import { useContext, useEffect, useState } from 'react'
import { FC } from 'react'
import { useTheme } from '@mui/material'
import { AddressLayout } from '@ntpkunity/controls'
import { useGetCountries, useGetStates } from '../../../hooks'
import { QueryKeys } from '@constants'
import { useQueryClient } from 'react-query'
import { IStates } from '@interfaces'
import { AppContext } from '@app/context-provider'
import { PageWrap } from '../finacing.style'

interface IAddressComponentProps {
  isGoogleEnabled: boolean
  dealerCode?: string | null
  form: any
  addressValue?: string
  errorProps?: any
  setIsAddressDirty?: any
  moveIn?: boolean
  control?: any
  showContactNumber?: boolean
  contactError?: string
}

const AddressDetailsComponent: FC<IAddressComponentProps> = ({
  dealerCode,
  isGoogleEnabled,
  form,
  addressValue,
  setIsAddressDirty,
  errorProps,
  moveIn = true,
}) => {
  const theme = useTheme()
  const { setValue, watch } = form
    const appState = useContext(AppContext);

  const { data: countriesServiceData } = useGetCountries(appState?.state?.slug)

  const LANGUAGE_PLACEHOLDERS = appState.state.language.placeholders;

  const [countriesData, setCountriesData] = useState([])
  const [dealerCountry, setDealerCountry] = useState<Array<any>>([])
  const { data: statesData } = useGetStates(appState?.state?.slug);

  useEffect(() => {
    setCountriesData(countriesServiceData)
  }, [countriesServiceData])

  useEffect(() => {
    setDealerCountry(
      countriesData?.filter(
        (x: any) => x?.id == '229'
      )
    )
  }, [countriesData])

  const access_token = localStorage.getItem('access_token');
  return (
    <>
      {dealerCountry?.length > 0 && statesData?.length > 0 ? (
        <>
          <PageWrap theme={theme} className='detail residence-detail'>
            <AddressLayout
              addressVerificationRequired={isGoogleEnabled}
              hideAddressButton={!isGoogleEnabled}
              theme={theme}
              state={{
                data: statesData,
                selectedItemObject: { text: 'name', value: 'name' },
                controlName: 'state_name'
              }}
              countries={dealerCountry}
              validationProps={errorProps}
              isContactRequired={false}
              value={watch(`${addressValue}`)}
              verifyAddressURL={`${process.env.HUBEX_BASE_URL}/api/verify-address/`}
              token={access_token}
              dealerCode={dealerCode}
              setIsAddressDirty={setIsAddressDirty}
              onChange={(e: any) => {
                setValue(`${addressValue}`, e)
                setValue(`${addressValue}${'.state_name'}`, e?.state_name)
                setValue(`${addressValue}${'.state_id'}`, e?.state_id)
              }}
              showCountryDropdown={false}
              moveInRequired={moveIn}
              placeholders={LANGUAGE_PLACEHOLDERS}></AddressLayout>
            </PageWrap>
        </>
      ) : null}
    </>
  )
}
export default AddressDetailsComponent
