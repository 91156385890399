import { useTheme } from "@mui/material";
import { useEffect, useContext, useState } from "react";
import { PreQualificationCard } from "../../components/pre-qualification-card/pre-qualification-card.component";
import { AppContext } from '@app/context-provider'
import { Status } from "constants/enums"
import { Box, Button, Dialog, Grid, Icon } from "@ntpkunity/controls";
import { AddNewCard, Popup } from "@components";
import { PreQualificationForm, PreQualificationResponse } from "@ntpkunity/controls-common";
import { defaultLanguages, QueryKeys } from "@constants";
import { useGetStates } from "@hooks";
import DisableLoader from '../../src/shared/assets/loader-disabled.gif'
import { useForm } from "react-hook-form";
import { useCustomerResponse, useGetDealerProfileByDealerCode, useGetIntegrationByProviderName, useGetPreQualification } from "hooks/order-management";
import { formatDateStringPreQualification, validation_response, view_response } from "helpers/data_change";
import { useQuery, useQueryClient } from "react-query";
import { IntegrationProvider, LenderIntegrationType } from "constants/providers";
interface PreQualificationResponseItem {
  title: string;
  value: string;
}

interface PreQualificationResponseProps {
  state: boolean;
  refNo: string;
  title: string;
  subTitle: any;
  items: PreQualificationResponseItem[];
}
export const PreQualification = () => {
  const theme = useTheme();
  const [showdialogue, setShowdialogue] = useState(false)
  const appState = useContext(AppContext);
  const company_id = appState.state.tenant
  const queryClient = useQueryClient();
  const customerData: any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_BY_EMAIL);
  const user_profile = JSON.parse(localStorage.getItem("settings"))
  const reference_id = `customer_reference=${customerData?.reference_id}`
  const dealerObject = JSON.parse(localStorage.getItem('dealer_information') || '{}')
  const { data: dealerProfile, refetch: dealerRefetch } = useGetDealerProfileByDealerCode(dealerObject?.dealer?.dealer_code, appState?.state?.slug);
  const { mutate: getProvider } = useGetIntegrationByProviderName(IntegrationProvider.SOCURE,
    LenderIntegrationType.FRAUD_IDENTIFICATION, company_id, appState?.state?.slug);
  const { data: setupCustomer, refetch: refetchCustomer } = useCustomerResponse(reference_id)
  const [datacheck, setDatacheck] = useState<PreQualificationResponseProps | undefined>(undefined);
  const { data: stateData } = useGetStates(appState?.state?.slug);
  const user_zipcode = JSON.parse(localStorage.getItem("user_profile"))
  const [dealerNumber, setDealerNumber] = useState('')
  const [state, setState] = useState<Array<any>>([])
  const { mutate: preQualification, isLoading: saveLoading } = useGetPreQualification();
  const [showresponse, setShowresponse] = useState(false)
  const [responsedata, setResponsedata] = useState([])
  const [socureEnabled, setSocureEnabled] = useState(false)

  const form = useForm()
  useEffect(() => {
    if (company_id) {
      getProvider(
        {
          provider_name: IntegrationProvider.SOCURE,
          integration_type: LenderIntegrationType.FRAUD_IDENTIFICATION,
          company_id: company_id
        },
        {
          onSuccess(response: any) {
            setSocureEnabled(response?.is_active)
            refetchCustomer()
          }
        }
      )
    }
    if (dealerObject?.dealer?.dealer_code) {
      dealerRefetch()
      let formatted = dealerProfile?.customer_support_number.replace(/(.{3})(?=.)/g, '$1 ');
      setDealerNumber(formatted)
    }
  }, [company_id])
  useEffect(() => {
    if (stateData?.length > 0) {
      setState(stateData)
    }
  }, [stateData]);
  useEffect(() => {
    if (datacheck) {
      setShowdialogue(false);
      setShowresponse(true);
    }
  }, [datacheck]);
  const onSubmit = (data: any) => {
    const dealer_id = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_id
    const body = {
      ...data,
      dealer_id
    }
    preQualification(body, {
      onSuccess: (_responce: any) => {
        let response_text
        response_text = validation_response(_responce);
        setDatacheck(response_text)
        queryClient.invalidateQueries(QueryKeys.CUSTOMER_CHECK)
        form.reset()
      }
    }
    )
  }
  useEffect(() => {
    const parsedDataArray = setupCustomer?.map(item => {
      const data = JSON.parse(item?.data);
      return {
        id: item?.id,
        status: data.status,
        executed_date: new Date(data?.executed_date).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        }),
        address: data?.address,
        mobile_phone_number: data?.mobile_phone_number,
        customer_name: data?.customer_name,
        email: data?.email,
        date_of_birth: new Date(data?.date_of_birth)?.toLocaleDateString('en-US'),
        ssn: data?.ssn,
        expiration: new Date(data?.expiration)?.toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        }),
        score: data?.score ?? '0',
        finance: `$ ${data?.finance?.toLocaleString('en-US', { minimumFractionDigits: 2 }) ?? '0.00'}`,
        sample_provider: data.sample_provider
      };
    });
    setResponsedata(parsedDataArray)
  }, [setupCustomer]);
  const getStatusClass = (status: string, currentDate: Date, statusValue: Date) => {
    if (status === "reject") {
      return Status.REJECTED;
    }

    const startOfDay = date => new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const isExpired = startOfDay(new Date(currentDate)).getTime() <= startOfDay(new Date(statusValue)).getTime();

    return !isExpired ? Status.Expired : Status.Qualified;
  };

  return (
    <>
      <Grid
        theme={theme}
        container
        item
        spacing={{ xs: 3, lg: 2 }}
      >
        {responsedata?.map(item => (
          <Grid item xs={12} sm={6} md={6} lg={4} theme={theme} key={item?.id}>
            <PreQualificationCard
              status={getStatusClass(item?.status, new Date(), item?.expiration)}
              refNo={item?.id}
              items={[
                { title: 'Credit Score', value: item?.score },
                { title: 'Financing Eligibility', value: item?.finance },
                { title: 'Response Date', value: formatDateStringPreQualification(item?.executed_date) }
              ]}
              btnText={'View Results'}
              onClick={() => {
                let response
                response = view_response(item)
                setShowresponse(true)
                setDatacheck(response)
              }}
            />

          </Grid>
        ))}
        {socureEnabled && (<Grid item xs={12} sm={6} md={6} lg={4} theme={theme}>
          <AddNewCard
            title={'Get Pre-Qualified'}
            icon={<><Icon name="CicleLinkIcon" onClick={() => { setShowdialogue(true) }} /></>}
          />
        </Grid>)}
        <>
          {showdialogue && user_profile?.email ? (
            <>
              <Popup>
                <Dialog
                  theme={theme}
                  size="sm"
                  title={defaultLanguages?.placeholders.HEADER_PREQUALIFICATION}
                  open={true}
                  disablePortal={true}
                  onCloseDialog={() => {
                    form.reset()
                    setShowdialogue(false)
                  }}
                  noFooter={true}
                  customFooter={
                    <Button theme={theme} primary type="submit" text={'Submit'} startIcon={saveLoading && <img src={DisableLoader} alt="Loader" />}
                      disabled={saveLoading} onClick={form.handleSubmit(onSubmit)} />}
                >
                  <PreQualificationForm
                    theme={theme}
                    formText={defaultLanguages?.placeholders.FORMTEXT_HEADER}
                    addressVerified={false}
                    form={form}
                    email={customerData?.email}
                    first_name={customerData?.first_name}
                    last_name={customerData?.last_name}
                    zip_code={user_zipcode?.zip_code}
                    statesData={{
                      data: state,
                      selectedItemObject: { text: 'name', value: 'code' }
                    }}
                    countries={[{ "id": 229, }]}
                    addressTitle={defaultLanguages?.placeholders.ADDRESS_HEADER}
                    disclaimerText={defaultLanguages?.placeholders.DISCLAIMER_PRE_QUALIFIED}
                  />
                </Dialog>
              </Popup>
            </>
          ) : null}
        </>
        <>
          {showresponse ? (
            <>
              <Popup>
                <Dialog
                  variant={undefined}
                  size="sm"
                  title=""
                  open={showresponse}
                  onCloseDialog={() => {
                    setShowresponse(false);
                  }}
                  theme={theme}
                  disablePortal
                  customFooter={
                    <>
                      <Box theme={theme} className='footer-full-width-btns footer-p-lg'>
                        <Button
                          theme={theme}
                          secondary
                          text={datacheck.state ? "Send Email" : "Start Chat"}
                          startIcon={datacheck.state ? null : <Icon name="StartChatIcon" />}
                          onClick={() => { }}
                        />
                        <Button
                          theme={theme}
                          secondary
                          text={datacheck.state ? "Print" : dealerNumber}
                          startIcon={datacheck.state ? null : <Icon name="CallIcon" />}
                          onClick={() => { }}
                        />
                      </Box>
                    </>
                  }
                >
                  <PreQualificationResponse
                    title={datacheck.title}
                    subTitle={datacheck.subTitle}
                    refNo={datacheck.refNo}
                    theme={theme}
                  />
                </Dialog>
              </Popup>
            </>
          ) : null}
        </>
      </Grid>
    </>
  );
};
