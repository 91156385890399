import { useTheme } from "@mui/material";
import SingleChat, {
  MessageSkeleton,
} from "./single-chat/single-chat-component";
import ChatMessagesWrap from "./chat-messages.style";
import { useChatServiceContext } from "../../../../hooks/useChatService";
import { MessagePerson } from "../../../../constants/enums";
import { useEffect, useRef } from "react";
import { Box, Button } from "@ntpkunity/controls";
import { useGetDealerChatConfig } from "hooks/dealer-management";
import { getDealerInfo } from "helpers/methods";

const ChatMessages = () => {
  const theme = useTheme();
  const dealerInfo = getDealerInfo();
  const { data: dealerChatConfig, isSuccess: chatConfigLoaded } =
    useGetDealerChatConfig(dealerInfo?.dealer_code);
  const {
    messages,
    loadingMessages,
    isLoadingMore,
    loadMoreMessages,
    shouldShowLoadMore,
  } = useChatServiceContext();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loadingMessages, messages]);

  if (loadingMessages) {
    return (
      <ChatMessagesWrap theme={theme} className="chat-messages-wrap">
        {[...Array(5)].map((_, index) => {
          return <MessageSkeleton key={index} />;
        })}
      </ChatMessagesWrap>
    );
  }

  if (!messages.length) {
    return (
      <ChatMessagesWrap theme={theme} className="chat-messages-wrap">
        {chatConfigLoaded && dealerChatConfig?.welcome_chat ? (
          <SingleChat
            fromOtherSide={true}
            message={dealerChatConfig?.welcome_chat}
            messageDetails={true}
            name={dealerInfo?.dealer_name}
            time={new Date().toLocaleTimeString(undefined, {
              hour: "2-digit",
              minute: "2-digit",
            })}
          />
        ) : (
          ""
        )}
      </ChatMessagesWrap>
    );
  }

  return (
    <ChatMessagesWrap theme={theme} className="chat-messages-wrap">
      {shouldShowLoadMore && (
        <Box theme={theme} textAlign="center">
          <Button
            theme={theme}
            onClick={loadMoreMessages}
            size="small"
            text="Load more"
            className="read-more-btn"
          />
        </Box>
      )}
      {isLoadingMore &&
        [...Array(5)].map((_, index) => {
          return <MessageSkeleton key={index} />;
        })}
      {messages.map((msg) => {
        const isMyMessage = msg.who === MessagePerson.ME;
        const messageTime = new Date(msg.timestamp);
        return (
          <SingleChat
            key={`message-${msg.messageId}`}
            fromOtherSide={!isMyMessage}
            message={msg.content}
            messageDetails={true}
            name={isMyMessage ? "You" : dealerInfo?.dealer_name}
            time={messageTime.toLocaleTimeString(undefined, {
              hour: "2-digit",
              minute: "2-digit",
            })}
          />
        );
      })}
      <div ref={scrollRef}></div>
    </ChatMessagesWrap>
  );
};

export default ChatMessages;
