export enum CardType {
  VISA = "visa",
  MASTERCARD = "masterCard",
}
export enum Status {
  Inquiry = "Inquiry",
  AvailableInInventory = "Available In Inventory",
  InContarct = "In Contarct",
  Available = "Available",
  Draft = "Draft", //Orders
  Submitted = "Submitted", //Orders
  Approved = "Approved", //Orders
  Conditioned = "Conditioned", //Orders
  InReview = "In Review", //Orders
  Reviewed = "Reviewed", //Orders
  ReSubmitted = "Re Submitted", //Orders
  SchedulePickup = "Awaiting Scheduling", //Schedule Pickup/delivery
  Appointment = "Appointment Scheduled", //Appointment
  Cancelled = "Cancelled", //Cancelled
  Converted = "Converted", //Completed
  Completed = "Completed", //Completed
  InProgress = "In-Progress", //In-Progress
  Declined = "Declined",
  Withdrawn = "Withdrawn",
  PendingConfirmation = "Pending Confirmation",
  Expired = "Expired",
  PendingTradeInConfirmation = "Pending Trade-In Confirmation",
  Qualified = "Qualified",
  REJECTED = "Rejected",
  FULLY_RECEIVED = "Fully Received",
  TRADE_IN_INSPECTION = "Trade-In Inspection",
}

export enum Stage {
  QUOTATION = "Quotation",
  APPLICATION = "Application",
}


export enum EstimateProvider {
  KBB = "KBB",
  PLAID = "Plaid",
}
export enum Integration_type {
  TRADE_IN = "Trade In Valuation",
  PLAID_PAYMENT = "Payment Processing",
}
export enum TradeInJourneySteps {
  AddTradeInPopup = "AddTradeInPopup",
  ProvideManualEstimatePopup = "ProvideManualEstimatePopup",
  MakeModelPopupOne = "MakeModelPopupOne",
  VinOnePopup = "VinOnePopup",
  LicensePlatePopup = "LicensePlatePopup",
  TrimLevelPopup = "TrimLevelPopup",
  FinalPopup = "FinalPopup",
  LoaderPopup = "LoaderPopup",
}

export enum PaymentType {
  Lease = "Lease",
  Finance = "Finance",
}

export enum Steps {
  REGISTERED = "registered",
  VERIFIED = "verified",
  TENANT = "tenant",
  TERMS = "terms",
}

export enum Tags {
  SIGNIN_PAGE = "Display Signin page",
  OTP = "Display OTP",
  SESSION_END = "Session End",
  ZIPCODE_POPUP = "Zipcode Pop Up",
  LANDING_ON_INVENTORY_SCREEN = "Landing on inventory screen",
  SEARCH_ZIPCODE = "Search Zip Code",
  SCROLL = "Scroll",
  DEALER_SELECTION = "Dealer Selection",
  DISPLAY_INVENTORY = "Display Inventory",
  APPLY_SORTING = "Apply sorting",
  APPLY_FILTERS = "Apply Filters",
  SELECT_VEHICLE = "Select Vehicle",
  VIEW_VEHICLE_DETAILS = "View Vehicle Details",
  INVENTORY_PAGE_END = "Inventory Page End",
  DISPLAY_PAYMENT = "Display Payment",
  PAYMENT_END = "Payment End",
  DISPLAY_FNI = "Display F&I",
  TAGS = "Tags",
  ADD_PRODUCT = "Add Product",
  VIEW_PRODUCT_DETAILS = "View Product Details",
  FNI_END = "F&I End",
  DISPLAY_ADDONS = "Display Addons",
  VIEW_DETAILS = "View Details",
  CARD_CLICK = "Card Click",
  ADDONS_END = "Addons End",
  DISPLAY_TRADE_IN = "Display Trade In",
  ENTER_MAKE_MODEL = "Enter Make Model",
  SELECT_YEAR = "Select Year",
  MAKE = "Make",
  SEARCH_EVENT = "Search Event",
  MODEL = "Model",
  SERIES = "Series",
  POP_TRADE_ESTIMATE = "Pop Trade Estimate Auto",
  POP_TRADE_ESTIMATE_MAN = "Pop Trade Estimate Manual",
  MILEAGE = "Milege",
  LOAN_KIND = "Loan Kind",
  PAY_OFF_AMOUNT = "Pay off Amount",
  CONDITION = "Condition",
  UPLOAD_PHOTOS = "upload photos",
  PROCEED_WITH_TRADING = "Proceed with Tradin",
  TRADE_IN_END = "Trade In End",
  DISPLAY_INSURANCE = "Display insurance",
  INSURANCE_POP_UP = "Insurance Pop Up",
  INPUT_VALUES = "Input Values",
  INSURANCE_SESSION_END = "Insurance Session End",
  DISPLAY_SCHEDULE_APPOINTMENTS = "Display Schedule Appointments",
  ADDRESS_ENTRY = "Address Entry",
  Schedule_END = "Schedule End",
  DISPLAY_FINANCING = "Display Financing",
  INFORMATION_FILL = "Information Fill",
  FINANCING_END = "Financing End",
  DISPLAY_REVIEW_ORDER = "Display Review Order",
  SUBMIT_ORDER = "Submit Order",
  REVIEW_ORDER_END = "Review Order End",
  ADD_PRODUCT_ADDON = "Add Product Addon",
  PAYMENT_CONTRACT = "Contract Payment",
  CONTRACTING = "Contract",
  PAYMENT_CALC = "Payment Calculator",
  PROFILE_INFO = "Profile Info Start",
  PROFILE_INFO_SET = "Profile Info Finish",
}

export enum PageType {
  SignIn = "Sign In Page",
  Otp = "OTP Page",
  InventoryImpression = "inventory Page",
  ZipCode = "Zip Code Popup",
  SelectVehicle = "Select Vehicle",
  ViewVehicle = "View Details",
  Payments = "Payments Page",
  FIPage = "F&I Page",
  Addons = "Addons Page",
  TradeIn = "TradeIn Page",
  Insurance = "Insurance Page",
  ScheduleAppointments = "Schedule Appointments Page",
  Financing = "Financing Page",
  ReviewOrder = "Review Order Page",
  SetProfile = "Set Profile",
}
export enum AvailabilityCheck {
  MANUAL = "Manual",
  AUTO = "Automatic",
}

export enum VehicleStatus {
  AVAILABLE = "Available",
  NOT_AVAILABLE = "Not Available",
}

export enum DocumentPackageTypes {
  CONTRACT = "Contract",
  GENERAL = "General",
}

export enum DocumentPackageStatus {
  SignatureNotRequired = "SignatureNotRequired",
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Signed = "Signed",
}

export enum SignTitle {
  SIGN_CONTRACT = "Sign Contract",
  SIGN_YOUR_INITIALS = "Sign Your Initials",
}

export enum PaymentCheck {
  CHECK = "checking",
  DEBIT = "ach_debit",
}
export enum AddOnsType {
  OEM = "OEM",
  Dealer = "Dealer",
}

export enum PaymentErrors {
  "card_declined" = "Your card was declined.",
  "insufficient_funds" = "Insufficient funds in the account.",
  "expired_card" = "Your card has expired.",
  "incorrect_cvc" = "The CVC code you entered is incorrect.",
  "invalid_card_number" = "The card number is invalid.",
  "card_not_supported" = "The card type is not supported.",
  "transaction_limit_exceeded" = "The transaction amount exceeds the card’s limit.",
  "bank_error" = "A bank error occurred. Please try again later.",
  "authentication_required" = "Additional authentication is required for this transaction.",
  "card_blocked" = "Your card has been blocked.",
  "invalid_expiry_date" = "The expiration date you entered is invalid.",
  "fraudulent_transaction" = "The transaction has been flagged as potentially fraudulent.",
  "payment_method_restricted" = "The payment method is restricted.",
  "transaction_declined" = "The transaction was declined.",
  "processing_error" = "An error occurred while processing the payment.",
  "duplicate_transaction" = "The transaction appears to be a duplicate.",
  "merchant_account_closed" = "The merchant's account is closed.",
  "currency_not_supported" = "The currency used is not supported by the card issuer.",
}

export enum Event {
  CREDIT_APPLICATION_APPROVED = "Credit Application Approved",
  INSURANCE = "Insurance Received",
}

export enum CreditDecisioning {
  EXTERNAL = "External",
  INTERNAL = "Internal",
}

export enum ChatClientEvents {
  CONNECT = "connect",
  MESSAGE = "message",
  DISCONNECT = "disconnect",
  ERROR = "error",
  MESSAGE_SENT = "messagesent",
  SUBSCRIBED = "subscribed",
  ONLINE = "online",
  OFFLINE = "offline",
  MESSAGE_LIST = "messagelist",
  CHANNELS = "channels",
  UNREAD_MESSAGES = "unreadmessages",
  MESSAGE_COUNT = "messagecount",
  NEW_THREAD = "newthread",
  SET_CHANNEL = "setchannel",
}

export enum MessagePerson {
  ME = "me",
  OTHER = "other",
}

export enum MessageType {
  TEXT = "text",
  IMAGE = "image",
  URL = "url",
  VIDEO = "video",
  AUDIO = "audio",
  FILE = "file",
  BADGE = "bage",
  RECEIPT = "receipt",
  TYPING = "typing",
  AUTO = "auto",
}

export enum MessageStatus {
  SENT = 1,
  RECEIVED = 2,
  SEEN = 3,
}

export enum OnlineEvents {
  SUBSCRIBE = "subscribe",
  UNSUBSCRIBE = "unsubscribe",
}

export enum TaggingClasses {
  PAYMENT = "payments-class",
  FNI = "fni-class",
  ADDS_ON = "adds-on-class",
  TRADE_IN = "trade-in-class",
  INSURANCE = "insurance-class",
  SCHEDULE = "schedule-class",
  FINANCING = "financing-class",
  REVIEW_ORDER = "review-order-class",
  CONTRACTING = "contracting-class",
  STRIPE_PAYMENT = "stripe-payment-class",
  SUBMIT_ORDER_APPROVED = "submit-order-approved",
  PAYMENT_METHOD="payment-method"
}

export enum TaggingId {
  SIGNING = "signing-id",
  SIGN_UP = "sign-up-id",
  LOG_IN = "log-in-id"
} 

export enum TimeZone {
  Baker_Island_Time = "-12:00", // Baker Island Time
  Niue_Time = "-11:00", // Niue Time
  Hawaii_Aleutian_Standard_Time = "-10:00", // Hawaii-Aleutian Standard Time (HAST)
  Alaska_Standard_Time = "-09:00", // Alaska Standard Time (AKST)
  Pacific_Standard_Time = "-08:00", // Pacific Standard Time (PST)
  Mountain_Standard_Time = "-07:00", // Mountain Standard Time (MST)
  Central_Standard_Time = "-06:00", // Central Standard Time (CST)
  Eastern_Standard_Time = "-05:00", // Eastern Standard Time (EST)
  Atlantic_Standard_Time = "-04:00", // Atlantic Standard Time (AST)
  Brasilia_Time = "-03:00", // Brasília Time (BRT)
  South_Georgia_Time = "-02:00", // South Georgia Time
  Azores_Standard_Time = "-01:00", // Azores Standard Time
  Coordinated_Universal_Time = "+00:00", // Coordinated Universal Time (UTC)
  Central_European_Time = "+01:00", // Central European Time (CET)
  Eastern_European_Time = "+02:00", // Eastern European Time (EET)
  Moscow_Standard_Time = "+03:00", // Moscow Standard Time (MSK)
  Gulf_Standard_Time = "+04:00", // Gulf Standard Time
  Pakistan_Standard_Time = "+05:00", // Pakistan Standard Time
  Bangladesh_Standard_Time = "+06:00", // Bangladesh Standard Time
  Indochina_Time = "+07:00", // Indochina Time
  China_Standard_Time = "+08:00", // China Standard Time (CST)
  Japan_Standard_Time = "+09:00", // Japan Standard Time (JST)
  Australian_Eastern_Standard_Time = "+10:00", // Australian Eastern Standard Time (AEST)
  Magadan_Time = "+11:00", // Magadan Time
  Fiji_Time = "+12:00", // Fiji Time
  India_Standard_Time = "+05:30", // India Standard Time (IST)
  Singapore_Time = "+08:00", // Singapore Time (SGT)
  Israel_Standard_Time = "+02:00", // Israel Standard Time
  South_Africa_Standard_Time = "+02:00", // South Africa Standard Time
  Mountain_Daylight_Time = "-06:00", // Mountain Daylight Time (MDT)
  Eastern_Daylight_Time = "-04:00", // Eastern Daylight Time (EDT)
  Pacific_Daylight_Time = "-07:00", // Pacific Daylight Time (PDT)
}

export const timeSlots = [
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
];
