import { AppContext } from "@app/context-provider";
import { ProductShoppingCard } from "@components";
import { Translations } from "@constants";
import { IFniProduct } from "@interfaces";
import { fniContext } from "@utilities";
import { VehicleDetailsPopup } from "libraries/vehicle-details-popup/vehicle-details-popup.component";
import React, { FC, useContext, useEffect, useState } from "react";
// Component to wrap Product SHopping Card Component
export const FniProductDetailCard: FC<{
  fniProduct: IFniProduct;
  rates: any;
  selectedTerm: number;
}> = ({ fniProduct, rates, selectedTerm }) => {
  const { addOrRemoveProduct } = useContext(fniContext);
  const [covergaeName, setCoverageName] = useState<string>("");
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;

  //get Smallest Rate with matched term
  const getSmallestRate = () => {
    let smallestRetailPrice = Infinity;
    let coverage_name = "";
    let coverage_terms = 1;
    fniProduct.coverage_terms = selectedTerm;
    for (let i = 0; i < rates?.length; i++) {
      if (rates?.[i]?.product_id == fniProduct?.product_id) {
        fniProduct.session_id = rates?.[i]?.session_id;
          let getCoveragesWithMatchedTerm = []
          if (!fniProduct?.price) {
            //When no product is added
            getCoveragesWithMatchedTerm = rates?.[i]?.coverages?.coverage?.filter(
              (x: any) => x?.term_months == selectedTerm
            );
          } else { 
            getCoveragesWithMatchedTerm = rates?.[i]?.coverages?.coverage?.filter(
              (x: any) =>
                x?.deductibles?.deductible?.retail_price == fniProduct?.price
            );
          }
          coverage_terms = getCoveragesWithMatchedTerm?.[0]?.term_months ?? 1;
          fniProduct.coverage_terms = coverage_terms
          fniProduct.term = getCoveragesWithMatchedTerm?.[0]?.term_months
          fniProduct.mileage = getCoveragesWithMatchedTerm?.[0]?.term_miles
          getCoveragesWithMatchedTerm?.forEach((item: any) => {
            const retailPrice = item?.deductibles?.deductible?.retail_price;
            fniProduct.form_id = item?.form?.form_id;
            fniProduct.rate_id = item?.deductibles?.deductible?.rate_id;
            if (retailPrice < smallestRetailPrice) {
              smallestRetailPrice = retailPrice;
              coverage_name = item?.coverage_name;
            }
          });
      }
    }
    setCoverageName(coverage_name);
    if (!fniProduct?.price) {
      fniProduct.price =
        smallestRetailPrice == Infinity ? 0 : smallestRetailPrice;
    }
  };
  useEffect(() => {
    getSmallestRate();
  }, [rates]);

  const [open, setOpen] = useState<boolean>(false);
  const openPopup = () => setOpen(true);
  return (
    <>
      <ProductShoppingCard
        productImg={fniProduct?.image?.[0]?.location}
        title={fniProduct?.product_name}
        desc={fniProduct?.description}
        linkText={PLACEHOLDERS.FNI_VIEW_PRODUCT_TEXT}
        onLinkClick={openPopup}
        buttonStatePrimary={!fniProduct.is_added}
        buttonStateSecondary={fniProduct.is_added}
        buttonText={
          fniProduct.is_added == true
            ? PLACEHOLDERS.FNI_ADDED
            : PLACEHOLDERS.FNI_ADD_PRODUCT_TEXT
        }
        onBtnClick={() => {
          addOrRemoveProduct(
            fniProduct.id,
            !fniProduct.is_added,
            null,
            fniProduct.term,
            fniProduct.mileage
          );
        }}
        coverageItem
        coverageTitle={covergaeName}
        coveragePrice={Math.round(
          fniProduct.price / fniProduct.coverage_terms ?? 1
        )}
        changeTextonHover={true}
        onHoverText={PLACEHOLDERS.FNI_REMOVE_PRODUCT_TEXT}
      />
      {open && (
        <VehicleDetailsPopup
          open={open}
          setOpen={setOpen}
          product={fniProduct}
          rate={rates?.find((x) => x.product_id == fniProduct.product_id)}
          onButtonClick={addOrRemoveProduct}
          brochureUrl={fniProduct?.brochure_url}
        />
      )}
    </>
  );
};
