import { FC, useState } from 'react'
import { useTheme } from '@mui/material'
import { ContractingStack } from '@components'
import { useOrderContext } from '@pages'
import { DocumentViewModal } from '@ntpkunity/controls-common'
import { TaggingId } from 'constants/enums'

export const GeneralDocument: FC<{ documentPackage: any, PLACEHOLDERS: any }> = ({ documentPackage, PLACEHOLDERS }) => {
  const [openDocumentModal, setOpenDocumentModal] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState()
  const { state: { order } } = useOrderContext()
  const theme = useTheme()

  const handleOptionSelection = (e, key, value) => {
    switch (key) {
      case 'view':
        setSelectedDocument(value)
        setOpenDocumentModal(true)
        break
      default:
        setSelectedDocument(value)
        setOpenDocumentModal(true)
    }
  }

  return (
    <>
      <ContractingStack
        heading={PLACEHOLDERS.CONTRACTING_GENERAL_DOCUMENTS}
        menu
        handleOptionSelection={handleOptionSelection}
        items={documentPackage?.documents?.map(document => ({
          title: document?.title,
          menu: document
        }))}
      />
      {openDocumentModal && (
        <DocumentViewModal
          id={TaggingId.SIGNING}
          theme={theme}
          openPopup={openDocumentModal}
          setOpenPopup={setOpenDocumentModal}
          selectedDocument={selectedDocument}
          referenceId={order?.reference_id}
          referenceNumber={order?.reference_number}
          placeholders={PLACEHOLDERS}
        />
      )}
    </>
  )
}