import { styled } from "@mui/material";
import { Box, IBoxProps } from "@ntpkunity/controls";

const ChatBoxWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.chat-box": {
    margin: "0 24px 24px 24px",
    position: "relative",
    ".u-form-group": {
      ".textarea": {
        ".MuiInputBase-root": {
          borderRadius: 16,
          padding: "16px 16px 72px",
          ".MuiInputBase-input": {
            fontSize: theme.typography.subtitle2.fontSize,
          },
        },
      },
    },
    ".inner-btn-wrap": {
      display: "flex",
      alignItems: "center",
      gap: 16,
      justifyContent: "flex-end",
      position: "absolute",
      width: "100%",
      left: "50%",
      transform: "translateX(-50%)",
      bottom: 0,
      padding: 16,
      ".inner-icons-btn": {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: 8,
        ".btn.with-icon.icon-only": {
          padding: 0,
          "&:hover": {
            backgroundColor: "initial",
          },
        },
      },
      ".main-btn": {
        padding: "8px 16px",
      },
    },
    ".inner-select-wrap": {
      ".section-header": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        width: "100%",
        padding: "8px 16px",
        border: "1px solid" + theme.palette.grey[100],
        borderBottom: "none",
        borderRadius: "16px 16px 0 0",
        ".u-form-group": {
          marginBottom: 0,
          ".u-form-control, .u-form-control.u-select": {
            height: 32,
            backgroundColor: theme.customVariables.tableStripedBg,
            ".MuiInputBase-input": {
              padding: "0px 35px 0px 12px",
              fontSize: theme.typography.caption.fontSize,
              color: theme.palette.grey[600],
            },
            ".MuiSelect-icon": {
              right: 8,
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "0 solid transparent",
            },
          },
        },
      },
    },
    "&.drift-chat-box": {
      ".u-form-group": {
        ".textarea": {
          ".MuiInputBase-root": {
            borderRadius: "16px",
          },
        },
      },
    },
  },
}));

export default ChatBoxWrap;
