import { ProductDetailCard } from "@libraries"
import { Accordion, Box } from "@ntpkunity/controls"
import { AppointmentsWrap } from './appointments.style'
import { useTheme } from '@mui/material'

export const AppointmentsPage = () => {
    const theme = useTheme()
    return (
        <>
        <AppointmentsWrap theme={theme} className="appointment-wrap">
            <ProductDetailCard 
                title={'In-Store Pickup'}
                subTtile={'Free'}
                caption={'at 18800 Hawthorne Blvd, Torrance, CA 90504, United States'}
                listItems={[
                    {
                        title: 'Scheduled On',
                        value: 'July 00, 0000, 00:00 PM'
                    },
                    {
                        title: 'Contact Number',
                        value: '000 000 0000'
                    }
                ]}
                exportToCalendarText={'Time zone GMT-8'}
                onExportCalendarClick={()=>{}}
                rescheduleText="Need a different date or time"
                onRescheduleClick={()=>{}}
                mediaContent={<><iframe src="https://maps.google.com/maps?q=america&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameBorder="0" scrolling="no"></iframe></>}

            />
            <Box theme={theme} mt={3}>
                <ProductDetailCard 
                    title={'Delivery'}
                    subTtile={'Free'}
                    caption={'at 18800 Hawthorne Blvd, Torrance, CA 90504, United States'}
                    listItems={[
                        {
                            title: 'Scheduled On',
                            value: 'July 00, 0000, 00:00 PM'
                        },
                        {
                            title: 'Contact Number',
                            value: '000 000 0000'
                        }
                    ]}
                    exportToCalendarText={'Time zone GMT-8'}
                    onExportCalendarClick={()=>{}}
                    rescheduleText="Need a different date or time"
                    onRescheduleClick={()=>{}}
                    mediaContent={<><iframe src="https://maps.google.com/maps?q=america&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameBorder="0" scrolling="no"></iframe></>}

                />
            </Box>
            <Box theme={theme} mt={3} className="appointment-history">
                <Accordion
                    theme={theme}
                    items={[
                        {
                        content: (
                            <>
                                <ProductDetailCard 
                                    title={'Test Drive'}
                                    subTtile={'Free'}
                                    caption={'at 18800 Hawthorne Blvd, Torrance, CA 90504, United States'}
                                    listItems={[
                                        {
                                            title: 'Scheduled On',
                                            value: 'July 00, 0000, 00:00 PM'
                                        },
                                        {
                                            title: 'Contact Number',
                                            value: '000 000 0000'
                                        }
                                    ]}
                                    exportToCalendarText={'Time zone GMT-8'}
                                    onExportCalendarClick={()=>{}}
                                    rescheduleText="Need a different date or time"
                                    onRescheduleClick={()=>{}}
                                    mediaContent={<><iframe src="https://maps.google.com/maps?q=america&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameBorder="0" scrolling="no"></iframe></>}

                                />
                            </>
                        ),
                        title: "Your Appointments History",
                        key: "string-1",
                        isExpanded: false,
                        id: "string-1",
                        ariaControls: "string-1",
                        },
                    ]}
                    shouldCollapse
                />
            </Box>
        </AppointmentsWrap>
        </>
    )
}