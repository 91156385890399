import { FC, useState, useEffect, useContext } from 'react'
import React from 'react'
import { Link, useTheme } from '@mui/material'
import { Box, Typography, Icon, Grid, Image } from '@ntpkunity/controls'
import { ProductDecriptionCard, ProductDetailDialog } from '../../../components'
import { AppContext } from '@app/context-provider'

export const ProductDetailtPopup: FC<{ details?: any , defaultCurrency?: any}> = ({ details,defaultCurrency }) => {
  const theme = useTheme()
  const handleBrochureLink = () => {
    let url = details?.brochure_url
    window.open(url)
  }
  const handleVideoLink = () => {
    let url = details?.video_url;
    if (!url.startsWith('http')) {
        url = 'https://' + url;
    }
    window.open(url, '_blank');
  }
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders

  return (
    <ProductDetailDialog theme={theme}>
      <Grid
        theme={theme}
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, md: 3 }}
        sx={{ mb: 2 }}
      >
        <Grid item xs={12} theme={theme}>
          <Image 
              theme={theme}
              src={details?.image[0]?.location}
              aspectRatio="16:9"
              alt='dialog image'
          />
        </Grid>
        <Grid item xs={12} theme={theme}>
          <ProductDecriptionCard
            label={details?.offered_by}
            title={details?.product_name}
            productPrice={
              <>
                {defaultCurrency}
                {parseFloat(details?.price).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </>
            }
            secondaryText={
              <>
                {PLACEHOLDERS.ADD_ONS_PART_NUMBER}: {details?.part_no}
              </>
            }
            desc={details?.description}
            link={[
              {
                title: PLACEHOLDERS.ADD_ONS_VIEW_PRODUCT_BROCHURE,
                onClick: handleBrochureLink,
                hideLink: !details?.brochure_url,
              },
              {
                title: PLACEHOLDERS.ADD_ONS_VIEW_PRODUCT_VIDEO,
                onClick: handleVideoLink,
                hideLink: !details?.video_url,
              },
            ]}
          />
          <Typography
            theme={theme}
            variant='caption'
            component={'small'}
            textAlign={'center'}
            display={'block'}
            className='text-muted'
            mt={2}
          >
            The prices displayed are not guaranteed and are subject to change. Rates are subject to final approval. Nothing on this site is an offer to contract. The products above are optional and contain additional benefits, limitations and exclusions from coverage.
          </Typography>
        </Grid>
      </Grid>
    </ProductDetailDialog>
  );
}

export default ProductDetailtPopup
