export const prefixOptions = [
    {
        text: 'Dr.',
        value: 'DR'
    },
    {
        text: 'Ms.',
        value: 'MS'
    },
    {
        text: 'Mrs.',
        value: 'MRS'
    },
    {
        text: 'Miss.',
        value: 'MISS'
    },
    {
        text: 'Mr.',
        value: 'MR'
    }
]

export const messages = {
    name: {
        prefix: 'prefix',
        firstName: 'first_name',
        middle_name: 'middle_name',
        lastName: 'last_name',
        suffix: 'suffix',
        ssn: 'ssn',
        home_number: 'home_number',
        mobile_number: 'mobile_number',
        email: 'email',
        dateOfBirth: 'date_of_birth'
    },
    validation: {
        ssn: 'Customer Already Exists With SSN'
    }
}