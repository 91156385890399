import { useTheme } from "@mui/material";
import ChatButtonWrap from "./chat-window.style";
import { Box, Button, Icon } from "@ntpkunity/controls";
import ChatMessagesWindow from "../chat-messages-window/chat-messages-window.component";
import {
  useChatService,
  ChatServiceProvider,
  useGetDealerByDealerCode,
  useChatVinContext,
} from "../../../hooks";
import { getDealerInfo } from "../../../helpers/methods";
import { useAppContext } from "@app/context-provider";
import { useGetDealerChatConfig } from "hooks/dealer-management";

const ChatService = () => {
  const dealerCode = getDealerInfo()?.dealer_code;
  const { state: appState } = useAppContext();
  const company_id = appState.tenant;

  const { data: dealerByDealerCode, isLoading } = useGetDealerByDealerCode({
    dealerCode: dealerCode,
    headers: { company_id },
  });
  const { isLoading: chatConfigLoading } = useGetDealerChatConfig(dealerCode);

  const { vin } = useChatVinContext();

  if (
    !dealerCode ||
    isLoading ||
    !dealerByDealerCode?.chat ||
    chatConfigLoading
  ) {
    return <></>;
  }

  return <ChatButton key={`vin-${vin}`} />;
};

const ChatButton = () => {
  const theme = useTheme();
  const dealerCode = getDealerInfo()?.dealer_code;
  const { vin } = useChatVinContext();
  const { data: dealerChatConfig } = useGetDealerChatConfig(dealerCode);

  const chatMethods = useChatService({
    dealerCode,
    orderVin: vin,
    chatConfig: dealerChatConfig,
  });

  const { chatClient, openChat, setOpenChat, unreadCount } = chatMethods;

  return (
    <ChatServiceProvider {...chatMethods}>
      <ChatButtonWrap theme={theme} className="chats-button-wrap">
        {openChat && <ChatMessagesWindow />}
        <Box theme={theme} className="chat-icon-wrap">
          {unreadCount > 0 && !openChat && (
            <span className="chats-count">{unreadCount}</span>
          )}
          {chatClient && (
            <Button
              theme={theme}
              iconText={<Icon name={openChat ? "CloseBlack" : "InboxIcon"} />}
              className="chat-icon"
              onClick={() => {
                setOpenChat(!openChat);
              }}
            />
          )}
        </Box>
      </ChatButtonWrap>
    </ChatServiceProvider>
  );
};

export default ChatService;
