import { useTheme } from '@mui/material'
import { Stack } from '../../components'
import { QuestionStackProps } from './question-stack.props'
import { QuestionStackWrap } from './question-stack.style'
import { Box, Button, Checkbox, Icon, Typography } from '@ntpkunity/controls'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useOrderContext } from '@pages'
import { areArraysEqual } from 'helpers/methods'
import { getTabsClass } from '@helpers'

export const QuestionStack = ({ title, description, paddingMd = 4, paddingXs = 3, marginTopXs, marginTopMd, actionArea, buttonText, dFlex, dColumn, onLinkClick, onCheckBoxClick }: QuestionStackProps) => {
    const theme = useTheme()
    const {
        state: { activeTab },
      } = useOrderContext();
      const [orderTabs, setOrderTabs] = useState([]);
  
      useEffect(() => {
        const workflow: any = JSON.parse(localStorage.getItem('workflow'))
        if (workflow?.meta_data?.tabs && !areArraysEqual(workflow?.meta_data?.tabs, orderTabs)) {
          setOrderTabs(workflow?.meta_data?.tabs)
        }
      })
    return (
        <QuestionStackWrap className={clsx({'question-stack-wrap':true,[`${getTabsClass(orderTabs,activeTab,false)}-yes`]:true})} theme={theme} p={{ xs: paddingXs, md: paddingMd }} mt={{ xs: marginTopXs, md: marginTopMd }} onClick={onLinkClick}>
            <Box theme={theme} gap={'16px'} className={clsx({ 'stack-row': true, 'stack-column': description, 'd-flex': dFlex, 'd-column': dColumn ,[`${getTabsClass(orderTabs,activeTab,false)}-yes`]:true})}>
                {title &&
                    <Typography theme={theme} className={clsx({'title fw-sbold w-100':true,[`${getTabsClass(orderTabs,activeTab,false)}-yes`]:true})} component={'p'} variant='subtitle1'>{title}</Typography>
                }
                {actionArea &&
                    <Box theme={theme} className={clsx({'action-area':true,[`${getTabsClass(orderTabs,activeTab,false)}-yes`]:true})}>
                        {onLinkClick &&
                            <Button className={clsx({[`${getTabsClass(orderTabs,activeTab,false)}-yes`]:true})} 
                            theme={theme} 
                            defaultBtn 
                            text={buttonText} 
                            onClick={onLinkClick} 
                            iconText={<Icon className={clsx({[`${getTabsClass(orderTabs,activeTab,false)}-yes`]:true})} name="IconRightArrow" />} 
                            ariaLabel='Icon Right Arrow' 
                            />
                        }
                        {onCheckBoxClick &&
                            <Checkbox theme={theme} label="" onChange={onCheckBoxClick} ariaLabel='checkbox'/>
                        }
                        {actionArea}
                    </Box>
                }
            </Box>
            {description && <Box theme={theme} >{description}</Box>
            }
        </QuestionStackWrap>
    )
}
