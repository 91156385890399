import { useEffect, useReducer, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material'
import { Snackbar } from '@ntpkunity/controls'
import { SignupComponent } from '@ntpkunity/controls-ums'
import { StatusCodes } from 'http-status-codes'
import { useAppContext, changeUserLoggedInState } from '@app/context-provider'
import { LOGIN_ROUTE, Navigation } from 'constants/navigation'
import { SnackbarUtility } from 'utilities'
import { AuthLayoutWrap } from "../layout.style"
import { DisclaimerNames } from 'constants/consent-status'
import { useDisclaimers } from 'hooks/authentication-management'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags,PageType, Steps } from 'constants/enums';
import { useWorkflowContext } from '@app/router-workflow'
import { user_session } from '@constants'

export const SignInPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { tenant } = useParams()
  const { pathname } = useLocation()
  const isLoginRoute = pathname.includes(LOGIN_ROUTE)

  const { disclaimers, disclaimersLoading } = useDisclaimers([
    DisclaimerNames.TERMS_AND_CONDITIONS,
    DisclaimerNames.CCPA
  ])
  const [response, setResponse] = useState<any>()

  const { mutate: createTags } = useTags();
  const { navigateNext } = useWorkflowContext()

  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)

  const { state: appState, dispatch: app_dispatch } = useAppContext()
  const PLACEHOLDERS = appState?.language?.placeholders;

  const translations = {
    title: isLoginRoute ? PLACEHOLDERS.LOGIN_TITLE : PLACEHOLDERS?.SIGN_IN_TITLE,
    subTitle: PLACEHOLDERS?.SIGN_IN_SUBTITLE_STARTED,
    emailFieldLabel: PLACEHOLDERS?.SIGN_IN_EMAIL_FIELD_LABEL,
    helpText: PLACEHOLDERS?.SIGN_IN_HELP_TEXT,
    buttonText: isLoginRoute ? PLACEHOLDERS?.LOGIN_BUTTON_TEXT : PLACEHOLDERS?.SIGN_IN_BUTTON_TEXT,
    passwordFieldLabel: PLACEHOLDERS?.SIGN_IN_PASSWORD_FIELD_LABEL,
    forgetLinkLabel: PLACEHOLDERS?.SIGN_IN_FORGET_LINK_LABEL,
    loginButtonText: PLACEHOLDERS?.LOGIN_TEXT,
    emailAddressText: PLACEHOLDERS?.EMAIL_ADDRESS_TEXT,
    termsAndConditionsText: PLACEHOLDERS?.TERMS_AND_CONDITIONS_TEXT,
    marketingMaterialText: PLACEHOLDERS?.MARKETING_MATERIAL_TEXT,
    ccpaStaticText: PLACEHOLDERS?.CCPA_STATIC_TEXT,
    ccpaLinkText: PLACEHOLDERS?.CCPA_DISCLOSURE_TEXT,
    productRelatedText: PLACEHOLDERS?.PRODUCT_RELATED_TEXT,
    isRequiredText: PLACEHOLDERS?.IS_REQUIRED_TEXT,
    termsAndConditionsTitle: PLACEHOLDERS?.END_USER_LICENSE_AGREEMENT,
    termsAndConditionsButtonText: PLACEHOLDERS?.AGREED_TERMS_AND_CONDITIONS,
    disclosureTitle: PLACEHOLDERS?.CCPA_DISCLOSURE_TEXT,
    validEmailRequiredText: PLACEHOLDERS?.VALID_EMAIL_IS_REQUIRED,
    googleButtonLayoutText: {
      buttonText: isLoginRoute ? PLACEHOLDERS?.GOOGLE_LOGIN_BUTTON_TEXT : PLACEHOLDERS?.GOOGLE_SIGNUP_BUTTON_TEXT
    }
  }

  const handleSnackbar = (message) => {
    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(message));
    setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 5000);
  };

  useEffect(() => {
    if (response) {
      const { status, data } = response
      const {
        email,
        signup_steps: signupSteps,
        is_tenant: isTenant,
        provider,
        key,
        access_token: accessToken,
        refresh_token: refreshToken,
        profile
      } = data ?? {}

      if (status === StatusCodes.NOT_FOUND) {
        handleSnackbar(PLACEHOLDERS.INVALID_CREDENTIALS_MESSAGE || response.message)
      }
      if (status === StatusCodes.BAD_REQUEST) {
        handleSnackbar(response.message)
      }
      if (Object.keys(data ?? {}).length > 0) {
        if (!!accessToken && [Steps.TENANT, Steps.TERMS].includes(signupSteps?.toLowerCase())) {
          localStorage.setItem("settings", JSON.stringify({ email: response?.data?.user_name, 'access_token': accessToken }));
          localStorage.setItem('access_token', accessToken)
          localStorage.setItem('refresh_token', refreshToken)
          localStorage.setItem('user_profile', JSON.stringify(profile))
          localStorage.setItem('signup_steps', `"${signupSteps.toLowerCase()}"`);
          app_dispatch(changeUserLoggedInState(true))

          const vin = localStorage.getItem('vin');
          localStorage.removeItem('vin');

          navigateNext('', vin ? { vin } : undefined);
        } else if (
          (provider ?? '').length > 0 && (key ?? '').length > 0 &&
          [Steps.REGISTERED, Steps.VERIFIED].includes(signupSteps?.toLowerCase())
        ) {
          navigate(`${Navigation.AUTHENTICATION.USER_PROFILE.replace(':tenant', tenant)}`, {
            replace: true,
            state: {
              email,
              isTenant,
              otp: key,
              provider,
              profile
            }
          })
        } else {
          navigate(`${Navigation.AUTHENTICATION.OTP_PAGE.replace(':tenant', tenant)}`, {
            replace: true,
            state: {
              email,
              isTenant,
              signupSteps
            }
          })
        }
        if(!isLoginRoute){
          const event: Event = {
            session: user_session,
            slug:tenant,
            event_name:Tags.SIGNIN_PAGE,
            page_type:PageType.SignIn
          }
          createTags(event)
        }
      }
    }
  }, [response])

  return (
    <AuthLayoutWrap theme={theme} className='auth-layout-wrap'>
      <SignupComponent
        theme={theme}
        layoutText={translations}
        setResponse={setResponse}
        slug={tenant}
        customTermsAndConditions={true}
        termsAndConditionsContent={disclaimers?.TERMS_AND_CONDITIONS}
        ccpaContent={disclaimers?.CCPA}
        isLoginRoute={isLoginRoute ?? false}
      />
      <Snackbar
        theme={theme}
        message={snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      />
    </AuthLayoutWrap>
  )
}
