import { FC, useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { PageWrap } from "./fni-product.style";
import { PageHeader, Search, Tab } from "../../components";
import { Box, ScrollableTabs, Typography } from "@ntpkunity/controls";
import {
  UseGetFinancialInsuranceProduct,
  useGetIntegrationByProviderName,
  useGetFnIProductRate,
} from "hooks/order-management";
import { QueryKeys, Translations } from "@constants";
import {
  IDealerProfile,
  IFniControlProps,
  IFniProductFilter,
  IOrderFnI,
  IPenCredentials,
  IVehicle,
} from "interfaces";
import { useQueryClient } from "react-query";
import { useParams } from 'react-router-dom'
import { FniProductCard } from "./fni-product-card";
import { fniContext } from "@utilities";
import { AppContext } from "@app/context-provider";
import { user_session } from '@constants'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { useOrderContext } from "@pages";
import { Tags,PageType } from 'constants/enums';import { IntegrationProvider, LenderIntegrationType } from "constants/providers";
import { updateFnI } from "../../pages/(order-management)/order/order-context-provider";

export const FniProduct: FC<IFniControlProps> = ({
  finance_type = "Lease",
  terms = 12,
  apr = 1.2,
  financed_amount = 33718,
  addedFniProducts,
  allFniProducts
}) => {

  const queryClient = useQueryClient();
  const theme = useTheme();
  const { state: { order }, dispatch } = useOrderContext()
  const appState = useContext(AppContext);
  const { tenant, vin } = useParams()

  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const dealerCode = localStorage.getItem('dealer_information') ? JSON.parse(localStorage.getItem('dealer_information')).dealer?.dealer_code : 0

  const PLACEHOLDERS = appState.state.language.placeholders;
  const company_id = appState.state.tenant

  const vehicle: IVehicle = queryClient.getQueryData([QueryKeys.VEHICLE, vin]);
  const dealerProfile: any = queryClient.getQueryData([QueryKeys.DEALER_PROFILE, dealerCode])

  const [fniProducts, setFniProducts] = useState<IFniProductFilter>(allFniProducts);
  const [productRates, setProductRates] = useState<any>();
  const [penEnabled, setPenEnabled] = useState<boolean>(false)

  const { mutate: createTags } = useTags();
  const { mutate: getProductRates, isLoading: isRatesLoading } =
    useGetFnIProductRate(appState?.state?.slug);
  const { mutate: getPenIntegrations } = useGetIntegrationByProviderName(
    IntegrationProvider.PEN, LenderIntegrationType.FNI_PRODUCTS,company_id ,appState?.state?.slug
  );



  const applyMarkupOnRates = (fniProduct: any, fniRates: any) => {
    fniRates?.rates?.rate?.map((rate: any) => {
      let markup = fniProduct.find(
        (x: any) => x.product_id == rate?.product_id
      )?.markup;
      //when only single coverage is found then pen returns object instead of array
      if (
        rate?.coverages?.coverage &&
        !Array.isArray(rate?.coverages?.coverage)
      ) {
        let price =
          rate?.coverages?.coverage?.deductibles?.deductible?.retail_price;
        price = price + (markup / 100) * price;
        rate.coverages.coverage.deductibles.deductible.retail_price = price;
        rate.coverages.coverage = [rate?.coverages?.coverage];
      } else {
        rate?.coverages?.coverage?.map((item: any) => {
          let price = item.deductibles.deductible.retail_price;
          price = price + (markup / 100) * price;
          item.deductibles.deductible.retail_price = price;
        });
      }
    });
    setProductRates(fniRates)
  };
  const getPenCredentials = () => {
    getPenIntegrations(
      { provider_name: IntegrationProvider.PEN, integration_type: LenderIntegrationType.FNI_PRODUCTS, company_id:company_id},
      {
        onSuccess(response: any) {
          setPenEnabled(response?.is_active)
        },
      }
    );
  };
  const getProductInfo = () => {
    if (fniProducts) {
      let productInfo: Array<any> = [];
      fniProducts?.result?.map((obj: any) => {
        const newObj: any = {
          product_id: obj?.product_id,
          starting_mileage: 0,
          ending_mileage: 10000,
        };
        productInfo.push(newObj);
      });
      return productInfo;
    }
  };
  const getCarStatus = (type: any) => {
    return type == "pre-owned" || type == "pre installed"
      ? "USED"
      : type?.toUpperCase();
  };
  const getRateRequestObject = () => {
    const ratesRequestObject = {
      deal_info: {
        pen_dealer_id: dealerProfile?.pen_dealer_id,
        vin: vehicle.vin,
        mileage: vehicle.mileage,
        car_status: getCarStatus(vehicle.type?.toString()?.toLowerCase()), //"NEW"
        effective_date: new Date(
          Date.now() - 24 * 60 * 60 * 1000
        ).toLocaleDateString(), // yesterday's date
        in_service_date: new Date(
          Date.now() - 24 * 60 * 60 * 1000
        ).toLocaleDateString(), // yesterday's date
        finance_type: finance_type,
        vehicle_msrp: vehicle.msrp,
        vehicle_purchase_price: vehicle.internet_price,
        deal_type: finance_type === 'lease' ? 'LEASE': 'LOAN' ,
        finance_terms: [terms],
        finance_terms_mileage: [24],
        apr: apr,
        financed_amount: financed_amount,
        language: "ENGLISH",
        user_role: "FI_MANAGER",
      },
      products_info: getProductInfo(),
      test_request: true,
    };
    return ratesRequestObject;
  };
  const getRatesCall = () => {
    getProductRates(
      {
        data: getRateRequestObject(),
      },
      {
        onSuccess: (data) => {
          applyMarkupOnRates(fniProducts?.result, data);
        },
      }
    );
  };
  const addOrRemoveProduct = (
    fni_product_id: number,
    add_product: boolean,
    is_price: number = null,
    term: number = null,
    mileage: number = null

  ) => {
    setFniProducts((prevState) => ({
      ...prevState,
      result: prevState.result.map((item) =>
        item.id === fni_product_id
          ? {
              ...item,
              is_added: add_product,
              price: is_price ?? item.price,
              term: term,
              mileage: mileage
            }
          : item
      ),
    }));
  };
  useEffect(() => {
    if(company_id){
    getPenCredentials();
    }
  }, [company_id]);

  useEffect(() => {
    if(allFniProducts && !fniProducts?.result){
          setFniProducts(allFniProducts);
    }
    if (allFniProducts?.result && penEnabled && fniProducts?.result && !order?.order_fnI?.length) {
      getRatesCall();
    }
  }, [allFniProducts, penEnabled,fniProducts]);

  useEffect(()=>{
    if(order?.order_fnI?.length){
      order?.order_fnI?.forEach((fni)=>{
        addOrRemoveProduct(
            fni?.financial_insurance_id,
            true,
            fni?.applied_price,
            fni?.term,
            fni?.mileage
        )
      })
    }
  },[])

  //Add data To order store whenever product is added
  useEffect(() => {
    if (fniProducts?.result?.length > 0) {
      let order_fni: Array<IOrderFnI>=[]
      fniProducts?.result
        .filter((x) => x.is_added)
        .map((item) => {
          order_fni?.push({
            order_id: item?.order_id ?? 0,
            financial_insurance_id: item.id,
            product_name: item.product_name,
            dealer_id: +vehicle?.dealer_id,
            applied_price: item?.price,
            session_id: item?.session_id,
            rate_id: item?.rate_id,
            form_id: item?.form_id,
            identifier: item?.identifier,
            is_taxable:item?.taxable,
            term: item?.term,
            mileage:item?.mileage

          });
        });
      dispatch(updateFnI(order_fni));
      const event_detail: Event = {
        session: user_session,
        slug:tenant,
        event_name:Tags.ADD_PRODUCT,
        user_id:user_profile?.user_id,
        vin:vehicle?.vin,
        dealer_id: +vehicle?.dealer_id,
        vehicle_make:vehicle?.make,
        vehicle_model:vehicle?.model,
        order_id:order?.identifier,
        page_type:PageType.FIPage
      }
      createTags(event_detail)
    }
  }, [fniProducts]);

  const product_type = [
    ...Array.from(
      new Set(fniProducts?.result?.map((item: any) => item.product_type))
    ),
  ];
  const tabs = [
    {
      title: "All",
      content: (
        <fniContext.Provider
          value={{
            addOrRemoveProduct: addOrRemoveProduct,
            isLoading: isRatesLoading,
          }}
        >
          <FniProductCard
            products={fniProducts?.result}
            rates={productRates?.rates?.rate}
          />
        </fniContext.Provider>
      ),
    },
  ].concat(
    product_type?.map((item: any) => {
      {
        return {
          title: item,
          content: (
            <fniContext.Provider
              value={{
                addOrRemoveProduct: addOrRemoveProduct,
                isLoading: isRatesLoading,
              }}
            >
              <FniProductCard
                products={fniProducts?.result?.filter(
                  (x: any) => x.product_type == item
                )}
                rates={productRates?.rates?.rate}
              />
            </fniContext.Provider>
          ),
        };
      }
    })
  );

  return (
    <PageWrap theme={theme} className="page-wrap">
      <PageHeader
        marginBottom={3}
        title={PLACEHOLDERS.FNI_HEADER_TITLE}
        subTitle={
          <>
            {PLACEHOLDERS.FNI_HEADER_SUB_TITLE}
          </>
        }
      />
      <Box theme={theme} mb={3}>
        <Search placeholder={PLACEHOLDERS.FNI_SEARCH_PLACEHOLDER} />
      </Box>
      <Box theme={theme} mt={3}>
        <Tab hasBorder autoWidth>
          <ScrollableTabs
            scrollButtons={"auto"}
            theme={theme}
            items={tabs}
            defaultTabIndex={0}
          />
        </Tab>
      </Box>
      <Typography
        theme={theme}
        className="text-muted"
        variant="caption"
        component={"small"}
        display={"block"}
        textAlign={"center"}
        mt={3}
      ></Typography>
      {/* <VehicleDetailsPopup /> */}
      {/* <QuestionnairePopup /> */}
    </PageWrap>
  );
};
