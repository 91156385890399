import { useTheme } from '@mui/material'
import { StepWrap } from './step.style'
import { Box, Button, Icon, Typography } from '@ntpkunity/controls'
import { StepProps } from './step.props'
import clsx from 'clsx'

export const Steps = ({ stepItems}: StepProps) => {
    const theme = useTheme()

    return (
        <StepWrap theme={theme} className='step-wrap'>
            {stepItems.map((item, index) => (
                <Box theme={theme} key={index} className={clsx({ 'step-item': true, 'is-active': item?.isActive, [`${item?.class}-tab`]: !!item?.class })} onClick={item?.onClick}>
                    <Box theme={theme} className={clsx({'card-content': true,[`${item?.class}-tab`]: !!item?.class})}>
                        {item?.title &&
                            <Typography theme={theme} variant='subtitle1' component={'span'} display={'block'} className={clsx({[`${item?.class}-tab`]: !!item?.class})}>{item.title}</Typography>
                        }
                        {item?.subtitle &&
                            <Typography theme={theme} variant='caption' component={'small'} className={clsx({'text-muted': true,[`${item?.class}-tab`]: !!item?.class})} display={'block'}>{item.subtitle}</Typography>
                        }
                    </Box>
                    <Box theme={theme} className={clsx({'step-action': true,[`${item?.class}-tab`]: !!item?.class})}>
                        <Box theme={theme} className={clsx({ 'cj-icon': true, 'error': item?.error, 'success': item?.success,[`${item?.class}-tab`]: !!item?.class })}>
                            {item?.error &&
                                <Icon name='InfoIcon' />
                            }
                            {item?.success &&
                                <Icon name='GreenCheckIcon' />
                            }
                            {item?.isActive &&
                                <Button className={clsx({ [`${item?.class}-tab`]: !!item?.class })} defaultBtn theme={theme} onClick={item?.onClick} iconText={<Icon className={clsx({ [`${item?.class}-tab`]: !!item?.class })} name="IconRightArrow" />} ariaLabel="Icon Right Arrow" />
                            }
                        </Box>
                    </Box>
                </Box>
            ))}
        </StepWrap>

    )
}