import React, {useEffect} from 'react';
import {EmploymentDetailsComponent} from "../../../../libraries/financing-control/EmploymentDetails/employment-info";
import {useCustomDirtyForm} from "../../../../hooks/order-management";
import {useFormContext} from "../../../(order-management)/order/form-context-provider";
import {getDefaultFormValues} from "../../../../utilities/form/employment-details";

const EmploymentDetailsWrapper = ({
  employInfoData,
  customerReferenceId,
  setTab,
  employmentForm,
  watchEmploymentFields, setCustomerDataLead
}) => {
  const {formStates } = useFormContext()
  useCustomDirtyForm('employment', watchEmploymentFields, employmentForm?.formState?.isValid);
  useEffect(() => {
    if(employInfoData) {
      employmentForm?.reset(formStates?.['employment']?.data || getDefaultFormValues(employInfoData))
    }
    }, [employmentForm]);

  return (
    <EmploymentDetailsComponent
      employInfoData={employInfoData}
      customerReferenceId={customerReferenceId}
      setTab={setTab}
      form={employmentForm}
      setCustomerDataLead={setCustomerDataLead}
    />
  );
};

export default EmploymentDetailsWrapper;
