import { FC, useEffect, useReducer, useState } from 'react'
import { CircleLoader, Dialog, Snackbar } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { useAddTransactionId, useAddWayInsurance } from '@hooks'
import { IStates, IVehicle } from '@interfaces'
import { QueryKeys } from '@constants'
import { useQueryClient } from 'react-query'
import { useOrderContext } from '@pages'
import { SnackbarUtility } from '@utilities'
import { Popup } from '@components'
import { WayInsuranceWrap } from './way-insurance.style'
import { useParams } from "react-router-dom";
import { useAppContext } from '@app/context-provider'
import { CreditDecisioning, Event } from 'constants/enums'
import { useCreateOrderDocument, useCreateOrderFniDocument } from 'hooks/order-management'

export const WayInsurance: FC<{ openWayInsuranceDialog: boolean, setOpenWayInsuranceDialog: any }> = ({ openWayInsuranceDialog, setOpenWayInsuranceDialog }) => {
    const theme = useTheme()
    const queryClient = useQueryClient();
    const { state: { order } } = useOrderContext()
    const { state: { default_lender } } = useAppContext()
    const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
    const { mutate: addInsurance } = useAddWayInsurance();
    const { mutate: addTransactionId } = useAddTransactionId();
    const { mutate: createDocument } = useCreateOrderDocument();
    const { mutate: createFniDocument } = useCreateOrderFniDocument();
    const { vin } = useParams()

    const [url, setUrl] = useState()

    const customer: any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_BY_EMAIL)
    const vehicle: IVehicle = queryClient.getQueryData(
        [QueryKeys.VEHICLE, vin]
    );
    const states: IStates[] = queryClient.getQueryData(
        QueryKeys.GET_ALL_STATES
    );

    const handleCloseInsuranceDialog = () => {
        queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, order?.reference_number])
        setOpenWayInsuranceDialog(false)
    }

    const handleDocuments = () => {
        createDocument("response.credit_approval_data", {
            onSuccess() {
                queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, order?.reference_number])
            }
        });
        createFniDocument("response.credit_approval_data", {
            onSuccess() {
                queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, order?.reference_number])
            }
        });
    }

    useEffect(() => {
        if (vehicle && openWayInsuranceDialog) {
            const address = customer?.customer_addresses?.find(f => f.address_type == 'Mailing')
            const data = {
                "email": customer?.email,
                "first_name": customer?.first_name,
                "last_name": customer?.last_name,
                "phone_number": customer?.mobile_number,
                "driver_license_number": customer?.license_info?.license_number,
                "date_of_birth": customer?.license_info?.date_of_birth,
                "address": {
                    address_line1: address?.address_line_1,
                    address_line2: address?.address_line_2,
                    city: address?.city,
                    state: states?.find((x: any) => x?.name === address?.state_name)?.code,
                    postal_code: address?.zip_code
                },
                "vehicles": [vehicle],
                "gender": "Male",
                "marital_status": "S",
                "student": false
            }
            addInsurance(data, {
                onSuccess(res) {
                    setUrl(res?.iframe_url)
                    if (!order?.insurance?.transaction_id) {
                        addTransactionId({ order_id: order?.reference_number, transactionId: res?.transaction_id }, {
                            onSuccess() {
                                queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, order?.reference_number])
                            }
                        })
                    }
                },
                onError(error) {
                    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(error?.detail?.message));
                    setTimeout(() => {
                        snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR());
                        setOpenWayInsuranceDialog(false)
                    }, 3000);
                }
            })
        }
    }, [order?.insurance, vehicle, openWayInsuranceDialog])

    useEffect(() => {
        const handleIframeLoad = (event) => {
            const data = event.data;
            if (data.type === 'continue') {
                default_lender?.credit_decisioning === CreditDecisioning.EXTERNAL && default_lender?.event === Event.INSURANCE && handleDocuments()
                handleCloseInsuranceDialog()
            }
        };
        window.addEventListener('message', handleIframeLoad);

        return () => {
            window?.removeEventListener('message', handleIframeLoad)
        };
    }, []);

    return (
        <>
            <Popup>
                <Dialog
                    disablePortal
                    size="xl"
                    title={"Way Insurance"}
                    open={openWayInsuranceDialog}
                    onCloseDialog={handleCloseInsuranceDialog}
                    theme={theme}
                    noFooter
                >
                    <WayInsuranceWrap theme={theme} className='way-insurance-wrap no-footer'>
                        {
                            url ? <iframe src={url} frameBorder={'0'} width={'100%'} height={'500px'} /> :
                                <CircleLoader theme={theme} size='xl' />
                        }
                    </WayInsuranceWrap>

                </Dialog>
            </Popup>
            <Snackbar
                theme={theme}
                message={snackbarState.message}
                open={snackbarState.open}
                onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            />
        </>
    )
}