import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { PageWrap } from './schedule.style'
import { PageHeader, Popup } from '@components'
import { ConfirmationDialog, MapContainer, ProductDetailCard, QuestionStack } from '@libraries'
import { Box, Button, Dialog, Icon } from '@ntpkunity/controls'
import { RescheduleAppointmentDialog } from 'libraries/add-delivery-detail-popup/delivery-step-one.component'
import { OrderActionType, useOrderContext } from 'pages'
import { IAddress } from '@models'

import { useAppContext } from '@app/context-provider'
import { pagesActionType, useJourneyLayoutContext } from 'layouts/journey-layout/journey-layout-context-provider'
import { IDealerProfile, IStates } from '@interfaces'
import { useQueryClient } from 'react-query'
import { QueryKeys, ScheduleOptions } from '@constants'
import dayjs from 'dayjs'
import {useGetCountries, useUpdateOrderStatus, useVerifyAddress} from '@hooks'
import { getGoogleKey } from '@helpers'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import {Tags, PageType, Status} from 'constants/enums';
import { user_session } from '@constants'
import { useParams } from 'react-router-dom'
import { useCustomDirtyForm, useGetIntegrationByProviderName } from 'hooks/order-management'
import { IntegrationProvider, LenderIntegrationType } from 'constants/providers'
import { useScheduleForm } from '../../../utilities/form/schedule-form'
import { useFormContext } from 'pages/(order-management)/order/form-context-provider'
interface IFormInputs {
  appointment_date: string
  address: IAddress | null
}
export const SchedulePage = () => {
  const theme = useTheme()
  const { state, dispatch } = useOrderContext()
  const order_detail = state?.order
  const [openPopup, setOpenPopup] = useState(false)
  const [tab, setTab] = useState("")
  const { dispatch: pageDispatch } = useJourneyLayoutContext()
  const { mutate: verifyAddress, isLoading: isVerifyAddressLoading } = useVerifyAddress();
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const [openAppointmentPopup, setOpenAppointmentPopup] = useState(false)
  const [scheduleRequestData, setScheduleRequestData] = useState<any>({})
  const { state: appState } = useAppContext()
  const PLACEHOLDERS = appState.language.placeholders;
  const company_id = appState.tenant
  const [addressLocation, setAddressLocation] = useState<any>()
  const [deliveryAddress, setdeliveryAddress] = useState<IAddress | null | undefined>()
  const [selectedTimeZone, setSelectedTimeZone] = useState({ value: '', name: '' });
  const [formattedDate, setFormattedDate] = useState(null);

  const queryClient = useQueryClient();
  const dealerCode = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code ?? 0;
  const dealerProfile: IDealerProfile = queryClient.getQueryData(
   [ QueryKeys.DEALER_PROFILE,dealerCode]
  );
  const states: any = queryClient.getQueryData(QueryKeys.GET_ALL_STATES)
  const { data: countriesServiceData,isFetched} = useGetCountries(appState?.slug)
  const { mutate: updateOrderStatus } = useUpdateOrderStatus()
  const customerData: any = queryClient.getQueryData(
    QueryKeys.GET_CUSTOMER_BY_EMAIL
  );
  const handleAppointmentDialog = () => {
    setOpenAppointmentPopup(false)
    setOpenPopup(false)
  }
  const { scheduleForm: scheduleForm, watchScheduleFields } = useScheduleForm(deliveryAddress, PLACEHOLDERS);
  const {clearErrors,trigger,formState:{isValid}}=scheduleForm
  useCustomDirtyForm('schedule', watchScheduleFields, scheduleForm?.formState?.isValid)
  const {  resetFormState } = useFormContext()

  const [isDateTimeView, setIsDateTimeView] = useState(true)
  const[submitText,setSubmitText]=useState(PLACEHOLDERS.BUTTON_TEXT_CONTINUE)
  const [countryInfo, setCountryInfo] = useState<{ countries: any[]; dealerCountry: any[] }>({
    countries: [],
    dealerCountry: [],
  });

  useEffect(() => {
    if (isFetched) {
      const filteredCountry = countriesServiceData?.filter(
          (x: any) => x?.id === dealerProfile?.dealer_address?.country_id
      ) || [];

      setCountryInfo({
        countries: countriesServiceData || [],
        dealerCountry: filteredCountry,
      });
    }
  }, [countriesServiceData, isFetched]);
  const handleContinue = () => {
    if (scheduleForm.watch(["appointment_date", "selectedTime"])) {
      setIsDateTimeView(false)
    }
  };

  const handleBack = () => {
    setIsDateTimeView(true);
  };
  const checkValuePickUp = () =>{
    if(scheduleRequestData.appointment_type===ScheduleOptions.PICKUP){


      scheduleForm.setValue("selectedDate","")
      scheduleForm.setValue("selectedTime","")
      setSelectedTimeZone({ value: '', name: '' })
      setIsDateTimeView(true);
    }
  }
  const checkValueDelivery = () =>{
    if(scheduleRequestData.appointment_type===ScheduleOptions.DELIVERY){
      scheduleForm.setValue("selectedDate","")
      scheduleForm.setValue("selectedTime","")
      setSelectedTimeZone({ value: '', name: '' })
    }
  }
  const { mutate: getProvider} = useGetIntegrationByProviderName( IntegrationProvider.GOOGLE,
    LenderIntegrationType.ADDRESS_VALIDATION,company_id, appState?.slug);
  const [isGoogleEnabled, setIsGoogleEnabled] = useState(false)
  const { tenant } = useParams()
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const { mutate: createTags } = useTags();
    useEffect(() => {
      if(company_id){
      getProvider(
        {
          provider_name: IntegrationProvider.GOOGLE,
          integration_type: LenderIntegrationType.ADDRESS_VALIDATION,
          company_id:company_id
        },
        {
          onSuccess(response: any) {
            setIsGoogleEnabled(response?.is_active)
          }
        }
      )
    }
    }, [company_id])

  useEffect(() => {
    if (Object.keys(scheduleForm?.formState?.errors)?.length) {
      pageDispatch({
        type: pagesActionType.UPDATE_SCHEDULE,
        payload: {
          success: false,
          error: true
        }
      })
    }
  }, [scheduleForm?.formState?.errors])
  useEffect(() => {
    if (openPopup) {
      setOpenAppointmentPopup(openPopup)
    }
  }, [openPopup])
  useEffect(() => {
    const page_event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.INSURANCE_SESSION_END,
      user_id: user_profile?.user_id,
      page_type:PageType.Insurance
      }
      createTags(page_event)
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.DISPLAY_SCHEDULE_APPOINTMENTS,
      user_id: user_profile?.user_id,
      dealer_id: +order_detail?.dealer_id,
      vehicle_make: order_detail?.order_asset?.make,
      vehicle_model: order_detail?.order_asset?.model,
      order_id:order_detail?.identifier,
      vin:order_detail?.vin,
      page_type:PageType.ScheduleAppointments
      }
      createTags(event)
  }, [])
  const schedule_value= (val:any)=>{
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:val,
      user_id: user_profile?.user_id,
      page_type:PageType.ScheduleAppointments
      }
      createTags(event)
  }
  useEffect(() => {

    if (order_detail?.appointment_details) {
      setScheduleRequestData(order_detail?.appointment_details)
      setTab(order_detail?.appointment_details?.appointment_type)
      if (order_detail?.appointment_details?.appointment_type == ScheduleOptions.PICKUP && isGoogleEnabled) {
        schedule_value(ScheduleOptions.PICKUP)
        getVerifyGoogleAddress(dealerProfile?.dealer_address?.address_line_1 + " " + dealerProfile?.dealer_address?.address_line_2 + " " + dealerProfile?.dealer_address?.city + " " + dealerProfile?.dealer_address?.state_name)
      }
      else if (order_detail?.appointment_details?.appointment_type == ScheduleOptions.DELIVERY && isGoogleEnabled) {
        schedule_value(ScheduleOptions.DELIVERY)
        const addressStr = order_detail?.appointment_details?.address?.address_line_1 + " " + order_detail?.appointment_details?.address?.address_line_2 + " " + order_detail?.appointment_details?.address?.city + " " + order_detail?.appointment_details?.address?.state_name
        getVerifyGoogleAddress(addressStr)
      }
    }
  }, [order_detail?.appointment_details, isGoogleEnabled])

  useEffect(() => {
    if (customerData) {
      const address = customerData?.customer_addresses?.find(
        (x: any) =>
          (x?.address_type === "Mailing" && x?.is_garaging) ||
          x?.address_type === "Garaging"
      )
      scheduleForm.setValue('address', address)
      scheduleForm.setValue('address.state_name', address?.state_name)
      scheduleForm.setValue('address.state_id', states?.find((x: any) => x.name === address?.state_name)?.id)
    }
  }, [customerData])

  const getVerifyGoogleAddress = (address: string) => {
    verifyAddress(
      {
        address: address,
      },
      {
        onSuccess: (data) => {
          setAddressLocation(data.results[0]?.geometry?.location)
        },
      }
    );
  };

  const addAppointment = () => {
    setScheduleRequestData({
      ...scheduleForm.getValues(),
      id: order_detail?.appointment_details?.id ? order_detail?.appointment_details?.id : 0,
      appointment_type: tab,
      time_zone:scheduleForm.getValues("time_zone"),
      selectedDate:scheduleForm.getValues("selectedDate"),
      selectedTime:scheduleForm.getValues("selectedTime"),
      address_data:
        tab == ScheduleOptions.DELIVERY && scheduleForm.getValues("address")
          ? {
            address_line_1: scheduleForm.getValues("address").address_line_1,
            address_line_2: scheduleForm.getValues("address").address_line_2,
            state_name: scheduleForm.getValues("address").state_name,
            zip_code: scheduleForm.getValues("address").zip_code,
            county: scheduleForm.getValues("address").county,
            city: scheduleForm.getValues("address").city,
            contact_number: scheduleForm.getValues("contact_number"),
            verified: true,
            address_type: 'Garaging'
          }
          : null
    })

    dispatch({
      type: OrderActionType.UPDATE_SCHEDULE_ORDER,
      payload: {
        ...scheduleForm.getValues(),
        id: order_detail?.appointment_details?.id ? order_detail?.appointment_details?.id : 0,
        appointment_type: tab,
        time_zone: scheduleForm.getValues("time_zone"),
        selectedDate: scheduleForm.getValues("selectedDate"),
        selectedTime: scheduleForm.getValues("selectedTime"),
        address_data:
          tab == ScheduleOptions.DELIVERY && scheduleForm.getValues("address")
            ? {
              address_line_1: scheduleForm.getValues("address").address_line_1,
              address_line_2: scheduleForm.getValues("address").address_line_2,
              state_name: scheduleForm.getValues("address").state_name,
              zip_code: scheduleForm.getValues("address").zip_code,
              county: scheduleForm.getValues("address").county,
              city: scheduleForm.getValues("address").city,
              contact_number: scheduleForm.getValues("contact_number"),
              verified: true,
              address_type: 'Garaging'
            }
            : null
      }
    })
    pageDispatch({
      type: pagesActionType.UPDATE_SCHEDULE,
      payload: {
        success: true,
        error: false
      }
    })
    setOpenPopup(false)
  }
  const removeFormData = () => {
    resetFormState('schedule')
    scheduleForm.setValue("selectedDate", "")
    scheduleForm.setValue("selectedTime", "")
    scheduleForm.setValue("time_zone", "")
    setIsDateTimeView(true);
    if(tab===ScheduleOptions.PICKUP){
      scheduleForm.setValue("appointment_date", ""), scheduleForm.setValue("address", customerData?.customer_addresses?.find(
        (x: any) =>
          (x?.address_type === "Mailing" && x?.is_garaging) ||
          x?.address_type === "Garaging"
      ));
    }
    else{
      scheduleForm.setValue("appointment_date", ""), scheduleForm.setValue("address", customerData?.customer_addresses?.find(
        (x: any) =>
          (x?.address_type === "Mailing" && x?.is_garaging) ||
          x?.address_type === "Garaging"
      ));
    }
    updateOrderStatus({
      status: Status.Approved,
      reference_number: order_detail?.reference_number,
      user_id: 0,
    })
    setScheduleRequestData({});
    dispatch({
      type: OrderActionType.UPDATE_SCHEDULE_ORDER,
      payload: null
    })
    pageDispatch({
      type: pagesActionType.UPDATE_SCHEDULE,
      payload: {
        success: false,
        error: false
      }
    })
  }

  const submitAppointment = () => {
  // google address is disabled so address is verified true
    scheduleForm.setValue('address.verified',true)

    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.INFORMATION_FILL,
      user_id: user_profile?.user_id,
      page_type:PageType.ScheduleAppointments
      }
      createTags(event)

    if (scheduleForm.getValues("address")?.verified && tab === ScheduleOptions.DELIVERY) {
      addAppointment()
    }
    else if (tab === ScheduleOptions.PICKUP) {
      addAppointment()
    }
    else {
      setOpenPopup(true)
    }
  }
  useEffect(() => {
    const appointmentDate = scheduleRequestData?.appointment_date;
    if (appointmentDate && scheduleRequestData?.time_zone) {
      const formatted = dayjs(appointmentDate)
          .tz(scheduleRequestData?.time_zone)
          .format('MMMM DD, YYYY, hh:mm A');
      setFormattedDate(formatted);
    } else {
      setFormattedDate(null);
    }
  }, [scheduleRequestData?.appointment_date, selectedTimeZone?.value]);

  return (
    <PageWrap theme={theme} className='page-wrap'>
      <PageHeader
        marginBottom={3}
        title={PLACEHOLDERS.SCHEDULE_TITLE}
        subTitle={PLACEHOLDERS.SCHEDULE_SUB_TITLE}
      />
      {dealerProfile?.schedule_option?.find((item => item == 'pickup')) && scheduleRequestData?.appointment_type != ScheduleOptions.PICKUP &&
        <QuestionStack
          dFlex
          title={PLACEHOLDERS.SCHEDULE_DEALERSHIP_STORE_PICKUP}
          actionArea
          onLinkClick={() => {
            checkValueDelivery()
            setOpenPopup(true);
            setTab(ScheduleOptions.PICKUP);
            schedule_value(PLACEHOLDERS.SCHEDULE_DEALERSHIP_STORE_PICKUP)
          }}
        />}

      {(tab === ScheduleOptions.PICKUP || scheduleRequestData?.id >= 0) && scheduleRequestData?.appointment_type == ScheduleOptions.PICKUP && <ProductDetailCard
        title={PLACEHOLDERS.SCHEDULE_OPTIONS_PICKUP_TITLE}
        caption={
          [
            dealerProfile?.dealer_address.address_line_1,
            dealerProfile?.dealer_address.address_line_2,
            dealerProfile?.dealer_address.city,
            dealerProfile?.dealer_address.state_name,
            dealerProfile?.dealer_address.zip_code,
            countryInfo?.dealerCountry?.[0]?.name
          ].filter(Boolean).join(", ")
        }
        listItems={[
          {
            title: PLACEHOLDERS.SCHEDULE_LIST_ITEM_SCHEDULED_ON,
            value: formattedDate
          },
          {
            title: PLACEHOLDERS.SCHEDULE_LIST_ITEM_CONTACT_NUMBER,
            value: dealerProfile?.customer_support_number
          }
        ]}
        onBtnClick={() => {
          // edit on click
          setOpenPopup(true); scheduleForm.reset({
            ...scheduleRequestData,
            appointment_date: dayjs(scheduleRequestData.appointment_date),
            time_zone:scheduleRequestData.time_zone,
            selectedDate:scheduleRequestData.selectedDate,
            selectedTime:scheduleRequestData.selectedTime

          })
        }}
        buttonText={PLACEHOLDERS.SCHEDULE_EDIT_SCHEDULE}
        onLinkClick={() => {
          // remove on click
          setConfirmationPopupState(true)
        }}
        linkText={PLACEHOLDERS.SCHEDULE_REMOVE_SCHEDULE}
        mediaContent={
          isGoogleEnabled && <MapContainer location={addressLocation} />
        }
      />}
      {dealerProfile?.schedule_option?.find((item => item == 'delivery')) && scheduleRequestData?.appointment_type != ScheduleOptions.DELIVERY && <QuestionStack
        dFlex
        title={PLACEHOLDERS.SCHEDULE_OPTIONS_DELIVERY_QUESTION}
        actionArea
        onLinkClick={() => {
          checkValuePickUp()
          setOpenPopup(true);
          setTab(ScheduleOptions.DELIVERY);
          schedule_value(PLACEHOLDERS.SCHEDULE_OPTIONS_DELIVERY_QUESTION)
         }}
        marginTopXs={3}
        marginTopMd={3}
      />}
      {(tab === ScheduleOptions.DELIVERY || scheduleRequestData?.id >= 0) && scheduleRequestData?.appointment_type == ScheduleOptions.DELIVERY &&
        <Box theme={theme} mt={3}>
          <ProductDetailCard
            title={PLACEHOLDERS.SCHEDULE_OPTIONS_DELIVERY_TITLE}
            caption={[
              scheduleRequestData?.address?.address_line_1,
              scheduleRequestData?.address?.address_line_2,
              scheduleRequestData?.address?.city,
              scheduleRequestData?.address?.state_name,
              scheduleRequestData?.address?.zip_code
            ].filter(Boolean).join(", ") + ", United States"
            }
            listItems={[
              {
                title: PLACEHOLDERS.SCHEDULE_LIST_ITEM_SCHEDULED_ON,
                value: formattedDate
              },
              ...(scheduleRequestData?.contact_number ? [{
                title: PLACEHOLDERS.SCHEDULE_LIST_ITEM_CONTACT_NUMBER,
                value: scheduleRequestData?.contact_number
              }] : [])

            ]}
            onBtnClick={() => {
              setOpenPopup(true);
              setIsDateTimeView(true);
              scheduleForm.reset({
                ...scheduleRequestData,
                appointment_date: dayjs(scheduleRequestData.appointment_date),
                time_zone: scheduleRequestData.time_zone,
                selectedDate: scheduleRequestData.selectedDate,
                selectedTime: scheduleRequestData.selectedTime
              })
            }}
            buttonText={PLACEHOLDERS.SCHEDULE_EDIT_SCHEDULE}
            onLinkClick={() => {
              setConfirmationPopupState(true)
            }}
            linkText={PLACEHOLDERS.SCHEDULE_REMOVE_SCHEDULE}
            mediaContent={
              isGoogleEnabled && <MapContainer location={addressLocation} />
            }
          />
        </Box>}
      {openPopup &&
      <Popup>
      <Dialog
        disablePortal
        variant={undefined}
        size="sm"
        title={PLACEHOLDERS.SCHEDULE_APPOINTMENT_TITLE}
        open={openAppointmentPopup}
        onCloseDialog={ handleAppointmentDialog}
        onBackBtn={tab === ScheduleOptions.DELIVERY ? handleBack : null}
        customFooter={
          <>
            {scheduleForm.watch("selectedDate") && scheduleForm.watch("selectedTime") && selectedTimeZone.value && (
              tab === ScheduleOptions.DELIVERY ? (
                <Button
                  theme={theme}
                  primary
                  text={submitText}
                  onClick={isDateTimeView  ? handleContinue : submitAppointment}
                />
              ) : ( // Pickup case
                <Button
                  
                  theme={theme}
                  primary
                  text={PLACEHOLDERS.CONFIRM_SCHEDULE_BUTTON_TEXT}
                  onClick={submitAppointment}
                />
              )
            )}
          </>
        }

        theme={theme}
      > <RescheduleAppointmentDialog
          tab={tab}
          scheduleOrderForm={scheduleForm}
          isGoogleEnabled={isGoogleEnabled}
          isDateTimeView={isDateTimeView}
          setIsDateTimeView={setIsDateTimeView}
          setSelectedTimeZone={setSelectedTimeZone}
          selectedTimeZone={selectedTimeZone}
          setSubmitText={setSubmitText}
           />
      </Dialog>
      </Popup>}

      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={removeFormData}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={PLACEHOLDERS.SCHEDULE_REMOVE_APPOINTMENT_DETAILS_CONFIRMATION}
          confirmationText={PLACEHOLDERS.SCHEDULE_UNDO_ACTION_CONFIRMATION}
          primaryBtnText={PLACEHOLDERS.CONFIRM_TEXT}
          secondaryBtnText={PLACEHOLDERS.CANCEL_TEXT}
          icon={<Icon name="ErrorTriangle" />}
          onCancel={function (_action: any): void {
            setConfirmationPopupState(false)
          }}
          hideCancelButton={false}
        />
      )}
    </PageWrap>
  )
};