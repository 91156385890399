import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ChooseDateTimeWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.choose-date-time-wrap': {
        '.choose-date-switch': {
            borderRadius: 8,
            '.MuiFormGroup-root': {
                '.MuiFormControlLabel-labelPlacementStart': {
                    justifyContent: 'space-between',
                    paddingLeft: '4px',
                    gap: 24
                }
            }
        },
        '.custom-menu': {
            '.MuiButtonBase-root.MuiButton-root': {
                color: theme.palette.primary.main,
                padding: 0,
                fontSize: 12,
            }
        },
        '.choose-time': {
            '.selection-button-wrap': {
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                alignSelf: 'stretch',
                flexWrap: 'wrap',
                '.cj-btn': {
                    width: 100,
                    flex: 1
                }
            }
        }
    }
}))
