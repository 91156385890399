import React, { FC } from "react";
import { FooterWrap } from "./footer.style";
import { useTheme } from "@mui/material";
import { Box, Grid, Icon, Typography } from "@ntpkunity/controls";
import { useAppContext } from "@app/context-provider";
import { formatUrl } from "helpers/methods";
import clsx from "clsx";
export const Footer: FC = () => {
	const {
		state: {
			collapse_state,
			social_media_icons,
			footer_links,
			language: { placeholders },
		},
	} = useAppContext();
	const theme = useTheme();
	const currentYear = new Date().getFullYear();
	const hasSocialMedia = Object.values(social_media_icons).some((icon) => icon);

	return (
		<FooterWrap theme={theme} className={clsx("footer-wrap", collapse_state ? "collapse" : "")}>
			<Grid theme={theme} container item spacing={{ xs: 0, sm: 3 }}>
				<Grid theme={theme} item xs={12} sm={8}>
					<Box theme={theme} display={"flex"} alignItems={"center"} gap={3} className="nav-links">
						{footer_links.length > 0 &&
							footer_links.map(({ menu_url, menu_name }) => (
								<Typography key={menu_name} theme={theme} component={"span"} variant="body2" className="nav-link" display={"block"}>
									<a href={formatUrl(menu_url)} rel="noopener noreferrer">
										{menu_name}
									</a>
								</Typography>
							))}
					</Box>
				</Grid>
				<Grid theme={theme} item xs={12} sm={4}>
					<Typography theme={theme} component={"span"} variant="body2" className="text-muted all-right" display={"block"}>
						&copy; {currentYear} AppexNow. {placeholders?.FOOTER_ALL_RIGHT_RESERVED}
					</Typography>
				</Grid>

					{hasSocialMedia && (
						<Grid theme={theme} item xs={12} sm={8} justifyContent="flex-start">
							<Box theme={theme} display={"flex"} alignItems={"center"} gap={4} justifyContent={{ sm: "flex-start" }} className="social-links">
								{social_media_icons.facebook && (
									<a href={formatUrl(social_media_icons.facebook)} target="_blank" rel="noopener noreferrer" aria-label="facebook">
										<Icon name="FacebookIcon" />
									</a>
								)}
								{social_media_icons.instagram && (
									<a href={formatUrl(social_media_icons.instagram)} target="_blank" rel="noopener noreferrer" aria-label="instagram">
										<Icon name="InstagramIcon" />
									</a>
								)}
								{social_media_icons.linkedin && (
									<a href={formatUrl(social_media_icons.linkedin)} target="_blank" rel="noopener noreferrer" aria-label="linkdin">
										<Icon className="ic-16" name="LinkedInIcon" />
									</a>
								)}
								{social_media_icons.twitter && (
									<a href={formatUrl(social_media_icons.twitter)} target="_blank" rel="noopener noreferrer" aria-label="twitter">
										<Icon className="ic-16" name="TwitterXIcon" />
									</a>
								)}
								{social_media_icons.google && (
									<a href={formatUrl(social_media_icons.google)} target="_blank" rel="noopener noreferrer" aria-label="google">
										<Icon className="ic-16" name="GoogleIcon" />
									</a>
								)}
								{social_media_icons.youtube && (
									<a href={formatUrl(social_media_icons.youtube)} target="_blank" rel="noopener noreferrer" aria-label="youtube">
										<Icon name="YoutubeIcon" />
									</a>
								)}
							</Box>
						</Grid>
					)}
				
			</Grid>
		</FooterWrap>
	);
};
