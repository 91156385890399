export enum LenderIntegrationType {
    ADDRESS_VALIDATION = 'location_services',
    FRAUD_IDENTIFICATION = 'fraud_identification',
    EXISTING_CUSTOMER_CHECK = 'customer_management',
    INVENTORY_UPLOAD = 'inventory_management',
    FNI_PRODUCTS = 'fni_products',
    IDENTITY_VERIFICATION = 'identity_verification',
    TRADE_IN_VALUATION = 'trade_in_valuation',
    PAYMENT_PROCESSING = 'payment_processing',
    COBROWSING = 'co_browsing',
    CALCULATION_AND_TAXATION= 'calculation_and_taxation',
    PURCHASE_INSURANCE= 'purchase_insurance'
}

export enum IntegrationProvider {
    GOOGLE = 'Google',
    SOCURE = 'Socure',
    KBB = 'KBB',
    MITEK = 'MiTek',
    PEN = 'PEN',
    SHIFT_DIGITAL = 'ShiftDigital',
    CDK = 'Cdk',
    TEKION = 'Tekion',
    STRIPE_CONNECT = 'Stripe Connect',
    PLAID = 'Plaid',
    UPSCOPE='upscope',
    MARKETSCAN='MarketScan',
    WAY = 'Way'
}