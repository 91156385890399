import { FC } from 'react'
import { useTheme } from '@mui/material'
import { PageHeader } from '@components'
import { PageWrap } from './contracting-control.style'
import { useAppContext } from '@app/context-provider'
import { DocumentPackage } from './document-package.component'
import { DocumentPackageTypes } from 'constants/enums'
import { GeneralDocument } from './general-document.component'
import { useOrderContext } from '@pages'
import { IDocumentPackage } from '@interfaces'

export const ContractingControl: FC<{}> = ({ }) => {
  const theme = useTheme()
  const { state: { order } } = useOrderContext()
  const appState = useAppContext();
  const PLACEHOLDERS = appState.state.language.placeholders;

  const handleDocumentPackage = (documentPackage: IDocumentPackage) => {
    switch (documentPackage.type) {
      case DocumentPackageTypes.CONTRACT:
        return documentPackage?.documents?.length > 0 && <DocumentPackage documentPackage={documentPackage} PLACEHOLDERS={PLACEHOLDERS} key={documentPackage?.identifier} />
      case DocumentPackageTypes.GENERAL:
        return documentPackage?.documents?.length > 0 && <GeneralDocument documentPackage={documentPackage} PLACEHOLDERS={PLACEHOLDERS} key={documentPackage?.identifier} />
    }
  }

  const sortDocumentPackages = (documentPackages: IDocumentPackage[]) => {
    return documentPackages.sort((a, b) => {
      if (a.type === DocumentPackageTypes.CONTRACT && b.type === DocumentPackageTypes.GENERAL) {
        return -1;
      }
      if (a.type === DocumentPackageTypes.GENERAL && b.type === DocumentPackageTypes.CONTRACT) {
        return 1;
      }
      return 0;
    });
  };

  return (
    <PageWrap theme={theme} className='page-wrap'>
      <PageHeader
        marginBottom={3}
        title={PLACEHOLDERS.CONTRACTING_PAPERWORK_DONE}
        subTitle={
          <>
            {PLACEHOLDERS.CONTRACTING_READ_CONTRACT}{" "}
            <a href="javascript:void(0)" className="text-primary decoration-none" aria-label={PLACEHOLDERS.CONTRACTING_CONTACT_US}>{PLACEHOLDERS.CONTRACTING_CONTACT_US}</a>
            {` ${PLACEHOLDERS.CONTRACTING_CONFUSION_HELP}`}
            {PLACEHOLDERS.DOT_TEXT}
          </>
        }
      />
      {sortDocumentPackages(order?.documents || [])?.map((documentPackage) => {
        return handleDocumentPackage(documentPackage)
      })}
    </PageWrap>
  )
}