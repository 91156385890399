import { IFniControlProps, IOrderFnI } from "@interfaces";
import { FniProduct } from "@libraries";
import { useParams } from 'react-router-dom'
import { pagesActionType, useJourneyLayoutContext } from "layouts/journey-layout/journey-layout-context-provider";
import {
  OrderCreateContext,
  updateFnI,
} from "pages/(order-management)/order/order-context-provider";
import { FC, useContext, useEffect, useState } from "react";
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags,PageType } from 'constants/enums';
import { user_session } from '@constants'
import { useOrderContext } from 'pages/(order-management)/order/order-context-provider'

export const FnIProductsPage = ({allFniProducts}) => {
  const { state } = useContext(OrderCreateContext);
  const { tenant } = useParams()
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const { mutate: createTags } = useTags();
  const { state: { order } } = useOrderContext()
  useEffect(() => {
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.PAYMENT_CALC,
      user_id: user_profile?.user_id,
      dealer_id: +order?.dealer_id,
      vehicle_make: order?.order_asset?.make,
      vehicle_model: order?.order_asset?.model,
      order_id:order?.identifier,
      page_type:PageType.FIPage
      }
      createTags(event)
  }, [state?.order?.order_fnI]);
  useEffect(()=>{
    const page_event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.PAYMENT_END,
      user_id: user_profile?.user_id,
      page_type:PageType.Payments
      }
      createTags(page_event)
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.DISPLAY_FNI,
      user_id: user_profile?.user_id,
      dealer_id: +order?.dealer_id,
      vehicle_make: order?.order_asset?.make,
      vehicle_model: order?.order_asset?.model,
      order_id:order?.identifier,
      page_type:PageType.FIPage
      }
      createTags(event)
  },[])
  return (
    <FniProduct
      finance_type={state.order.finance_type}
      terms={state.order.contract_term}
      apr={state.order.apr}
      addedFniProducts={state?.order?.order_fnI}
      allFniProducts={allFniProducts}
    />
  );
};
