import { Stack } from '@components'
import { PageWrap, QuestionStack, SwitchStack } from '@libraries'
import { useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'

export const PrefrencesPage = () => {
    const theme = useTheme()
    return (
        <>
            <PageWrap theme={theme}>
                <Box theme={theme}>
                    <Typography theme={theme} variant='h3' component={'h3'} mb={1}>Product Updates</Typography>
                    <Typography theme={theme} variant='body2' component={'span'} className='text-muted'>Choose email content you recieve from us.</Typography>
                </Box>
                <Box theme={theme} mt={3}>
                    <SwitchStack
                        title={'Product Updates'} 
                        subTitle={'The latest product improvements and updates.'}
                        actionArea
                        onSwitchClick={()=>{}}
                    />
                    <SwitchStack
                        title={'Company News'} 
                        subTitle={'Stay in the loop with what’s happening on our side.'}
                        actionArea
                        onSwitchClick={()=>{}}
                        marginTopXs={2}
                    />
                    <SwitchStack
                        title={'Deals'} 
                        subTitle={'Promotional offers to save you money.'}
                        actionArea
                        onSwitchClick={()=>{}}
                        marginTopXs={2}
                    />
                </Box>

                <Box theme={theme} mt={5}>
                    <Typography theme={theme} variant='h3' component={'h3'} mb={1}>Your Activity</Typography>
                    <Typography theme={theme} variant='body2' component={'span'} className='text-muted'>Choose emails about your products, and feedback for us.</Typography>
                </Box>
                <Box theme={theme} mt={3}>
                    <SwitchStack
                        title={'Product Usage'} 
                        subTitle={'Receive notifications about your usage. These include product updates (delete, archive, invite), confirmations, etc.'}
                        actionArea
                        onSwitchClick={()=>{}}
                    />
                    <SwitchStack
                        title={'Account Activity'} 
                        subTitle={"Get important notifications about you or activity you've missed."}
                        actionArea
                        onSwitchClick={()=>{}}
                        marginTopXs={2}
                    />
                    <SwitchStack
                        title={'New Messages'} 
                        subTitle={'Get news, announcements, and product updates.'}
                        actionArea
                        onSwitchClick={()=>{}}
                        marginTopXs={2}
                    />
                    <SwitchStack
                        title={'Chat Notifications'} 
                        subTitle={"Receive notifications every time someone replies to your message on a thread you're following on an order."}
                        actionArea
                        onSwitchClick={()=>{}}
                        marginTopXs={2}
                    />
                    <SwitchStack
                        title={'Feedback'} 
                        subTitle={'Help us improve by sharing feedback with us.'}
                        actionArea
                        onSwitchClick={()=>{}}
                        marginTopXs={2}
                    />
                </Box>
            </PageWrap>
        </>
    )
}