import { useTheme } from '@mui/material'
import { PageWrap } from './payment.style'
import { PageHeader, Tab } from '../../../components'
import { DuoTab } from '@ntpkunity/controls'
import { FC, useContext } from 'react'
import { FinanceTypes, QueryKeys } from '@constants'
import { TabContent } from '../tab-content/tab-content'
import { useOrderContext } from 'pages'
import { AppContext } from '@app/context-provider'
import { updateFinanceType, UpdateisPaymentUpdated } from 'pages/(order-management)/order/order-context-provider'
import {OrderUpdatePopup} from '../../order-update-popup/order-Update-popup'
import { useQueryClient } from 'react-query'
import { IVehicle } from '@interfaces'
import {useParams} from "react-router-dom";
import {useUpdateFinancialIndicator} from "../../../hooks/order-management";



export const Payment: FC<{
  isLoading: boolean,
  setupDataLoading: boolean,
  setLastSelectedTerm:  any
  program: any
}> = ({
  isLoading, setupDataLoading, setLastSelectedTerm, program
}) => {
    const theme = useTheme()
    const { state: { order: orderState }, dispatch: orderDispatch,handleUpdateOrder } = useOrderContext()
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders
  const { mutate: mutateIsPaymentUpdate } = useUpdateFinancialIndicator();

  const handlePopupClose =  async() => {
    orderDispatch( UpdateisPaymentUpdated(false))
    mutateIsPaymentUpdate(
        {id:orderState?.reference_number,
          status:false
        }

    )
  };
  const {  vin } = useParams()
  const queryClient = useQueryClient();
  const vehicle: IVehicle = queryClient.getQueryData(
    [QueryKeys.VEHICLE, vin]
  );


    const tabItems = [
      {
        title: PLACEHOLDERS.PAYMENT_TITLE_LEASE,
        content: <TabContent
          program={program?.finance_type === FinanceTypes.LEASE ? program: null}
          isLoading={isLoading}
          setupDataLoading={setupDataLoading}
          setLastSelectedTerm={setLastSelectedTerm}
        />
      },
      {
        title: PLACEHOLDERS.PAYMENT_TITLE_FINANCE,
        content: <TabContent
          program={program?.finance_type === FinanceTypes.FINANCE ? program: null}
          isLoading={isLoading}
          setupDataLoading={setupDataLoading}
          setLastSelectedTerm={setLastSelectedTerm}
        />
      },
    ]

    return (
      <>
      <PageWrap className='page-wrap' theme={theme}>
        <PageHeader
          marginBottom={3}
          title={PLACEHOLDERS.PAYMENT_TITLE}
        />
        <Tab>
       {<OrderUpdatePopup isOpen={orderState?.is_payment_updated} onClose={handlePopupClose} popupimage={vehicle?.photo_urls?.[0]?.location ?? ''}/>}
        <DuoTab
            varient='default'
            theme={theme}
            items={tabItems}
            defaultTabIndex={0}
            value={orderState.finance_type === FinanceTypes.LEASE ? 0 : 1}
            onChange={(e, value) => {
              orderDispatch(updateFinanceType(value === 0 ? FinanceTypes.LEASE : FinanceTypes.FINANCE))
            }}
          />
        </Tab>

      </PageWrap>
      </>
    )
  }
