import styled from '@emotion/styled'
import { Theme } from '@mui/material'
import { FC, useState } from 'react'
import { Grid, IBoxProps, Icon, Box, Button, Typography, IGridProps, Select } from '@ntpkunity/controls'
import clsx from 'clsx'
import ResponsiveCarousel from '../ResponsiveCarousel/ResponsiveCarousel'
import { VehicleCardUi } from '../VehicleCardUi'
import Menu from '../Menu/Menu'
import AssetDetailControl from '../AssetDetailCard'
import React from 'react'
import { Search, SearchBarTags, Stack } from '@components'
import { FilterControl, RightPanel } from './assetListViewControl.style'

type IAssetListViewControlProps = {
  theme: Theme
  openFilter: boolean
  setOpenFilter: any
  inventoryData: any
  setOrderByState: any
  defaultCurrency: string
  onListViewClick: (vin: string) => void
  onClick: () => void
}
export const MultiSelectWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  width: '100%',
  display: 'inline-block',
  marginBottom: 24,

  '&:last-child': {
    marginBottom: 0
  },
  '.u-dropdown-item': {
    '.custom-checkbox-wrap': {
      width: '100%',
      '.u-custom-control-label': {
        margin: 0,
        display: 'inline-block',
        width: '100%',
        '.MuiButtonBase-root': {
          marginRight: 0,
          position: 'absolute',
          width: '97%',
          textAlign: 'left',
          display: 'inline-block'
        },
        '.MuiFormControlLabel-label': {
          marginLeft: '30px',
          paddingTop: '24px',
          '&:hover': {
            '&:after': {
              display: 'none !important'
            }
          }
        }
      }
    }
  }
}))


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

const prefixOptions = [
  {
      text: 'Recommended',
      value: 'descending'
  },
  {
      text: 'Recommended',
      value: 'descending'
  }
]

const AssetListViewControl: FC<IAssetListViewControlProps> = ({
  theme,
  openFilter,
  setOpenFilter,
  inventoryData,
  setOrderByState,
  defaultCurrency,
  onListViewClick,
  onClick
}) => {
  const [openPopup, setOpenPopup] = useState(false)
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null)

  return (
    <>
      {openPopup && (
        <AssetDetailControl
          theme={theme}
          selectedVehicle={selectedVehicle}
          defaultCurrency={defaultCurrency}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClick={onClick}
        />
      )}
      <Grid
        theme={theme}
        item
        xs={12}
        md={12}
        lg={12}
        maxWidth={{lg: 1352}} m={{lg: 'auto'}} mr={{lg: 'auto'}}
      >
        <RightPanel theme={theme} className={clsx('right-content', openFilter ? '' : 'expand')}>
          <Grid theme={theme} container item spacing={2}>
            <Grid theme={theme} item xs={12}>
              <Stack paddingMd={3}>
                <Box theme={theme} className="search-area">
                  <Search placeholder='search...' />
                </Box>
                <SearchBarTags 
                  tags={[
                    {
                      tag: 'Color',
                      onClick: ()=>{},
                    },
                    {
                      tag: 'Transmission',
                      onClick: ()=>{},
                    },
                    {
                      tag: 'Test Drive',
                      onClick: ()=>{},
                    },
                    {
                      tag: 'Price',
                      onClick: ()=>{},
                    }
                  ]}
                  onLinkClick={()=>{}}
                  linkText={'Clear All'}
                  />
              </Stack>
              <FilterControl theme={theme} className="filters-control">
                {/* <Button
                  defaultBtn
                  theme={theme}
                  startIcon={<Icon name="FilterIcon" />}
                  text={openFilter ? 'Hide Filters' : 'Show Filters'}
                  onClick={() => setOpenFilter(!openFilter)}
                ></Button> */}
                <Typography theme={theme} variant='subtitle2' component={'p'}>
                  9 results for  sample dealership
                </Typography>

                <Box theme={theme} className='location'>
                  <Box theme={theme} display={'flex'} gap={0.5}>
                    <Typography theme={theme} variant='body2' component={'p'} className='fw-medium text-muted'>
                      Location: 
                    </Typography>
                    <Typography theme={theme} variant='body2' component={'p'} className='fw-medium'>
                      90201 
                    </Typography>
                    <Typography theme={theme} variant='body2' component={'p'}>
                      <a href='javascript:void(0)' className='fw-medium decoration-none text-primary' aria-label='edit'>
                        Edit
                      </a>
                    </Typography>
                  </Box>
                  
                  <Box theme={theme} className='select'>
                    <Typography theme={theme} variant='body2' component={'p'} className='fw-medium text-muted'>
                      Sort By: 
                    </Typography>
                    <Select 
                      theme={theme}
                      items={prefixOptions}
                      disablePortal={false}
                      onChange={(key, _value) => {
                        setOrderByState(key)
                    }}
                    />
                  </Box>
                </Box>
                
                
                {/* <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: 'Selling Price (High to Low)',
                      optionKey: 'descending'
                    },
                    {
                      optionText: 'Selling Price (Low to High)',
                      optionKey: 'ascending'
                    }
                  ]}
                  handleOptionClick={(_e, key, _value) => {
                    setOrderByState(key)
                  }}
                  render={(cb) => (
                    <Button
                      defaultBtn
                      endIcon={<Icon name="ChevronDown" />}
                      onClick={cb}
                      text={'Sort By'}
                    ></Button>
                  )}
                /> */}
              </FilterControl>
              
            </Grid>
            {inventoryData?.result?.length == 0 ? (
              <Grid theme={theme} item xs={12}>
                {/* <NotFound /> */}
              </Grid>
            ) : (
              inventoryData?.result?.map((vehicle: any, index: number) => (
                <Grid theme={theme} key={index} item xs={12} md={6} lg={openFilter ? 4 : 3}>
                  <VehicleCardUi theme={theme} className="vehicle-card full-width mini-carousel">
                    
                    {vehicle?.vehicle?.photo_urls?.every(
                      (photo: any) => photo.location !== '' && photo.location?.split('//')?.[1] !== ''
                    ) ? (
                      <>
                        <ResponsiveCarousel
                          responsive={responsive}
                          removeArrowOnDeviceType={'xs'}
                          swipeable={false}
                          draggable={false}
                          autoPlay={true}
                          pauseOnHover={false}
                          autoPlaySpeed={3000}
                        >
                          {vehicle?.vehicle?.photo_urls?.length > 0 &&
                            vehicle?.vehicle?.photo_urls?.map((obj: any, index: any) => (
                              <img
                                src={
                                  obj?.location === ''
                                    ? require('../images/no-img.svg')
                                    : obj?.location
                                }
                                key={index}
                                alt="vehicle"
                              />
                            ))}
                        </ResponsiveCarousel>
                        {vehicle?.vehicle?.photo_urls?.length > 0 && (
                          <Box theme={theme} className="vc-img-wrap single-img">
                            <img
                              src={vehicle?.vehicle?.photo_urls[0]?.location}
                              key={index}
                              alt="vehicle"
                            />
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box theme={theme} className="vc-img-wrap blank-img-wrap">
                        <img src={require('../images/no-img.svg')} alt="Blank" />
                      </Box>
                    )}
                    
                    <Box theme={theme} className="vc-footer w-100" textAlign={'center'}>
                      <Grid container spacing={2} theme={theme}>
                        <Grid item xs={7} theme={theme}>
                          <Box theme={theme} textAlign="left">
                            <Typography
                              theme={theme}
                              variant="caption"
                              component="span"
                              className="text-muted"
                            >
                              {vehicle?.vehicle.year} {vehicle?.vehicle.model}
                            </Typography>
                          </Box>
                          <Box theme={theme} textAlign="left">
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              className="w-100 text-dark"
                            >
                              {vehicle?.vehicle.trim_description}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={5} theme={theme}>
                          <Box theme={theme} textAlign="right">
                            <Typography
                              theme={theme}
                              variant="caption"
                              component="span"
                              className="text-muted"
                            >
                              Selling Price
                            </Typography>
                          </Box>
                          <Box theme={theme} textAlign="right">
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              className="text-dark"
                            >
                              {defaultCurrency}
                              {vehicle?.vehicle?.internet_price?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* <Box theme={theme} className="specs">
                        <Box theme={theme} className="w-100">
                          <Typography
                            theme={theme}
                            variant="caption"
                            component="span"
                            className="text-muted"
                            sx={{ mr: 0.5 }}
                          >
                            Stock:{' '}
                          </Typography>
                          <Typography
                            theme={theme}
                            variant="subtitle2"
                            component="span"
                            className="text-dark subtitle2"
                          >
                            {vehicle?.vehicle?.stock_number}
                          </Typography>
                        </Box>
                        <Box theme={theme} className="w-100">
                          <Typography
                            theme={theme}
                            variant="caption"
                            component="span"
                            className="text-muted"
                            sx={{ mr: 0.5 }}
                          >
                            VIN:{' '}
                          </Typography>
                          <Typography
                            theme={theme}
                            variant="subtitle2"
                            component="span"
                            className="text-dark subtitle2"
                          >
                            {vehicle?.vehicle?.vin}
                          </Typography>
                        </Box>
                      </Box> */}
                      <Box theme={theme} className="action">
                        <Button
                          theme={theme}
                          onClick={() => {
                            setOpenPopup(true)
                            setSelectedVehicle(vehicle)
                            //   openVdModal()
                          }}
                          secondary
                          className="btn-view"
                          iconText={<Icon name="IcView" />}
                        >
                          Button
                        </Button>
                        <Button
                          theme={theme}
                          primary
                          text={'Select & Continue'}
                          onClick={() => {
                            onListViewClick(vehicle?.vehicle?.vin)
                          }}
                        ></Button>
                      </Box>
                    </Box>
                  </VehicleCardUi>
                </Grid>
              ))
            )}
          </Grid>
        </RightPanel>
      </Grid>
    </>
  )
}

export default AssetListViewControl
