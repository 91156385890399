import { useQuery } from "react-query";
import { TenantManagement } from '../apis'
import {QueryKeys} from "@constants";

// TenantManagement.getTenant
export const useTenantConfiguration = (slug: string) => {
  return useQuery([QueryKeys.GET_TENANT_CONFIGURATION], () => {
    return TenantManagement.getTenantConfigurations({ slug })
  })
}
export const useTenantTheme = (email: string, slug: string) => {
  return useQuery([QueryKeys.GET_TENANT_THEME_BY_EMAIL, email], () => {
    return TenantManagement.getTenantTheme(email, slug )
  }, {enabled: !!slug && !!email})
}

export const useDefaultLenderConfiguration = (company_id: number, slug: string) => {

  return useQuery(
    [QueryKeys.GET_DEFAULT_LENDER],
    () => {
      return TenantManagement.getDefaultLenderConfigurations(company_id , slug)
    },{
      enabled: !!slug && !!company_id
    }
  )
}

export const useGetLenderConfigurationById = (lenderId: number) => {

  return useQuery(
    [QueryKeys.GET_LENDER, lenderId],
    () => {
      return TenantManagement.getLenderById(lenderId)
    },{
      enabled: !!lenderId
    }
  )
}
