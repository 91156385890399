import { useContext, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import {
  AppContext,
  loadDefaultLenderConfigurations,
  loadPlaceholder,
  loadTenantConfigurations,
  updateTenantTheme
} from './context-provider'
import { useDefaultLenderConfiguration, useTenantConfiguration, useTenantTheme } from '@hooks'
import {Box, CircleLoader, Snackbar} from "@ntpkunity/controls";
import {useTheme} from "@mui/material";
import {useWorkflowContext} from "@app/router-workflow";
import { useLanguages } from 'hooks/dealer-management';

export const Configurations = () => {
  const theme = useTheme();
  // using useParams to get the tenant from the url
  const { tenant } = useParams()
  const {state:{workflowLoading}} = useWorkflowContext()

  
  // using useContext to get the state and dispatch from the context
  const appState = useContext(AppContext)

  // using useTenantConfiguration get the tenant configurations
  // TODO: replace 'bmw' with the tenant from the url
  const { data: configurations, isFetched: isConfigurationsFetched } = useTenantConfiguration(tenant)
  const { data: language, isLoading:islanguageLoading } = useLanguages(tenant)

  const { data: defaultLenderConfigurations, isFetched: isDefaultLenderConfigurationsFetched } = useDefaultLenderConfiguration(appState.state.tenant, appState?.state?.slug)
  const {data: themeData} = useTenantTheme(appState.state.email, appState?.state?.slug)

  const areConfigurationsFetched = isConfigurationsFetched || isDefaultLenderConfigurationsFetched
  
  useEffect(() => {
    if (isConfigurationsFetched) {
      // dispatch the configurations to the context
      if(configurations?.data) {
        appState.dispatch(loadTenantConfigurations(configurations.data))
      }

      if (configurations?.data?.gtm_key) {

        // Paste this code as high in the <head> of the page as possible:
        const script = document.createElement('script');
        script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${configurations.data.gtm_key}');
      `;
        document.head.appendChild(script);

        // add the Google Tag Manager noscript code after the opening <body> tag
        const noscriptElement = document.createElement('noscript');
        noscriptElement.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${configurations.data.gtm_key}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
        document.body.insertBefore(noscriptElement, document.body.firstChild);
      }

    }
    if(!islanguageLoading && language){
      appState.dispatch(loadPlaceholder({placeholders:language?.placeholders}))
    }
  }, [isConfigurationsFetched,islanguageLoading])

  useEffect(() => {
    if(isDefaultLenderConfigurationsFetched && Object.keys(defaultLenderConfigurations ?? {}).length > 0 ) {
      appState.dispatch(loadDefaultLenderConfigurations(defaultLenderConfigurations))
    }
  }, [defaultLenderConfigurations])

  useEffect(() => {
    if(themeData && themeData?.data)
      appState.dispatch(updateTenantTheme(themeData.data))
  }, [themeData]);

  if (!areConfigurationsFetched || appState.state.tenant === 0) {
    return null
  } else {
    return <>
     <Outlet />
     <Snackbar
        theme={theme}

        message={
          <Box theme={theme} display={'flex'}>
            <Box theme={theme} width="30px" position="relative">
              <CircleLoader theme={theme} size='xs' />
            </Box> {'Loading ...'}
          </Box>
        }
        open={workflowLoading}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      />
    </>
  }
}
