import { useMutation } from "react-query";
import { InsuranceManagement } from "../apis";

export const useAddWayInsurance = (): any => {
  return useMutation<any>((body: any) => {
    return InsuranceManagement.addWayInsurance(body);
  });
};

export const useAddTransactionId = (): any => {
  return useMutation<any>((body: any) => {
    return InsuranceManagement.addTransactionId(body);
  });
};
