import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { CustomCalendarWrap } from './custom-calendar.style';
import { Box, Button, Icon, Typography } from '@ntpkunity/controls';
import { SelectionButton } from 'components';
import moment from 'moment';
import { Controller } from 'react-hook-form';

interface CustomCalendarProps {
  scheduleOrderForm:any
}

export const CustomCalendar: React.FC<CustomCalendarProps> = ({scheduleOrderForm }) => {
  const {control,formState: { errors }} = scheduleOrderForm

  const theme = useTheme();
  const [startDate, setStartDate] = useState(moment());
  const generateCalendarDays = (startDate: moment.Moment) => {
    const days: Array<{ title: string; subtitle: string; date: moment.Moment }> = [];

    for (let i = 0; i < 6; i++) {
      const dayDate = startDate.clone().add(i, 'day');
      days.push({
        title: dayDate.format('ddd'),
        subtitle: dayDate.format('DD'),
        date: dayDate,
      });
    }

    return days;
  };

  const handleDayChange = (direction: 'prev' | 'next') => {
    let newStartDate = startDate.clone();

    if (direction === 'prev') {
      newStartDate = newStartDate.subtract(4, 'days');
    } else if (direction === 'next') {
      newStartDate = newStartDate.add(4, 'days');
    }
    setStartDate(newStartDate);
  };

  const [calendarDays, setCalendarDays] = useState(generateCalendarDays(startDate));

  useEffect(() => {
    setCalendarDays(generateCalendarDays(startDate));
  }, [startDate]);

  return (
    <CustomCalendarWrap theme={theme} className="custom-calendar-wrap">
      <Box theme={theme} className="calendar-header">
        <Button
          theme={theme}
          className="left-btn"
          onClick={() => handleDayChange('prev')}
          defaultBtn
          iconText={<Icon name="ChevronDown" />}
          ariaLabel="Previous 6 Days"
        />
        <Typography theme={theme} variant="body1" component="p" className="fw-sbold">
          {startDate.format('MMMM YYYY')}
        </Typography>
        <Button
          theme={theme}
          className="right-btn"
          onClick={() => handleDayChange('next')}
          defaultBtn
          iconText={<Icon name="ChevronDown" />}
          ariaLabel="Next 6 Days"
        />
      </Box>

      <Box theme={theme} className="calendar-body">
        <Box theme={theme} className="calendar-items">
          <Controller
            name="selectedDate"
            control={control}
            render={({ field }) => (
              <>
              <SelectionButton
                buttonItems={calendarDays.map((day) => ({
                  title: day.title,
                  subtitle: day.subtitle,
                    onClick: () => {
                        if (!day.date.isBefore(moment(), 'day')) {
                            field.onChange(day.date);
                        }
                    },
                  isSelected: field.value && field.value.isSame(day.date, 'day'),
                  disabled: day.date.isBefore(moment(), 'day'),
                }))}
              />
              </>
            )}
          />
        </Box>
      </Box>
    </CustomCalendarWrap>
  );
};
