import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PreQualificationCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.product-order-card': {
        backgroundColor: theme.palette.common.white,
            padding: '24px',
            borderRadius: 16,
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            gap: 24,
            height: '100%',
            '.card-head': {
                position: 'relative',
                textAlign: 'center',
                '.status': {
                    textTransform: 'uppercase',
                    position: 'absolute',
                    left: '-8px',
                    top: '-7px',
                    borderRadius: 8,
                    padding: '4px 8px',
                    '&.in-progress': {
                        background: 'linear-gradient(0deg, rgba(0, 51, 253, 0.08) 0%, rgba(0, 51, 253, 0.08) 100%), #FFF',
                        color: theme.palette.primary.main
                    },
                    '&.cancelled': {
                        background: 'linear-gradient(0deg, rgba(255, 51, 51, 0.08) 0%, rgba(255, 51, 51, 0.08) 100%), #FFF',
                        color: theme.palette.error.main
                    },
                },
                '.qr-img': {
                    display: 'flex',
                    margin: 'auto',
                    marginBottom: 8,
                    width: 144,
                    height: 140,
                    img: {
                        width: 126,
                        height: 126
                    }
                },
                '.ref': {
                    backgroundColor: theme.palette.grey[600] + '12',
                    padding: '4px 8px',
                    borderRadius: 8,
                    textTransform: 'uppercase'
                },
            },
            ul: {
                textAlign: 'left',
                paddingLeft: 0,
                listStyleType: 'none',
                margin: 0,
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 4,
                li: {
                    backgroundColor: '#F5F5F7',
                    padding: '4px 8px',
                    borderRadius: 8,
                    display: 'flex',
                    gap: 4,
                }
            },
            
            '.card-content': {
                display: 'flex',
                flexDirection: 'column',
                gap: 24,
            },
            '.card-action': {
                display: 'flex',
                gap: 16,
            }
    }
}))
