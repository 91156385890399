import {lazy} from "react";

const loadComponent = (relativePath) => {
    return  lazy(() => import(`./trade-in-popup-wrapper//${relativePath}`));
};
export const popupConfigurations = {
    'AddTradeInPopup': {
        component: loadComponent('add-trade-in.wrapper'),
        hasContinueButton: false,
        hasBackButton: false,
        title: "",
        nextComponent: 'vin-popup.wrapper'
    },
    'ConditionRatingPopup': {
        component: loadComponent('condition-question.wrapper'),
        hasContinueButton: true,
        hasBackButton: true,
        title: "Let’s get your trade-in estimate",
        nextComponent: "UploadImagePopup"
    },
    'UploadImagePopup': {
        component: loadComponent('upload-image.wrapper'),
        hasContinueButton: true,
        hasBackButton: true,
        title: "Let’s get your trade-in estimate",
        nextComponent: "FinalPopup"
    },
    'FinalPopup': {
        component: loadComponent('final.wrapper'),
        hasContinueButton: false,
        hasBackButton: false,
        title: "",
        nextComponent: null

    },
    'LicensePlatePopup': {
        component: loadComponent('license-plate.wrapper'),
        hasContinueButton: true,
        hasBackButton: true,
        title: "Let’s get your trade-in estimate",
        nextComponent: null
    },
    'LoaderPopup': {
        component: loadComponent('loader-wrapper'),
        hasContinueButton: false,
        hasBackButton: false,
        title: "",
        nextComponent: null
    },
    'MakeModelPopupFour': {
        component: loadComponent('make-model-four.wrapper'),
        hasContinueButton: true,
        hasBackButton: true,
        title: "Let’s get your trade-in estimate",
        nextComponent: "MakeModelPopupFive"
    },
    'MakeModelPopupOne': {
        component: loadComponent('make-model-one.wrapper'),
        hasContinueButton: true,
        hasBackButton: true,
        title: "Let’s get your trade-in estimate",
        nextComponent: "MakeModelPopupTwo"
    },
    'MakeModelPopupThree': {
        component: loadComponent('make-model-three.wrapper'),
        hasContinueButton: true,
        hasBackButton: true,
        title: "Let’s get your trade-in estimate",
        nextComponent: "MakeModelPopupFour"
    },
    'MakeModelPopupTwo': {
        component: loadComponent('make-model-two.wrapper'),
        hasContinueButton: true,
        hasBackButton: true,
        title: "Let’s get your trade-in estimate",
        nextComponent: "MakeModelPopupThree"
    },
    'MakeModelPopupFive': {
        component: loadComponent('make-model-five.wrapper'),
        hasContinueButton: true,
        hasBackButton: true,
        title: "Let’s get your trade-in estimate",
        nextComponent: "MilesPopup"
    },
    'MilesPopup': {
        component: loadComponent('miles.wrapper'),
        hasContinueButton: true,
        hasBackButton: true,
        title: "Let’s get your trade-in estimate",
        nextComponent: "ConditionRatingPopup"
    },
    'ProvideManualEstimatePopup': {
        component: loadComponent('provide-manual-estimate.wrapper'),
        hasContinueButton: true,
        hasBackButton: true,
        title: "Let’s get your trade-in estimate",
        nextComponent: "ConditionRatingPopup"
    },
    'TrimLevelPopup': {
        component: loadComponent('trim-level.wrapper'),
        hasContinueButton: false,
        hasBackButton: true,
        title: "",
        nextComponent: null
    },
    'VinOnePopup': {
        component: loadComponent('vin-popup.wrapper'),
        hasContinueButton: true,
        hasBackButton: true,
        title: "Let’s get your trade-in estimate",
        nextComponent: "MakeModelPopupFour"
    }
};


