import React, {useEffect} from 'react';
import {useCustomDirtyForm} from "../../../../hooks/order-management";
import {PersonalFinanceDetailsComponent} from "../../../../libraries/financing-control/Personal-finance-details";
import {useFormContext} from "../../../(order-management)/order/form-context-provider";
import {getDefaultPersonalFinanceValues} from "../../../../utilities/form/personal-finance-details"; // Adjust the path as per your project structure

const PersonalFinanceDetailsWrapper = ({
  customerReferenceId,
  personalFinanceData,
  personalFinanceFrom,
  watchPersonalFinanceFields, setCustomerDataLead
}) => {

  const {formStates} = useFormContext()
  useCustomDirtyForm('personalFinance', watchPersonalFinanceFields, personalFinanceFrom?.formState?.isValid);
  useEffect(() => {
    if(personalFinanceData){
      personalFinanceFrom?.reset(formStates?.['personalFinance']?.data || getDefaultPersonalFinanceValues(personalFinanceData))
    }
  }, [personalFinanceData]);
  return (
    <PersonalFinanceDetailsComponent
      customerReferenceId={customerReferenceId}
      personalFinanceData={personalFinanceData}
      form={personalFinanceFrom}
      setCustomerDataLead={setCustomerDataLead}
    />
  );
};

export default PersonalFinanceDetailsWrapper;
