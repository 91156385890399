import React, { useEffect, useReducer, useState, useContext } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material'
import { Box, Button, CircleLoader, Icon, Snackbar } from '@ntpkunity/controls'
import { OtpComponent } from '@ntpkunity/controls-ums'
import { Navigation, QueryKeys } from '@constants'
import { AuthLayoutWrap } from "../layout.style"
import { v4 as uuidv4 } from 'uuid'
import { StatusCodes } from 'http-status-codes'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Steps, TaggingId } from 'constants/enums'
import { useAppContext, changeUserLoggedInState } from '@app/context-provider'
import { Tags } from 'constants/enums';
import { SnackbarUtility } from '@utilities'
import { useWorkflowContext } from '@app/router-workflow'
import { useIsMutating, useQueryClient } from 'react-query'
import { user_session } from '@constants'
import { useOrderInfo } from 'hooks/dealer-management'
import { IVehicle } from '@interfaces'
import { useGetDealerProfileByDealerCode, useGetVehicleByVin } from '@hooks'

export const OtpPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { tenant } = useParams()
  const vin = localStorage.getItem('reference_dealer');
  const running_id = localStorage.getItem('running_id');
  const { mutate: createTags } = useTags();
  const location = useLocation()
  const email = location?.state?.email
  const signUpSteps = location?.state?.signupSteps

  if (!email) {
    navigate(`${Navigation.AUTHENTICATION.SIGN_IN_PAGE.replace(':tenant', tenant)}`)
  }

  const { navigateNext, getOrderWorkflow } = useWorkflowContext()
  const { state: appState, dispatch: app_dispatch } = useAppContext()
  const isWokflowMutating = useIsMutating()
  const { data: order, isLoading:order_load } = useOrderInfo(tenant,vin)
  const [vehicleData,setVehicleData] = useState(false)
  const [dealerData,setDealerData] = useState(false)
  const { data: vehicle } = useGetVehicleByVin(vin?.includes('-') ? order?.Vehicle_details?.vin : vin, appState?.slug);
  const { data: dealerProfile } = useGetDealerProfileByDealerCode(vehicle?.dealer_code, appState?.slug);

  const isUserSignUpCompleted = [
    Steps.TERMS.toLowerCase(),
    Steps.TENANT.toLowerCase()
  ].includes(
    (signUpSteps ?? '')?.toLowerCase()
  )

  const PLACEHOLDERS = appState.language.placeholders;

  const translations = {
    title: isUserSignUpCompleted ? PLACEHOLDERS.LOGIN_OTP_TITLE : PLACEHOLDERS.SIGN_IN_OTP_TITLE,
    otpLoginText: PLACEHOLDERS.LOGIN_WITH_OTP_INSTEAD,
    passwordLoginText: PLACEHOLDERS.LOGIN_WITH_PASSWORD_INSTEAD
  }

  const otpLayoutText = {
    primaryDescription: PLACEHOLDERS.OTP_SENT_MESSAGE,
    secondaryDescription: PLACEHOLDERS.OTP_PROMPT,
    durationText: PLACEHOLDERS.OTP_VALIDITY_DURATION,
    helpText: PLACEHOLDERS.OTP_VERIFICATION_EMAIL,
    linkText: PLACEHOLDERS.OTP_RESEND_TEXT
  }

  const passwordLayout = {
    passwordText: PLACEHOLDERS.PASSWORD_LOGIN_PROMPT,
    passwordFieldLabel: PLACEHOLDERS.SIGN_IN_PASSWORD_FIELD_LABEL,
    forgetLinkLabel: PLACEHOLDERS.SIGN_IN_FORGET_LINK_LABEL,
    buttonText: PLACEHOLDERS.SIGN_IN_BUTTON_TEXT,
    isRequiredText: PLACEHOLDERS.IS_REQUIRED_TEXT,
    passwordHelperText: PLACEHOLDERS.PASSWORD_HELP_TEXT
  }

  const [response, setResponse] = useState<any>()
  const [sendingOtp, setSendingOtp] = useState<boolean>(false)
  const [verifyingOtp, setVerifyingOtp] = useState<boolean>(false)
  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)

  const handleSnackbar = (message, duration, timerId) => {
    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(message));
    timerId = setTimeout(() => {
      snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR());
    }, duration)
  }

  useEffect(()=>{
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.OTP}
    createTags(event)
  },[])
  useEffect(() => {
    if (!response) {
      return
    }

    let timerId
    const { status, data, message } = response
    if (status === StatusCodes.OK) {
      if (typeof data === 'string') {
        handleSnackbar(PLACEHOLDERS.OTP_SENT_SUCCESSFULLY ?? data, 5000, timerId)
      } else if ([Steps.REGISTERED, Steps.VERIFIED].includes(signUpSteps?.toLowerCase())) {
        const {
          signup_steps: signUpSteps,
          is_tenant: isTenant,
          otp: _otp
        } = data ?? {}

        navigate(`${Navigation.AUTHENTICATION.USER_PROFILE.replace(':tenant', tenant)}`, {
          replace: true,
          state: {
            email,
            isTenant,
            otp: _otp
          }
        })
      } else if ([Steps.TENANT, Steps.TERMS].includes(signUpSteps?.toLowerCase())) {
        const {
          access_token: accessToken,
          refresh_token: refreshToken,
          profile,
        } = data ?? {}

        localStorage.setItem("settings", JSON.stringify({ email :response?.data?.user_name , 'access_token':accessToken}));
        localStorage.setItem('access_token', accessToken)
        localStorage.setItem('refresh_token', refreshToken)
        localStorage.setItem('user_profile', JSON.stringify(profile))
        localStorage.setItem('signup_steps', `"${signUpSteps.toLowerCase()}"`);

        app_dispatch(changeUserLoggedInState(true))
        // navigate to next page
        // TODO: navigate to next page
          if(vin?.includes('-')){
            getOrderWorkflow(order?.Vehicle_details?.vin, vin)
          } else if(vin && !vin?.includes('-')){
            getOrderWorkflow(vin)
          } else {
            navigateNext();
          }
      }
    } else if (status === StatusCodes.NOT_FOUND) {
      handleSnackbar(message.includes('password') ? PLACEHOLDERS.OTP_INVALID_PASSWORD_MESSAGE : PLACEHOLDERS.OTP_INVALID_OTP_MESSAGE, 5000, timerId)
    } else if (status === StatusCodes.LOCKED) {
      handleSnackbar(PLACEHOLDERS.OTP_ACCOUNT_BLOCKED_MESSAGE, 5000, timerId)
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [response])

  useEffect(() => {
    if (Object.keys(vehicle??{}).length > 0 && Object.keys(dealerProfile??{}).length>0) {
      localStorage.setItem("dealer_information", JSON.stringify({
        dealer: {
          dealer_address_1: dealerProfile.dealer_address.address_line_1,
          dealer_address_2: dealerProfile.dealer_address.address_line_2,
          dealer_city: dealerProfile.dealer_address.city,
          dealer_county: dealerProfile.dealer_address.county,
          dealer_state_name: dealerProfile.dealer_address.state_name,
          dealer_id: vehicle.dealer_id,
          dealer_zip_code: dealerProfile.dealer_address.zip_code,
          dealer_code: vehicle.dealer_code,
          dealer_active: dealerProfile.is_active
        }
      }));
    }

  }, [ vehicle, dealerProfile]);

  useEffect(() => {
    let timerId

    if (sendingOtp) {
      handleSnackbar(PLACEHOLDERS.OTP_SENDING_MESSAGE, 5000, timerId)
    }
    if (verifyingOtp) {
      handleSnackbar(PLACEHOLDERS.OTP_VERIFYING_MESSAGE, 1300, timerId)
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [sendingOtp, verifyingOtp])

  return (
    <AuthLayoutWrap theme={theme} className='auth-layout-wrap'>
      {order_load ? (
        <CircleLoader theme={theme} size='xl' />
      ) : (
      <>
        <Box theme={theme} mb={4} className="back-btn">
          <Button
            defaultBtn
            theme={theme}
            startIcon={<Icon name="IconLeftArrow" />}
            text={PLACEHOLDERS.OTP_GO_BACK_PROMPT}
            onClick={() => { navigate(`${Navigation.AUTHENTICATION.SIGN_IN_PAGE.replace(':tenant', tenant)}`) }
            }
          />
        </Box>
        <OtpComponent
          id={TaggingId.LOG_IN}
          theme={theme}
          passwordFirst={false}
          email={email}
          setResponse={setResponse}
          isLoading={isWokflowMutating > 0}
          layoutText={translations}
          otpProps={{
            signUpSteps,
            setSendingOtp,
            setVerifyingOtp,
            layoutText: otpLayoutText
          }}
          passwordProps={{
            handleForgotPassword: () => navigateNext(Navigation.AUTHENTICATION.FORGOT_PASSWORD, { email }),
            layoutText: passwordLayout
          }}
        />
      </>
      )}
      <Snackbar
        theme={theme}
        message={snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      />
    </AuthLayoutWrap>
  )
}