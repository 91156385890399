import { useTheme } from "@mui/material";
import ChatHeader from "./chat-header/chat-header.component";
import ChatMessages from "./chat-messages/chat-messages.component";
import ChatMessagesWindowWrap from "./chat-messages-window.style";
import ChatBox from "./chat-box/chat-box-component";
import { useEffect } from "react";
import { useChatServiceContext } from "../../../hooks";

const ChatMessagesWindow = () => {
  const theme = useTheme();
  const { readMessages, messages } = useChatServiceContext();

  useEffect(() => {
    if (messages) readMessages();
  }, [messages.length]);

  return (
    <ChatMessagesWindowWrap theme={theme} className="chats-messages-wrap">
      <ChatHeader />
      <ChatMessages />
      <ChatBox />
    </ChatMessagesWindowWrap>
  );
};

export default ChatMessagesWindow;
