import { Endpoint } from "@constants";
import { http } from "../utilities";
import { IEmploymentDetails } from "@interfaces";
import {tableBodyClasses} from "@mui/material";

export const DMS_BASE_URL =  process.env.DMS_BASE_URL;
export const HUBEX_BASE_URL = process.env.HUBEX_BASE_URL;
export const CONFIG_API_GATEWAY = process.env.CONFIG_API_GATEWAY
export const UMS_BASE_URL = process.env.UMS_BASE_URL;

export namespace OrderManagement {
  export const getVehicle = async ({ vin, slug }: { vin: string, slug: string }) => {
    const body = {
      url: `${Endpoint.DMS_VEHICLE_BY_VIN}/${vin}`,
      method_type: 'get'
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };
  export const getVehicleDetail = async ({ vin, slug }: { vin: string, slug: string }) => {
    const body = {
      url:`${Endpoint.CONFIG_VEHICLE_DETAIL_BY_VIN}/${vin}`,
      method_type:'get'
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const getSetupData = async (queryParams, slug) => {
    const body = {
      url: `${Endpoint.GET_DMS_SETUP_DATA}?${queryParams}`,
      method_type: 'get',
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const getCustomerData = async (queryParams) => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.CUSTOMER_CHECK}?${queryParams}`)
      .then((res) => {
        return res.data;
      });
  };

  export const getTrimCode = async ({
    dealer_code,
    model_name,
    slug
  }: {
    dealer_code: string;
    model_name: string;
    slug: string
  }) => {
    const body = {
      url: Endpoint.DMS_OPTION_TRIM_CODE,
      method_type: 'get',
      payload:{
        dealer_code: dealer_code,
        model_name: model_name
      }
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };
  export const getIntegrationByProviderName = ({
    provider_name,
    integration_type,
    dealer_code,
    company_id,
    slug
  }: {
    provider_name: string;
    integration_type: string;
    dealer_code: string;
    company_id:number
    slug: string;
  }) => {
    const body = {
      url:`${Endpoint.DMS_INTEGRATION_BY_PROVIDER_NAME}/${dealer_code}/${integration_type}/${provider_name}`,
      method_type: 'get',
      company_id:company_id

    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };
  export const getIntegrationConfiguration = (data:any) => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.INTEGRATION_CONFIGURATION_BY_PROVIDER_NAME}/${data.dealer_code}/${data.integration_type}/${data.provider_name}`,{
        headers: {
          "company_id":data.company_id
        }
      })
      .then((res) => {
        return res.data;
      });
  };
  export const getFinancialInsuranceProduct = (columnFilters: string, slug: string) => {
    const body = {
      url:`${Endpoint.DMS_FNI_PRODUCT}?`.concat(columnFilters),
      method_type: 'get'
    }

    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };
  export const verifyAddress = (request): any => {
    return http(HUBEX_BASE_URL)
      .get(`${Endpoint.VERIFY_ADDRESS}/${request.body.address}`)
      .then((res) => {
        return res.data;
      });
  };
  export const getFnIProductRate = (slug:string, payload: any): any => {
    const body = {
      url: `${Endpoint.GET_RATES}`,
      method_type: 'post',
      payload:{
        ...payload.data
      }
    }
     return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };
  export const getDealerProfileByDealerCode = (
    dealer_code,
    slug
  ): any => {
    const body = {
      url: `${Endpoint.DMS_DEALER_PROFILE}/${dealer_code}`,
      method_type: 'get'
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };
  export const getCountries = (slug: string): any => {
    const body = {
      url:Endpoint.EP_GET_DMS_COUNTRIES,
      method_type: 'get'
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const getOrder = ({ orderId }: { orderId: string }): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.EP_GET_ORDER_INFORMATION}/${orderId}`)
      .then((res) => {
        return res.data;
      });
  };

  export const getStates = (slug) => {
    const body = {
      url: Endpoint.EP_DMS_GET_STATES,
      method_type: 'get'
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const getVehicleByTrimCode = ({
    trimCode,
    slug
  }: {
    trimCode: string;
    slug: string;
  }): any => {
    const body = {
      url: `${Endpoint.DMS_VEHICLE_BY_TRIM_CODE}/${trimCode}`,
      method_type: 'get'
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const getPrograms = (data: any, slug: string) => {
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, {
        url:Endpoint.GET_DMS_ALL_PROGRAMS,
        payload: data,
        method_type: 'post'
      })
      .then((res) => {
        return res.data;
      });
  };
  export const usePreQualification = (data): any => {
    return http(DMS_BASE_URL)
      .post(Endpoint.PRE_QUALIFICATION, data)
      .then((res) => {
        return res.data;
      });
  };
  export const calculateOrderQuotation = (data, slug): any => {
        const body = {
      url:Endpoint.DMS_CALCULATE_ORDER_QUOTATION,
      payload: data,
      method_type: 'post'
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const customerByEmail = (data): any => {
    return http(DMS_BASE_URL)
      .patch(Endpoint.CUSTOMER_EMAIL_CHECK, data)
      .then((res) => {
        return res.data;
      });
  };
  export const customerContractByReferenceID = ({
    reference_id,
  }: {
    reference_id: string;
  }): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.EP_GET_CUSTOMER}?reference_id=${reference_id}`)
      .then((res) => {
        return res.data;
      });
  };

  export const updateCustomerContractByReferenceID = (data): any => {
    const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

    const { reference_id, ...restPayload } = data;
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.EP_UPDATE_CONTACT_DETAILS}?reference_id=${reference_id}&dealer_code=${dealer_code}`,
        restPayload
      )
      .then((res) => {
        return res.data;
      });
  };
  export const getCompanyIntegrations = (data): any => {
    return http(DMS_BASE_URL)
      .get(Endpoint.EP_GET_COMPANY_INTEGRATIONS, {
        headers: data,
      })
      .then((res) => {
        return res.data;
      });
  };
  export const getCurrency = async ({ slug }: { slug: string }) => {
     const body = {
      url: Endpoint.EP_GET_DMS_CURRENCY,
      method_type: "get",
    };

    return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
      return res.data
    });
  };

  export const saveOrderRequest = (data): any => {
    const running_id = localStorage.getItem('running_id') || '0';
    return http(DMS_BASE_URL)
      .post(Endpoint.SAVE_ORDER_QUOTATION, [{ ...data[0], running_id: parseInt(running_id) }])
      .then((res) => {
        return res.data;
      });
  };

  export const convertQuotationToApplication = (data): any => {
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.CONVERT_QUOTATION_TO_APPLICATION}/${data.identifier}`,
        data
      )
      .then((res) => {
        return res.data;
      });
  };

  export const getPersonalFinanceInfo = ({
    reference_id,
  }: {
    reference_id: string;
  }): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.EP_GET_PERSONAL_FINANCE}?reference_id=${reference_id}`)
      .then((res) => {
        return res.data;
      });
  };

  export const saveEmploymentDetails = (data: IEmploymentDetails) => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

    return http(DMS_BASE_URL)
      .post(`${Endpoint.EP_SAVE_EMPLOYMENT_DETAILS}?dealer_code=${dealer_code}`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const calculateNfa = (data, slug): any => {
    const body = {
      url: Endpoint.DMS_CALCULATE_NFA,
      method_type: 'post',
      payload: data
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const updateEmploymentInfoByReferenceID = (
    data: IEmploymentDetails
  ): any => {
    const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

    const { reference_id, ...restPayload } = data;
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.EP_UPDATE_EMPLOYMENT_DETAILS}?reference_id=${reference_id}&dealer_code=${dealer_code}`,
        restPayload
      )
      .then((res) => {
        return res.data;
      });
  };

  export const EmploymentInfoByReferenceID = ({
    reference_id,
  }: {
    reference_id: string;
  }): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.EP_GET_EMPLOYMENT_INFO}?reference_id=${reference_id}`)
      .then((res) => {
        return res.data;
      });
  };

  export const updateOrderRequest = (data): any => {
    return http(DMS_BASE_URL)
      .patch(`${Endpoint.CONFIGURE_QUOTATION}/${data.identifier}`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const createEmploymentInfoByReferenceID = (data): any => {
    const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

    return http(DMS_BASE_URL)
      .post(
        `${Endpoint.EP_PERSONAL_FINANCE}?dealer_code=${dealer_code}`,
        data
      )
      .then((res) => {
        return res.data;
      });
  };

  export const updatePersonalFinanceByReferenceID = (data): any => {
    const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

    const { reference_id, ...restPayload } = data;
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.EP_UPDATE_PERSONAL_FINANCE_DETAILS}?reference_id=${reference_id}&dealer_code=${dealer_code}`,
        restPayload
      )
      .then((res) => {
        return res.data;
      });
  };
  export const createCustomerByReferenceID = (data): any => {
    const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
    const { reference_id, ...restPayload } = data;
    return http(DMS_BASE_URL)
      .post(
        `${Endpoint.EP_CREATE_CUSTOMER_DETAILS}?reference_id=${reference_id}&dealer_code=${dealer_code}`,
        restPayload
      )
      .then((res) => {
        return res.data;
      });
  };

  export const updateOrderStatus = (data): any => {
    const { reference_number, ...restPayload } = data;
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.UPDATE_ORDER_STATUS}/${reference_number}`,
        restPayload
      )
      .then((res) => {
        return res.data;
      });
  };

  export const submitOrder = (data): any => {
    return http(DMS_BASE_URL)
      .post(
        `${Endpoint.EP_SUBMIT_ORDER}?reference_number=${data.orderId}`
      )
      .then((res) => {
        return res.data;
      });
  };
  export const reSubmitOrder = (data): any => {
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.EP_RE_SUBMIT_ORDER}${data.identifier}`,data
      )
      .then((res) => {
        return res.data;
      });
  };

  export const addAppointment = (data): any => {
    return http(DMS_BASE_URL)
      .post(
        `${Endpoint.EP_APPOINTMENT}`,
        data
      )
      .then((res) => {
        return res.data;
      });
  }

  export const updateAppointment = (data): any => {
    return http(DMS_BASE_URL)
      .patch(`${Endpoint.EP_APPOINTMENT}`, data)
      .then((res) => {
        return res.data;
      });
  }
  export const deleteAppointment = (data): any => {
    return http(DMS_BASE_URL)
      .delete(`${Endpoint.EP_APPOINTMENT}/${data.id}`, data)
      .then((res) => {
        return res.data;
      });
  }

  export const getLenderConfigurationByRadius = (data): any => {
    const { company_id, slug } = data
    const body = {
      url: Endpoint.EP_GET_DMS_CONFIGURATION_RADIUS,
      method_type: 'get',
      company_id: company_id.toString()
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const getDealerDistance = (data): any => {
    const { companyId, radius, zip_code, slug } = data;

    const body = {
    method_type:"get",
    url:Endpoint.GET_DMS_DEALER_DISTANCE_BY_CUSTOMER,
    payload:{
        "company_id":companyId,
        "radius":radius,
        "zip_code":zip_code
        }
}
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const getVehicleDetailByVin = ({
    vin, slug
  }: {
    vin: string;
    slug: string
  }): any => {
    const body = {
      url: `${Endpoint.EP_DMS_UPDATE_VEHICLE_DETAIL}/${vin}`,
      method_type: 'get'
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const getcustomerOrdersByReferenceID = (query_params: string): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.GET_WORKQUEUE}?page_number=0&page_size=100`.concat(query_params))
      .then((res) => {
        return res.data;
      });
  };


  export const getCreditResponse = ({reference_number}: { reference_number: any }): any => {
    return http(DMS_BASE_URL)
    .get(`${Endpoint.EP_CREDIT_RESPONSE}?reference_number=${reference_number}`)
    .then((res) => {
        return res.data
    });
  }

  export const createDocumnet = (data :any) => {
    return http(DMS_BASE_URL)
      .post(Endpoint.EP_UPLOAD_DOCUMENT, data)
      .then((res) => {
        return res.data;
      });
  };

  export const createOrderDocumnet = (data :any) => {
    return http(DMS_BASE_URL)
      .post(Endpoint.ORDER_DOCUMENT, data)
      .then((res) => {
        return res.data;
      });
  };
  export const createContractDocumnet = (data :any) => {
    return http(DMS_BASE_URL)
      .post(`${Endpoint.CONTRACT_DOCUMENT}?reference_number=${data?.reference_number}&document_event=${data?.document_event.toString()}`, {})
      .then((res) => {
        return res.data;
      });
  };
  export const useGetPreSignedUrl = (data:any): any => {
    return http(DMS_BASE_URL).get<any>(`configure/document-pre-signed-url/${data.key}`, {}).then((res) => {
      return res.data;
    });
  
  }

  export const createOrderFniDocumnet = (data :any) => {
    return http(DMS_BASE_URL)
      .post(Endpoint.ORDER_DOCUMENT_FNI, data)
      .then((res) => {
        return res.data;
      });
  };

  export const uploadDocument = (data :any ): any => {
    const { customer_reference_id, ...restPayload } = data[0];
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.EP_UPLOAD_DOCUMENT_by_CUSTOMER_REFERENCE}/${customer_reference_id}`,
        data
      )
      .then((res) => {
        return res.data;
      });
  };

  export const nonFinancialStips = ({external_reference_id}: { external_reference_id: string }): any => {
    return http(DMS_BASE_URL)
    .get(`${Endpoint.EP_UPLOAD_DOCUMENT}?external_reference_id=${external_reference_id}`)
    .then((res) => {
        return res.data
    });
  }

  export const getStipulationsByLenderId = ({company_id}: { company_id: string }): any => {
    return http(DMS_BASE_URL)
    .get(`${Endpoint.EP_STIPULATION}?company_id=${company_id}`)
    .then((res) => {
        return res.data
    });
  }
  export const getTradeInHistoryByOrder = ({order_id}: { order_id: string }): any => {
    return http(DMS_BASE_URL)
    .get(`${Endpoint.GET_TRADE_IN_HISTORY}/${order_id}`)
    .then((res) => {
        return res.data
    });
  }
  export const updateFinancialIndicator = (id: string, status: boolean): any => {
    return http(DMS_BASE_URL)
        .patch(
            `${Endpoint.UPDATE_FINANCIAL_INDICATOR}/${id}/${status}`
        )
        .then((res) => {
          return res.data;
        });
  };

}
