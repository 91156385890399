import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const JourneyLayoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.journey-layout-wrap': {
    minHeight: '100vh',
    backgroundColor: '#F5F5F7',
    paddingTop: 0,
    marginLeft: -8,
    marginRight: -8,
    marginBottom: -8,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
      paddingTop: 24,
      paddingBottom: 8
    },
    '.sticky': {
      position: 'sticky',
      top: 0,
      zIndex: 11
    },
    '.journey-content-outer': {
      padding: '16px 80px 16px 80px',
      [theme.breakpoints.down('lg')]: {
        padding: '16px 40px 16px 40px',
      },
      [theme.breakpoints.down('md')]: {
        padding: 0,
        marginTop: 40
      },
      '.content-inner': {
        padding: '40px 24px 0 24px',
        '.no-data-preview-wrap': {
          '.content': {
            width: '400px',
            [theme.breakpoints.down('md')]:{
              width: '100%'
            }
          }
        },
        [theme.breakpoints.down('md')]: {
          padding: 0
        }
      }
    },
    '.steps-area': {
      '.all-steps-container': {
        [theme.breakpoints.down('md')]: {
          height: 0,
          overflow: 'hidden',
          transition: 'all 0.3s ease-in-out 0s'
        },
        '&.program-error': {
          '.step-item': {
            opacity: '50%',
            pointerEvents: 'none'
          },
          '.product-detail': {
            opacity: '50%',
            pointerEvents: 'none'
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        margin: '-24px -24px',
        padding: '24px 24px 0px 24px',
        transition: 'all 0.3s ease-in-out 0s',
        backgroundColor: '#F5F5F7',
        '.step-count-wrap': {
          marginBottom: 0
        }
      },
      '&.steps-collapsed': {
        [theme.breakpoints.down('md')]: {
          padding: '24px 0',
          backgroundColor: theme.palette.common.white,
          '.step-count-wrap': {
            margin: '0 24px'
          },
          '.all-steps-container': {
            height: 'calc(100vh - 104px)',
            overflow: 'auto',
            padding: '0px 24px 0px 24px'
          }
        }
      }
    },
    '.full-content-loader': {
      minHeight: '45vh',
      position: 'relative'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 8px 0px 8px'
    },
    '.MuiSnackbar-root .MuiSnackbarContent-message .loader-wrap .loader': {
      position: 'initial',
      transform: 'initial',
      margin: 'initial',
      '.lds-ring .ring-item': {
        borderColor: '#fff transparent transparent transparent'
      }
    },
    '&.hide-disclaimer': {
      '.disclaimer': {
        display: 'none'
      }
    }
  }
}))
