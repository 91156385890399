import { styled } from '@mui/material/styles'
import { lineHeight } from '@mui/system'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const AppointmentsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.appointment-wrap': {
    '.appointment-history': {
      '.MuiAccordion-root': {
        backgroundColor: 'transparent',
        '.u-accordion-header': {
          padding: 0,
          margin: '24px 0',
          minHeight: 'auto',
          '.MuiAccordionSummary-content': {
            margin: 0,
            '.MuiTypography-root': {
              fontSize: theme.typography.subtitle1.fontSize,
              lineHeight: theme.typography.subtitle1.lineHeight
            }
          }
        },
        '.MuiAccordionDetails-root': {
          padding: 0,
          '&.u-accordian-body': {
            border: 'none'
          }
        }
      }
    }
  }
}))
