import React, { useEffect } from "react";
import { ContactDetailsComponent } from "../../../../libraries/financing-control/ContactDetails";
import { useCustomDirtyForm } from "../../../../hooks/order-management";
import { useFormContext } from "../../../(order-management)/order/form-context-provider";
import {useAppContext} from "@app/context-provider";
const getContactData = ({contactData, isSsnFilled}: { contactData: any, isSsnFilled: boolean }) => {
  return {
    prefix: contactData?.prefix,
    first_name: contactData?.first_name,
    middle_name: contactData?.middle_name,
    last_name: contactData?.last_name,
    ssn:isSsnFilled ? contactData?.ssn : '',
    suffix: contactData?.suffix,
    home_number: contactData?.home_number,
    mobile_number: contactData?.mobile_number,
    email: contactData?.email,
  };
};
const ContactDetailsWrapper = ({
  customerReferenceId,
  contactData,
  setTab,
  contactDetailForm,
  watchContactFields,
}) => {
  const { formStates } = useFormContext();
  const { state: appState} = useAppContext()


  useCustomDirtyForm(
    "ContactDetails",
    watchContactFields,
    contactDetailForm?.formState?.isValid
  );

  useEffect(() => {
    if (contactData) {
      const { ssn, ...otherData } = getContactData({
        contactData,
        isSsnFilled: appState.isSSNFilled,
      });
      contactDetailForm?.reset({
        ...formStates?.["ContactDetails"]?.data,
        ...otherData,
        ssn: appState.isSSNFilled ? ssn : "",
      });
    }
  }, [contactData, appState.isSSNFilled]);

  return (
    <ContactDetailsComponent
      customerReferenceId={customerReferenceId}
      contactData={contactData}
      setTab={setTab}
      form={contactDetailForm}
    />
  );
};

export default ContactDetailsWrapper;
