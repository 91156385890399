import { useTheme } from '@mui/material'
import { Stack } from '../../components'
import { Box, Button, Checkbox, Icon, Radio, Switch, Typography } from '@ntpkunity/controls'
import React from 'react'
import clsx from 'clsx'
import { SwitchStackWrap } from './switch-stack.style'
import { SwitchStackProps } from './switch-stack.props'

export const SwitchStack = ({ title, subTitle, paddingMd = 4, paddingXs = 3, marginTopXs, marginTopMd, dFlex, dColumn, onSwitchClick }: SwitchStackProps) => {
    const theme = useTheme()
    return (
        <SwitchStackWrap className='switch-stack-wrap' theme={theme} p={{ xs: paddingXs, md: paddingMd }} mt={{ xs: marginTopXs, md: marginTopMd }}>
            <Box theme={theme} gap={'24px'} className={clsx({ 'stack-row': true, 'stack-column': subTitle, 'd-flex': dFlex, 'd-column': dColumn })}>
                <Box theme={theme}>
                    {title &&
                        <Typography theme={theme} className='title fw-bold w-100' component={'p'} variant='subtitle1'>{title}</Typography>
                    }
                    {subTitle &&
                        <Typography theme={theme} className='sub-title w-100 text-muted' component={'p'} variant='body2'>{subTitle}</Typography>
                    }
                </Box>
                {onSwitchClick &&
                    <Switch theme={theme} varient='basic' onChange={onSwitchClick} aria-labelledby='switch' />
                }
            </Box>
        </SwitchStackWrap>
    )
}
