import { ReactNode, useEffect, useState } from "react";
import { Footer, Header } from "../../libraries";
import { useTheme } from "@mui/material";
import { BaseLayoutWrap } from "./base-layout.style";
import { Box } from "@ntpkunity/controls";
import { ChatButton } from "components/chat";
import { ChatVinProvider } from "@hooks";

export const BaseLayout = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    setIsAtBottom(scrollTop + windowHeight >= documentHeight - 100);
  };

  const updateFooterHeight = () => {
    const footerElement = document.querySelector(".footer-wrap") as HTMLElement; // Assert as HTMLElement
    if (footerElement) {
      const height = footerElement.offsetHeight;
      setFooterHeight(height);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateFooterHeight);
    updateFooterHeight(); // Initial check for footer height

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateFooterHeight);
    };
  }, []);

  return (
    <BaseLayoutWrap theme={theme} className="base-layout-wrap">
      <ChatVinProvider>
        <Header theme={theme} />
        <main className="main-content">{children}</main>
        <Box
          theme={theme}
          sx={{
            position: "fixed",
            bottom: isAtBottom ? `${footerHeight + 70}px` : "16px",
            right: "16px",
            transition: "bottom 0.5s",
            zIndex: 12,
            "@media (max-width: 960px)": {
              bottom: isAtBottom ? `${footerHeight + 110}px` : "100px", // More space on mobile
            },
          }}
        >
          <ChatButton />
        </Box>
        <Footer />
      </ChatVinProvider>
    </BaseLayoutWrap>
  );
};
