import { styled } from "@mui/material";
import { Box, IBoxProps } from "@ntpkunity/controls";

const ChatMessagesWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.chat-messages-wrap": {
    height: "calc(100vh - 475px)",
    overflow: "auto",
    padding: "16px 24px",
    [theme.breakpoints.down('sm')]: {
      height: "calc(100vh - 560px)",
    },
    ".read-more-btn": {
      padding: "4px 8px",
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[900],
      position: "sticky",
      zIndex: 2,
      top: 0,
    },
    "&::-webkit-scrollbar": {
      width: 9,
      height: 9,
    },
    "&::-webkit-scrollbar-track": {
      padding: "0 0px",
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      transition: "all 0.3s",
      backgroundColor: "rgba(0, 0, 0, 0.16)",
      borderRadius: 4,
      border: "3px solid transparent",
      backgroundClip: "content-box",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.16)",
    },
    ".divider": {
      marginBottom: 8,
      span: {
        fontWeight: theme.typography.fontWeightMedium,
        "&:before, &:after": {
          backgroundColor: theme.palette.grey[50],
        },
      },
    },
  },
}));

export default ChatMessagesWrap;
