import React, { FC, useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { Skeleton, Box, Typography, Icon, DuoTab } from '@ntpkunity/controls';
import { RescheduleAppointmentWrap } from './delivery-step-one.style';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useAppContext } from '@app/context-provider';
import { ScheduleOptions } from '@constants';
import { ChooseDateTime } from '@components';
import { DeliveryStepTwo } from './delivery-step-two.component';
import moment from 'moment';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

export const RescheduleAppointmentDialog: FC<{
  tab: string;
  scheduleOrderForm?: any;
  isGoogleEnabled: boolean;
  isDateTimeView: any;
  setIsDateTimeView: any;
  setSelectedTimeZone: any;
  selectedTimeZone: any;
  setSubmitText:any
}> = (props) => {
  const { tab, scheduleOrderForm, isGoogleEnabled, isDateTimeView, setIsDateTimeView, setSelectedTimeZone, selectedTimeZone,setSubmitText } = props;
  const { state: appState } = useAppContext();
  const PLACEHOLDERS = appState.language.placeholders;
  setSubmitText(PLACEHOLDERS.BUTTON_TEXT_CONTINUE)
  const theme = useTheme();
  const { control, setValue, clearErrors, formState: { errors } } = scheduleOrderForm;
  const [dateTime, setDateTime] = useState<Dayjs | null>(null);
  const [isVerified, setIsVerified] = useState(false);

  const combineDateTime = (selectedDate: moment.Moment, selectedTime: string, timeZone: string) => {
    if (!selectedDate || !selectedTime || typeof selectedTime !== 'string') return null;

    const datePart = selectedDate.format('YYYY-MM-DD');
    const [timeString, modifier] = selectedTime.split(" ");
    if (!timeString || !modifier) return null;

    let [hours, minutes] = timeString.split(":");

    if (modifier === "PM" && hours !== "12") {
        hours = String(Number(hours) + 12);
    } else if (modifier === "AM" && hours === "12") {
        hours = "00";
    }

    const combinedDateTimeString = `${datePart}T${hours}:${minutes}:00`;
    return dayjs.tz(combinedDateTimeString, timeZone).toDate(); // Return as Date object
};
  useEffect(() => {
    const selectedDate = scheduleOrderForm.getValues("selectedDate");
    const selectedTime = scheduleOrderForm.getValues("selectedTime");
    if (selectedDate && selectedTime && selectedTimeZone) {
      const combinedDateTime = combineDateTime(moment(selectedDate), selectedTime, selectedTimeZone.value);
      if (combinedDateTime) {
        setDateTime(dayjs(combinedDateTime));
        setValue("appointment_date", combinedDateTime);
        setValue("time_zone",selectedTimeZone?.value)
      }
    }
  }, [scheduleOrderForm.getValues("selectedDate"), scheduleOrderForm.getValues("selectedTime"), selectedTimeZone]);

  useEffect(() => {
    if (scheduleOrderForm.getValues("appointment_date")) {
      setDateTime(dayjs(scheduleOrderForm.getValues("appointment_date")));
    }
    if (scheduleOrderForm.getValues("address")?.verified) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  }, [scheduleOrderForm]);

  const hasAppointmentDate = Boolean(scheduleOrderForm.getValues("selectedTime")) && Boolean(scheduleOrderForm.getValues("selectedDate"));

  return (
    <>
      {tab === ScheduleOptions.PICKUP ? (
        <RescheduleAppointmentWrap theme={theme} className="dialog date-time-picker">
          <ChooseDateTime
            scheduleOrderForm={scheduleOrderForm}
            selectedTimeZone={selectedTimeZone}
            setSelectedTimeZone={setSelectedTimeZone}
          />
        </RescheduleAppointmentWrap>
      ) : tab === ScheduleOptions.DELIVERY ? (
        <>
          {isDateTimeView ? (
            <RescheduleAppointmentWrap theme={theme} className="dialog date-time-picker">
              <ChooseDateTime
                scheduleOrderForm={scheduleOrderForm}
                selectedTimeZone={selectedTimeZone}
                setSelectedTimeZone={setSelectedTimeZone}
              />
            </RescheduleAppointmentWrap>
          ) : (
            hasAppointmentDate && (
              <DeliveryStepTwo
                form={scheduleOrderForm}
                isGoogleEnabled={isGoogleEnabled}
                addressValue={'address'}
                moveIn={false}
                errorProps={errors.address}
                control={control}
                isContactRequired={false}
                setSubmitText={setSubmitText}
              />
            )
          )}
        </>
      ) : null}
    </>
  );
};

export default RescheduleAppointmentDialog;
