import { useTheme } from '@mui/material'
import { Box, Grid, Input, Typography, } from '@ntpkunity/controls'
import { Stack, Textbox, SelectionButton } from '@components'
import { TrimLevelPopupWrap } from './trim-level-popup.style'
import React from 'react'
import { useAppContext } from '@app/context-provider'

export const TrimLevelPopup = ({setShowProgressBar}) => {
    const theme = useTheme()
    setShowProgressBar(false)
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders

    return(
        <TrimLevelPopupWrap theme={theme} className='trim-level-popup-wrap has-header-footer'>
            <Stack bgLight paddingXs={3} paddingMd={3} className='trim-level'>
                <Box theme={theme}>
                    <Typography theme={theme} component={'p'} variant='subtitle1' mb={'4px'}>{PLACEHOLDERS.TRADE_IN_TRIM_LEVEL_POPUP_QUESTION_ONE}</Typography>
                    <Typography theme={theme} component={'p'} variant='body2' className='text-muted'>
                        {PLACEHOLDERS.TRADE_IN_TRIM_LEVEL_POPUP_EXPLANATION}
                    </Typography>
                </Box>
                <Box theme={theme} mt={3}>
                    <Typography theme={theme} component={'p'} variant='subtitle1' mb={'4px'}>{PLACEHOLDERS.TRADE_IN_TRIM_LEVEL_POPUP_QUESTION_TWO}</Typography>
                    <Typography theme={theme} component={'p'} variant='body2' className='text-muted'>
                        {PLACEHOLDERS.TRADE_IN_TRIM_LEVEL_POPUP_INSTRUCTIONS}
                    </Typography>
                </Box>
            </Stack>
        </TrimLevelPopupWrap>
    )
}


