import { useTheme } from "@mui/material";
import SingleChatWrap from "./single-chat.style";
import { Box, Skeleton, Typography } from "@ntpkunity/controls";
import { SingleChatProps } from "./single-chat.props";
import { useMemo } from "react";

export const MessageSkeleton = () => {
  const theme = useTheme();
  function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const width = useMemo(() => randomIntFromInterval(70, 120), []);
  const isCustomer = useMemo(() => Math.random() < 0.5, []);

  return (
    <SingleChatWrap
      theme={theme}
      className={`chats-wrap ${isCustomer ? "receiver-chat" : "sender-chat"}`}
    >
      <Skeleton
        theme={theme}
        height={52}
        width={width}
        className="message-text"
      />
      <Box theme={theme} className="chat-details">
        <Skeleton theme={theme} height={16} width={isCustomer ? 85 : 20} />
        <Box theme={theme} className="separator"></Box>
        <Skeleton theme={theme} height={16} width={65} />
      </Box>
    </SingleChatWrap>
  );
};

export const SingleChat = ({
  fromOtherSide,
  message,
  messageDetails,
  name,
  time,
}: SingleChatProps) => {
  const theme = useTheme();
  return (
    <SingleChatWrap
      theme={theme}
      className={`chats-wrap ${
        fromOtherSide ? "receiver-chat" : "sender-chat"
      }`}
    >
      <Typography
        theme={theme}
        component="p"
        variant="body2"
        className="message-text"
      >
        {message}
      </Typography>
      {messageDetails && (
        <Box theme={theme} className="chat-details">
          <Typography
            theme={theme}
            component="span"
            variant="caption"
            className="text-dark"
            children={name}
          />
          <Box theme={theme} className="separator"></Box>
          <Typography
            theme={theme}
            component="span"
            variant="caption"
            children={time}
          />
        </Box>
      )}
    </SingleChatWrap>
  );
};

export default SingleChat;
