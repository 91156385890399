import { ReactNode, useEffect, useState } from 'react'
import { HeaderMenuWrap } from './header-menu.style'
import { IconButton, useTheme } from '@mui/material'
import clsx from 'clsx'
import { Icon, Box, Dialog, Button, Typography } from '@ntpkunity/controls'
import { HeaderMenuRight, HeaderSearch, Logo, NavLink, Popup } from '@components'
import { Navigation, QueryKeys } from '@constants'
import { useAuthentication } from 'hooks/useAuthenticationHook'
import { useWorkflowContext } from '@app/router-workflow'
import { useQueryClient } from 'react-query'
import { PreQualificationForm, PreQualificationResponse } from '@ntpkunity/controls-common'
import { defaultLanguages } from '@constants'
import DisableLoader from '../../src/shared/assets/loader-disabled.gif'
import { useForm } from 'react-hook-form'

import { validation_response } from '../../helpers/data_change'
import { useGetDealerProfileByDealerCode, useGetHeaderStates, useGetIntegrationByProviderName, useGetPreQualification } from 'hooks/order-management'
import { IntegrationProvider, LenderIntegrationType } from 'constants/providers'
import {useParams} from "react-router-dom";
import { logout } from '@ntpkunity/controls-ums'

import { formatUrl } from 'helpers/methods'
import { Link } from 'react-router-dom'
import { useAppContext, changeUserLoggedInState, updateHeaderFooter } from '@app/context-provider'
declare interface IHeaderMenu {
  openMenu: boolean
  setOpenMenu: any
  openSearch: boolean
  setOpenSearch: any
}
interface PreQualificationResponseItem {
  title: string
  value: string
}

interface PreQualificationResponseProps {
  state: boolean
  refNo: string
  title: string
  subTitle: string | ReactNode
  items: PreQualificationResponseItem[]
}

export const HeaderMenu = ({ openMenu, setOpenMenu, openSearch, setOpenSearch }: IHeaderMenu) => {
  const theme = useTheme()
  const { navigateNext, getOrderWorkflow } = useWorkflowContext()
  const queryClient = useQueryClient()
  const { mutate: preQualification, isLoading: saveLoading } = useGetPreQualification()
  const {
    state: {
      tenant,
      header_links,
      language: { placeholders },
      slug
    },
    dispatch,
  } = useAppContext()

  const user_zipcode = JSON.parse(localStorage.getItem('user_profile'))
  const customerData: any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_BY_EMAIL)
  const [socureEnabled, setSocureEnabled] = useState(false)
  const [showdialogue, setShowdialogue] = useState(false)
  const { mutate: getProvider } = useGetIntegrationByProviderName(IntegrationProvider.SOCURE, LenderIntegrationType.FRAUD_IDENTIFICATION, tenant, slug)
  const [showresponse, setShowresponse] = useState(false)

  const defaultValues = {
    homeText: 'Home',
    newInventory: 'Inventory',
    preOwnedInventory: 'Pre-Owned Inventory',
    contactUs: 'Contact Us',
    logout: 'Logout',
    preQualification: 'Pre-Qualification Check',
    login: 'Login',
    createAccount: 'Create Account',
    profile: 'Profile',
  }

	const { authenticationStatus } = useAuthentication()
	const [isAuthenticated, setIsAuthenticated] = useState(authenticationStatus)
	const [state, setState] = useState<Array<any>>([])
	const { data: stateData, refetch } = useGetHeaderStates(isAuthenticated, slug)
	const user_profile = JSON.parse(localStorage.getItem('settings'))
	const [datacheck, setDatacheck] = useState<PreQualificationResponseProps | undefined>(undefined)
	const dealerCode = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code ?? 0;
	const dealerProfile: any = queryClient.getQueryData([QueryKeys.DEALER_PROFILE, dealerCode])
	const dealerNumber = dealerProfile?.customer_support_number?.replace(/(.{3})(?=.)/g, '$1 ')

	const onSubmit = (data: any) => {
		const dealer_id = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_id
		const body = {
			...data,
			dealer_id,
		}
		preQualification(body, {
			onSuccess: (_responce: any) => {
				let response_text
				response_text = validation_response(_responce)
				setDatacheck(response_text)
				form.reset()
			},
		})
	}
	useEffect(() => {
		if (datacheck) {
			setShowdialogue(false)
			setShowresponse(true)
		}
	}, [datacheck])
	useEffect(() => {
		if (dealerCode && tenant) {
			refetch()
			getProvider(
				{
					provider_name: IntegrationProvider.SOCURE,
					integration_type: LenderIntegrationType.FRAUD_IDENTIFICATION,
					company_id: tenant,
				},
				{
					onSuccess(response: any) {
						setSocureEnabled(response?.is_active)
					},
				}
			)
		}
	}, [authenticationStatus, tenant, dealerCode])


  useEffect(() => {
    if (stateData?.length > 0) {
      setState(stateData)
    }
  }, [stateData])
  const form = useForm()
  useEffect(() => {
    setIsAuthenticated(authenticationStatus)
  }, [authenticationStatus])

  return (
    <>
      <HeaderMenuWrap theme={theme} className='header-menu-wrap'>
        <Box theme={theme} display={'flex'} flexGrow={1}>
          <Box theme={theme} className='app-logo' flexGrow={1} order={{ xs: 2, md: 1 }}>
            <Logo onClick={() => { }} />
          </Box>
          <IconButton
            className={clsx('toggle-icon', openMenu ? '' : 'change')}
            disableRipple={true}
            disableTouchRipple={true}
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={() => {
              setOpenMenu(!openMenu)
            }}
          >
            <Box theme={theme} className='menu-icon'>
              <Box theme={theme} className='toggle-bar'></Box>
            </Box>
          </IconButton>
          <IconButton
            className={'toggle-icon icon-search'}
            disableRipple={true}
            disableTouchRipple={true}
            aria-label='account of current user'
            aria-controls='searbar'
            aria-haspopup='true'
            onClick={() => setOpenSearch(!openSearch)}
          >
            <Icon name='SearchIcon' />
          </IconButton>
        </Box>
        <Box theme={theme} className={clsx('header-nav-wrap', openMenu ? '' : 'menu-open')}>
        {header_links.length > 0 &&
          <ul className='navbar-nav nav-center'>
              {header_links.map(({ menu_url, menu_name, id }) => (
                <li key={id} className='nav-item'>
                  <Link to={formatUrl(menu_url)} aria-label={menu_name} className='link custom-link'>
                    {menu_name}
                  </Link>
                </li>
              ))}
            </ul>
          }
          <Box theme={theme} className='responsive-user-menu-list' display={{ xs: 'block', md: 'none' }}>
            <Box theme={theme} className='responsive-user-menu' display={{ xs: 'block', md: 'none' }}>
              <ul className='navbar-nav'>
                {isAuthenticated && (
                  <li className='nav-item'>
                    <NavLink
                      title={placeholders?.PROFILE_TEXT ?? defaultValues.profile}
                      onClick={() => {
                        navigateNext(`${Navigation.PROFILE.USER_PROFILE}`)
                        setOpenMenu(!openMenu)
                      }}
                      ariaLabel={placeholders?.PROFILE_TEXT}
                    />
                  </li>
                )}
                {!isAuthenticated && (
                  <>
                    <li className='nav-item'>
                      <NavLink
                        title={placeholders?.LOGIN_TEXT ?? defaultValues.login}
                        onClick={() => {
                          navigateNext(Navigation.AUTHENTICATION.LOG_IN_PAGE)
                          setOpenMenu(!openMenu)
                        }}
                        ariaLabel={placeholders?.LOGIN_TEXT}
                      />
                    </li>
                    <li className='nav-item'>
                      <NavLink
                        title={placeholders?.CREATE_ACCOUNT_TEXT ?? defaultValues.createAccount}
                        onClick={() => {
                          navigateNext(`${Navigation.AUTHENTICATION.SIGN_IN_PAGE}`)
                          setOpenMenu(!openMenu)
                        }}
                        ariaLabel={placeholders?.CREATE_ACCOUNT_TEXT}
                      />
                    </li>
                  </>
                )}
              </ul>
            </Box>
            <Box theme={theme} className='responsive-user-menu' display={{ xs: 'block', md: 'none' }}>
              <ul className='navbar-nav'>
                <li className='nav-item'>
                  <NavLink
                    title={'English'}
                    startIcon='AmericaLangIcon'
                    onClick={() => {
                      setOpenMenu(!openMenu)
                    }}
                    selected
                    ariaLabel='America Lang Icon'
                  />
                </li>
              </ul>
            </Box>
            <Box theme={theme} className='responsive-user-menu' display={{ xs: 'block', md: 'none' }}>
              <ul className='navbar-nav'>
                {isAuthenticated && (
                  <>
                    <li className='nav-item'>
                      <NavLink
                        title={placeholders?.LOGOUT_TEXT ?? defaultValues.logout}
                        onClick={() => {
                          localStorage.clear()
                          dispatch(changeUserLoggedInState(false))
                          dispatch(updateHeaderFooter({}, [], [], ''))
                          queryClient.clear()
                          setOpenMenu(!openMenu)
                          logout((`${Navigation.AUTHENTICATION.LOG_IN_PAGE.replace(':tenant', slug)}`))
                        }}
                        ariaLabel={placeholders?.LOGOUT_TEXT}
                      />
                    </li>
                  </>
                )}
              </ul>
            </Box>
            </Box>
          <HeaderMenuRight />
        </Box>
        <>
          {showdialogue ? (
            <>
              <Popup>
                <Dialog
                  theme={theme}
                  size='sm'
                  title={defaultLanguages?.placeholders.HEADER_PREQUALIFICATION}
                  open={true}
                  disablePortal
                  onCloseDialog={() => {
                    form.reset()
                    setShowdialogue(false)
                  }}
                  noFooter={true}
                  customFooter={
                    <Button
                      theme={theme}
                      primary
                      type='submit'
                      text={'Submit'}
                      startIcon={saveLoading && <img src={DisableLoader} alt='Loader' />}
                      disabled={saveLoading}
                      onClick={form.handleSubmit(onSubmit)}
                    />
                  }
                >
                  <PreQualificationForm
                    theme={theme}
                    formText={defaultLanguages?.placeholders.FORMTEXT_HEADER}
                    addressVerified={false}
                    form={form}
                    email={user_profile?.email}
                    first_name={customerData?.first_name}
                    last_name={customerData?.last_name}
                    zip_code={user_zipcode?.zip_code}
                    statesData={{
                      data: state,
                      selectedItemObject: { text: 'name', value: 'code' },
                    }}
                    countries={[{ id: 229 }]}
                    addressTitle={defaultLanguages?.placeholders.ADDRESS_HEADER}
                    disclaimerText={defaultLanguages?.placeholders.DISCLAIMER_PRE_QUALIFIED}
                  />
                </Dialog>
              </Popup>
            </>
          ) : null}
        </>
        <>
          {showresponse ? (
            <>
              <Popup>
                <Dialog
                  variant={undefined}
                  size='sm'
                  title=''
                  open={showresponse}
                  onCloseDialog={() => {
                    setShowresponse(false)
                  }}
                  theme={theme}
                  disablePortal
                  customFooter={
                    <>
                      <Box theme={theme} className='footer-full-width-btns'>
                        <Button
                          theme={theme}
                          secondary
                          text={datacheck.state ? 'Send Email' : 'Start Chat'}
                          startIcon={datacheck.state ? null : <Icon name='StartChatIcon' />}
                          onClick={() => { }}
                        />
                        <Button
                          theme={theme}
                          secondary
                          text={datacheck.state ? 'Print' : dealerNumber}
                          startIcon={datacheck.state ? null : <Icon name='CallIcon' />}
                          onClick={() => { }}
                        />
                      </Box>
                    </>
                  }
                >
                  <PreQualificationResponse
                    title={datacheck.title}
                    subTitle={datacheck.subTitle}
                    refNo={datacheck.refNo}
                    items={datacheck.items}
                    theme={theme}
                  />
                </Dialog>
              </Popup>
            </>
          ) : null}
        </>
      </HeaderMenuWrap>
      <HeaderSearch
        searchOpen={openSearch}
        onClose={() => {
          setOpenSearch(!openSearch)
        }}
      />
    </>
  )
}
