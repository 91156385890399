import React, {FC, useReducer} from 'react';
import { useTheme } from '@mui/material'
import { Box, Button, Icon, Typography, Dialog, Textarea } from '@ntpkunity/controls'
import { CancelOrderDialogWrap } from './cancel-order-poup.style'
import { Popup } from '@components'
import { useAppContext } from "@app/context-provider";
import { useUpdateOrderStatus } from '@hooks';
import { useOrderContext } from '@pages';
import { Status } from 'constants/enums';
import { useWorkflowContext } from '@app/router-workflow';
import { validation } from '@helpers';
import { Controller, useForm } from 'react-hook-form';
import DisableLoader from 'src/shared/assets/loader-disabled.gif';
import {SnackbarUtility} from "@utilities";

export const CancelOrderDialog = ({setCancelorder, handleSuccess})  => {
    const theme = useTheme()
    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit
      } = useForm()
    const { state: appState } = useAppContext()
    const { state: { order } } = useOrderContext()
    const { mutate: updateOrderStatus,isLoading } = useUpdateOrderStatus()
    const PLACEHOLDERS = appState.language.placeholders
    const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)

    const onDeleteConfirm = () => {
        const reasonValues = getValues('reason');
        const data = {
            status: Status.Withdrawn,
            reference_number: order?.reference_number,
            user_id: 0,
            reason: reasonValues,
        };

        const handleError = () => {
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_CANCELLATION_FAILED));
            setTimeout(() => {
                snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR());
            }, 3000);
        };

        updateOrderStatus(data, { onSuccess: handleSuccess, onError: handleError });
    };


    return (
        <>
            <Popup>
                <Dialog
                    theme={theme}
                    size="sm"
                    open={true}
                    onCloseDialog={()=>{ setCancelorder ?
                        setCancelorder(false) : false
                    }}
                    disablePortal
                    noFooter
                >
                    <CancelOrderDialogWrap theme={theme} className='alert-popup-wrap'>
                        <Box theme={theme} mb={4} pt={6}>
                            <Icon name="DialogAlertIcon" />
                        </Box>
                        <Box theme={theme}>
                            <Typography theme={theme} mb={2} className="content-title" variant="h2" component="h2">
                                {PLACEHOLDERS.ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_ORDER}
                            </Typography>
                            <Typography theme={theme} className="text-muted" variant="body2" component="span" display={'block'} mb={4}>
                                {PLACEHOLDERS.CANCEL_THIS_ORDER_POPUP_DESC}
                            </Typography>
                            <form>
                                <Controller
                                    control={control}
                                    name="reason"
                                    render={({ field }) => (
                                        <Textarea
                                            theme={theme}
                                            fullWidth={true}
                                            rows={4}
                                            label='Reason'
                                            placeholder={'Type here..'}
                                            type="text"
                                            id={'string'}
                                            error={errors?.reason?.message?.toString()}
                                            {...field}
                                        />
                                    )}
                                />
                            <Box theme={theme} mt={4} mb={5} display={'flex'} justifyContent={'center'} gap={2}>
                                <Button theme={theme} secondary text={"No, Don't Cancel"} onClick={()=>{setCancelorder(false)}} />
                                <Button theme={theme} danger text={"Cancel Order"} startIcon={isLoading && <img src={DisableLoader} alt="Loader" />}
                                onClick={handleSubmit(onDeleteConfirm)} disabled={isLoading}/>
                            </Box>
                            </form>

                        </Box>
                    </CancelOrderDialogWrap>
                </Dialog>
            </Popup>
        </>
    )
}

