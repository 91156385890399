import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ScheduleOptions } from '../../constants/schedule-options'; // Replace with actual path to constants


function createValidationSchema(PLACEHOLDERS: any) {
  return yup.object().shape({
    appointment_date: yup.string().required(PLACEHOLDERS.APPOINTMENT_DATE_IS_REQUIRED),
    address: yup.object().shape({
      address_line_1: yup.string().nullable().required(PLACEHOLDERS.SCHEDULE_ADDRESS_LINE_1_IS_REQUIRED),
      city: yup.string().nullable().required(PLACEHOLDERS.SCHEDULE_CITY_IS_REQUIRED),
      state_name: yup.string().nullable().required(PLACEHOLDERS.SCHEDULE_STATE_NAME_IS_REQUIRED),
      zip_code: yup.string().nullable().required(PLACEHOLDERS.SCHEDULE_ZIP_CODE_IS_REQUIRED)
    }),
  });
}

export const getScheduleFormDefaultValues = (scheduleData?:any) => ({
  address: {
    address_line_1: scheduleData?.address?.address_line_1 || '',
    address_line_2: scheduleData?.address?.address_line_2 || '',
    state_name: scheduleData?.address?.state_name || '',
    zip_code: scheduleData?.address?.zip_code || '',
    county: scheduleData?.address?.county || '',
    city: scheduleData?.address?.city || '',
  },

  appointment_type: scheduleData?.appointment_type || ScheduleOptions.DELIVERY,
});

export const useScheduleForm = (scheduleData?: any, PLACEHOLDERS?: any) => {
  const validationSchema = createValidationSchema(PLACEHOLDERS);

  const form = useForm<any>({
    defaultValues: getScheduleFormDefaultValues(scheduleData),
    resolver: yupResolver(validationSchema),
    mode: 'all'
  });

  const { watch } = form;
  const watchScheduleFields = watch(['address']);
  return {
    scheduleForm: form,
    watchScheduleFields,
  };
};
