import { useMutation } from 'react-query'
import { LeadManagement } from '../apis'
import { OrderManagement } from '../apis'
import { useAppContext, updateHeaderFooter } from '../app/context-provider'
export const useUpdateLead = (slug: string): any => {
	const { dispatch: appDispatch } = useAppContext()
	return useMutation<any>(
		(body: any) => {
			return LeadManagement.updateLead(body, slug)
		},
		{
			onSuccess() {
				const dealorCode = JSON.parse(localStorage.getItem('dealer_information')).dealer?.dealer_code
				OrderManagement.getDealerProfileByDealerCode(dealorCode, slug).then((dealerProfile) => {
					const footerLinks: any = []
					const headerLinks: any = []
					dealerProfile?.custom_menu_links?.forEach((link) => {
						if (link.menu_position === 'Footer') footerLinks.push(link)
						else if (link.menu_position === 'Header') headerLinks.push(link)
					})
					appDispatch(
						updateHeaderFooter(
							{
								twitter: dealerProfile.twitter || '',
								linkedin: dealerProfile.linkedin || '',
								youtube: dealerProfile.youtube || '',
								google: dealerProfile.google || '',
								instagram: dealerProfile.instagram || '',
								facebook: dealerProfile.facebook || '',
							},
							headerLinks,
							footerLinks,
							dealorCode
						)
					)
				})
			},
		}
	)
}
