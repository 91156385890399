import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const WayInsuranceWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.way-insurance-wrap': {
        '.loader-wrap': {
            height: 500
        },
        '&.no-footer': {
            paddingBottom: 60
        }
    }
}))
