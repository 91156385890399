import { useTheme } from '@mui/material'
import { Grid, Input, Typography, } from '@ntpkunity/controls'
import { Stack, Textbox, SelectionButton } from '@components'
import { MakeModelPopupWrap } from './make-modal-poup.style'
import React, {useEffect, useState} from 'react'
import { useAppContext } from '@app/context-provider'

export const MakeModelPopupTwo = (props) => {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders
    const {vehicle, setValue, messages, clearErrors, trigger, getValues, setMakeModelYearTrim,setProgressValue} = props
    setProgressValue(22.22)
    const values = getValues()
    const {make} = values
    const [filter, setFilter] = useState(vehicle || [])
    const [filterValue, setFilterValue] = useState('')
    const [selectedItem, setSelected] = useState(make)
    const [selectedValue] = useState(make)


    useEffect(() => {
        if(make !== selectedValue){
            setValue(messages.name.Model, null)
            setValue(messages.name.Trim, null)
            setMakeModelYearTrim(prev =>({
                ...prev,
                "make_id": null,
            }))
        }
    }, [make]);
    useEffect(() => {
        const item = filter.find(item => item?.makeName === selectedItem)
        if(item) {
            setMakeModelYearTrim(prevState => ({
                ...prevState,
                'make_id': item?.makeId
            }))
            clearErrors()
            trigger()
        }
    }, [selectedItem]);

    const handleFilter = (value) => {
        setFilter(vehicle?.filter(item => item?.makeName?.toLowerCase().includes(value?.toLowerCase())))
        setFilterValue(value)
    }

    const handleSelection = (item) => {
        setSelected(item?.makeName)
        setValue(messages?.name?.Make, item?.makeName)

    }
    return(
        <MakeModelPopupWrap theme={theme} className='make-model-popup-two-wrap has-header-footer'>
            <Stack bgLight paddingXs={3} paddingMd={3} className='make-modal-popup'>
                <Grid
                    theme={theme}
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 2 }}
                >
                    <Grid item xs={12} theme={theme} className='sticky'>
                        <Typography theme={theme} component={'p'} variant='subtitle1' mb={'8px'}>{PLACEHOLDERS.TRADE_IN_MAKE_MODEL_POPUP_TWO_MAKE_PROMPT_LABEL}</Typography>
                        <Textbox>
                            <Input value={filterValue} name='makeFilter' type={'text'} fullWidth theme={theme} placeholder={PLACEHOLDERS.TRADE_IN_MAKE_MODEL_POPUP_TWO_INPUT_PLACEHOLDER} onChange={(value) => handleFilter(value)}/>
                        </Textbox>
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <SelectionButton
                            buttonItems={filter.map((item) => ({
                                title: item.makeName,
                                onClick: () => handleSelection(item),
                                isSelected: item?.makeName === selectedItem
                            }))}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </MakeModelPopupWrap>
    )
}


