import { createTheme } from '@mui/material'
import { unityTheme } from '@ntpkunity/controls'

export const CustomColors = {
  primary: '#0653b6', // BMW Primary
  primaryTransBg: 'rgba(#0653b6, 0.08)', // BMW Primary Transparent Background
  gradient: 'linear-gradient(100deg, #0653b6 0%, #0653b6 0.01%, #0653b6 100%)', // BMW Gradient

  // primary: '#5528ED', // Lane Primary
  // primaryTransBg: 'rgba(#5528ED, 0.08)', // Lane Primary Transparent Background
  // gradient: 'linear-gradient(100deg, #6C00FF 0%, #6C00FF 0.01%, #00B5AB 100%)', // Lane Gradient

  lightGrey: '#F8F8F8',
  lightGreyB: '#F5F5F7',

  inputLableTextColor: 'rgba(0,0,0,0.87)',
  inputPlaceholder: 'rgba(0,0,0,0.87)'
}

export const lightTheme = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: { main: CustomColors.primary }
  }
})
