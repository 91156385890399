import { FC, useState, useContext, useEffect, useReducer } from 'react'
import { useTheme } from '@mui/material'
import { useForm } from 'react-hook-form';
import { AppContext } from '@app/context-provider'
import { Box, Button, Snackbar, Typography } from '@ntpkunity/controls';
import { IFormInputs } from 'interfaces'
import { getDealerCode } from '../../../helpers/index'
import AddressDetailsComponent from 'libraries/financing-control/ResidenceDetails/address-detail';
import { useUpdateCustomerContact } from '@hooks';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SnackbarUtility } from '@utilities'
import DisableLoader from '../../../src/shared/assets/loader-disabled.gif'
import { UserProfileWrap } from './user-profile.style';
import { IntegrationProvider, LenderIntegrationType } from 'constants/providers'
import { useGetIntegrationByProviderName } from 'hooks/order-management'

export const ProfileAddress: FC<{
    contactData: any
    customerReferenceID: string
}> = ({ contactData, customerReferenceID }) => {

    const createValidationSchema = () => {
        const schema = yup.object().shape({
            mail_address: yup.object().shape({
                address_line_1: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ADDRESS_LINE_1_IS_REQUIRED),
                city: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_CITY_IS_REQUIRED),
                state_name: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
                state_id: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
                zip_code: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ZIP_CODE_IS_REQUIRED)
            })
        })
        return schema
    }

    const theme = useTheme()
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders
    const validationSchema = createValidationSchema()
    const [isGoogleEnabled, setIsGoogleEnabled] = useState(false)
    const company_id = appState.state.tenant
    const { mutate: getProvider} = useGetIntegrationByProviderName( IntegrationProvider.GOOGLE,
      LenderIntegrationType.ADDRESS_VALIDATION,company_id, appState?.state?.slug);
    const [isAddressDirty, setIsAddressDirty] = useState(false)
    const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
    const { mutate: updateCustomerByReferenceId , isLoading: updateProfileAddressLoading} = useUpdateCustomerContact()
    const dealerCode = getDealerCode()

    useEffect(() => {
        const mailAddress = contactData?.customer_addresses?.find(
            (x: any) => x?.address_type === 'Mailing'
        )
        setValue('mail_address', mailAddress)
        setValue('mail_address.state_id', mailAddress?.state_name)

    }, [contactData])

    const form = useForm<IFormInputs>({
        resolver: yupResolver(validationSchema),
        mode: 'onTouched'
    })
    useEffect(() => {
        if(company_id)
       { getProvider(
          {
            provider_name: IntegrationProvider.GOOGLE,
            integration_type: LenderIntegrationType.ADDRESS_VALIDATION,
            company_id:company_id
          },
          {
            onSuccess(response: any) {
              setIsGoogleEnabled(response?.is_active)
            }
          }
        )}
      }, [company_id])

    const {
        handleSubmit,
        formState: { errors, isDirty },
        getValues,
        setValue
    } = form

    const onSubmit = (data: IFormInputs) => {
        const addresses = []
        const mailAddress = getValues('mail_address')

        if (mailAddress) {
            mailAddress.address_type = 'Mailing'
            if (!dealerCode) {
                mailAddress.verified = false
            }
            addresses.push({
                ...mailAddress
            })
        }
        const newData = {
            customer_addresses: addresses,
            reference_id: customerReferenceID
        }
        updateCustomerByReferenceId(newData,
            {
                onSuccess() {
                    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.PROFILE_ADDRESS_SAVE))
                    setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
                }
            }
        )
    }

    return (
        <>
            <UserProfileWrap theme={theme} className='profile-address'>
                <Typography theme={theme} variant='h4' component={'h4'} mb={3} display={'block'}>
                    {PLACEHOLDERS.ADDRESS_CARD_YOUR_CURRENT_ADDRESS}
                </Typography>
                <AddressDetailsComponent
                    form={form}
                    isGoogleEnabled={isGoogleEnabled}
                    dealerCode={dealerCode}
                    addressValue={'mail_address'}
                    setIsAddressDirty={setIsAddressDirty}
                    moveIn={false}
                    errorProps={errors.mail_address}
                />
                {isDirty || isAddressDirty ? (
                    <Box theme={theme} mt={3}>
                        <Button theme={theme} primary type="submit"
                        disabled={updateProfileAddressLoading}
                        text={PLACEHOLDERS.USER_PROFILE_SAVE_CHANGE}
                        startIcon={updateProfileAddressLoading &&  <img src={DisableLoader} alt="Loader" />}
                        onClick={handleSubmit(onSubmit)} />
                    </Box>
                ) : null}
                <Snackbar
                    theme={theme}
                    message={snackbarState.message}
                    open={snackbarState.open}
                    onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
                    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                />
            </UserProfileWrap>
        </>
    )
};
