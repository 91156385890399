import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const DriftChatHeaderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.messages-header-wrap': {
    padding: 24,
    position: 'relative',
    display: 'flex',
    gap: 24,
    alignItems: 'center',
    borderBottom: '1px solid' + theme.palette.grey[50],
    justifyContent: 'space-between',
    '.sender-wrap': {
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      '.sender-details': {
        display: 'flex', 
        alignItems: 'center',
        flexGrow: 1,
        '.status': {
          width: 8,
          height: 8,
          borderRadius: 8,
          marginRight: 8,
          display: 'inline-block',
          '&.online, ': {
            backgroundColor: theme.palette.success.main
          },
          '&.offline': {
            backgroundColor: theme.palette.grey[300]
          },
          '&.away': {
            backgroundColor: theme.palette.warning.main
          }
        }, 
        '.sender-name': {
          flex: 1
        }
      }
    },
    '.btn.with-icon.icon-only': {
      padding: 0,
      svg: {
        path: {
          stroke: theme.palette.grey[400]
        }
      },
      '&:hover': {
        backgroundColor: 'initial',
        svg: {
          path: {
            stroke: theme.palette.grey[400]
          }
        }
      },
      '&.phone-icon': {
        svg: {
          path: {
            stroke: 'transparent',
            fill: theme.palette.grey[400]
          }
        }
      }
    }
  }
}))
