import { useTheme } from '@mui/material';
import { Box, Grid, Image, Typography, Dialog, Button } from '@ntpkunity/controls';
import React from 'react';
import { OrderChangePopupWrap } from './order-Update-popup.style';
import { useAppContext } from '@app/context-provider';
import { Popup } from '@components';

export const OrderUpdatePopup = ({ isOpen, onClose,popupimage}) => {
    const theme = useTheme();
    const { state: appState } = useAppContext();
    const PLACEHOLDERS = appState.language.placeholders;
    return (
        <>
            <Popup>
                <Dialog
                    theme={theme}
                    size="sm"
                    open={isOpen}
                    disablePortal
                    noFooter
                >
                    <OrderChangePopupWrap theme={theme} className='order-change-popup-wrap no-header-footer'>
                        <Grid
                            theme={theme}
                            container
                            rowSpacing={0}
                            columnSpacing={{ xs: 2 }}
                            sx={{ textAlign: 'center' }}
                        >
                            <Grid item xs={12} theme={theme}>
                                <Box theme={theme} mb={3} className='product-img'>
                                    <Image
                                        theme={theme}
                                        src={popupimage}
                                        aspectRatio='16:9'
                                        alt='product-img'
                                    />
                                </Box>
                                <Typography theme={theme} component={'h3'} variant='h3' mb={2}>
                                    {PLACEHOLDERS.ORDER_UPDATE_POPUP_TITLE}
                                </Typography>


                                <Typography theme={theme} className='text-muted' component={'p'} variant='body2'>
                                    {PLACEHOLDERS.ORDER_UPDATE_POPUP_SUB_TITLE}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box theme={theme} textAlign={'center'} mt={3} marginBottom={{ xs: 4, sm: 8}}>
                            <Button type="button" primary theme={theme} text="Ok" onClick={onClose} />
                        </Box>
                    </OrderChangePopupWrap>
                </Dialog>
            </Popup>
        </>
    );
};
