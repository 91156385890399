import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CjButtonWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.cj-button-wrap': {
    '.btn': {
      padding: '8px 16px',
        '&.btn-primary': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.grey[900],
          borderColor: theme.palette.common.white,
          '&:hover': {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.grey[900],
              borderColor: theme.palette.common.white, 
          }
        },
        '&.btn-default': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.grey[900],
          borderColor: theme.palette.common.white,
          padding: '10px 16px',
          '&:hover': {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.grey[900],
              borderColor: theme.palette.common.white, 
          }
        },
        '&.btn-secondary': {
          backgroundColor: theme.palette.grey[700],
          color: theme.palette.common.white,
          borderColor: theme.palette.grey[700],
          '&:hover': {
            backgroundColor: theme.palette.grey[700],
            color: theme.palette.common.white,
            borderColor: theme.palette.grey[700], 
          }
        }
      }
  }
}))
