import React, { ReactNode } from 'react'
import { useTheme } from '@mui/material'
import { CreateNewOrderWrap } from './add-new-card.style'
import { Icon, Typography } from '@ntpkunity/controls'
import { AddNewCardProps } from './add-new-card.props'

export const AddNewCard = ({ title, icon, onClick }: AddNewCardProps) => {
    const theme = useTheme()
  return (
    <CreateNewOrderWrap className='add-new-card-wrap' theme={theme} onClick={onClick}>
        {icon}
        {title &&
          <Typography theme={theme} variant='body2' component={'p'} display={'block'} className='text-muted'>{title}</Typography>
        }
      
    </CreateNewOrderWrap>
  )
}
