import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const StepWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.step-wrap': {
        '.step-item': {
            backgroundColor: theme.palette.common.white,
            padding: '20px 24px',
            borderRadius: 16,
            border: '2px solid transparent',
            marginBottom: 8,
            transition: '0.4s',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            gap: '24px',
            [theme.breakpoints.down('md')]: {
                padding: '12px 24px'
            },
            '&:hover': {
                border: '2px solid rgba(0, 0, 0, 0.10)',
            },
            '&.is-active': {
                border: '2px solid ' + theme.palette.primary.main,
                [theme.breakpoints.down('md')]: {
                    border: 'none',
                    backgroundColor: '#F5F5F7',
                    '.card-content': {
                        '.MuiTypography-subtitle1': {
                            color: theme.palette.grey[900],
                            fontWeight: theme.typography.fontWeightMedium
                        }
                    }
                }
            },
            '.MuiButtonBase-root': {
                '&.icon-only': {
                    padding : 0,
                },
                '&.btn-default': {
                    'svg': {
                        'path':{
                            stroke: theme.palette.grey[500],
                        }
                    }
                }
            },
            '.card-content': {
                '.MuiTypography-caption': {
                    [theme.breakpoints.down('md')]: {
                        display: 'none'
                    }
                },
                '.MuiTypography-subtitle1': {
                    [theme.breakpoints.down('md')]: {
                        color: theme.palette.grey[600],
                        fontWeight: theme.typography.fontWeightRegular
                    }
                }
            },
            '.step-action': {
                display: 'flex',
                gap: '12px',
                '.cj-icon': {
                    display: 'flex',
                    '&.error': {
                        svg: {
                            path: {
                                stroke: theme.palette.error.main,
                            }
                        }
                    },
                    '&.success': {
                        svg: {
                            path: {
                                stroke: theme.palette.success.main,
                            }
                        }
                    },
                    '.btn-default': {
                        '&:hover': {
                            svg: {
                                path: {
                                    stroke: theme.palette.grey[500],
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}))
