import { CustomColors, lightTheme } from '../styles/theme'
import { styled } from '@mui/material/styles'

export const VehicleCardUi = styled('div')(({ theme }) => ({
  '.links': {
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
    'a, span': {
      fontSize: theme.typography.body2,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: '12px',
      color: theme.palette.primary.main,
      borderRight: '1px solid',
      borderColor: theme.palette.primary.main,
      paddingRight: 8,
      display: 'flex',
      margin: 'inherit',
      marginTop: 0,
      '&.link': {
        textDecoration: 'underline !important',
        cursor: 'pointer',
      },
      '&:last-child': {
        borderRight: 0
      }
    },
  },
  '.price-info': {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  '&.vehicle-card': {
    backgroundColor: theme.palette.common.white,
    width: 320,
    height: 'auto',
    minWidth: 320,
    maxWidth: 320,
    border: '1px solid' + theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: 24,
    transition: 'all 0.3s',
    position: 'relative',
    // overflow: 'hidden',
    marginRight: 24,
    float: 'left',
    flexGrow: 1,
    flexBasis: 0,
    wordWrap: 'break-word',
    fontFamily: theme.typography.fontFamily,
    marginBottom: 24,
    '.carousel-img': {
      maxHeight: 495,
      [theme.breakpoints.down('sm')]: {
        maxHeight: 188,
      },
      'img': {
        maxHeight: 495,
        width: 'auto',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
          maxHeight: 188,
        },
      }
    },
    '.carousel-area': {
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: '#F5F5F7',
        borderRadius: 16,
        padding: 24
      }
    },
    '.info-head-stack': {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    '&:last-child': {
      marginRight: 0
    },
    span: {
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    '.vc-img-wrap': {
      backgroundColor: theme.customVariables.tableStripedBg,
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      height: 140,
      display: 'flex',
      verticalAlign: 'middle',
      textAlign: 'center',
      overflow: 'hidden',
      marginBottom: 16,
      img: {
        maxWidth: '100%',
        maxHeight: 140,
        width: 'auto',
        height: 'auto',
        display: 'block',
        margin: 'auto'
      },
      svg: {
        display: 'block',
        maxHeight: 140,
        maxWidth: '100%'
      },
      '&.h-120': {
        height: 120,
        img: {
          maxHeight: 120
        }
      },
      '&.blank-img-wrap': {
        height: 140,
        marginBottom: 40,
        img: {
          maxHeight: 140
        }
      },
      '.blank-img-wrap': {
        marginBottom: 18,
        width: '100%'
      }
    },
    '.vc-footer': {
      '.action': {
        visibility: 'hidden',
        opacity: 0,
        transition: 'all 0.3s',
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: -1,
        position: 'absolute',
        left: 24,
        right: 24,
        bottom: 15,
        padding: '5px 0',
        backgroundColor: theme.palette.common.white,

        '.btn': {
          paddingLeft: 5,
          paddingRight: 5,
          flex: 'auto',

          '&.btn-secondary': {
            maxWidth: 50,
            marginLeft: 10,

            '&.btn-view': {
              marginLeft: 0,
              marginRight: 10
            }
          }
        }
      },
      '.specs': {
        textAlign: 'center',
        transition: 'all 0.3s',
        '.subtitle2': {
          fontSize: theme.typography.caption.fontSize
        },
        span: {
          lineHeight: 'normal'
        }
      }
    },
    '&:hover': {
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
      '.vc-footer': {
        '.action': {
          visibility: 'visible',
          opacity: 1,
          zIndex: 1
        }
      }
    },

    '.tag': {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.caption.fontSize,
      background: theme.palette.grey[700],
      color: theme.palette.common.white,
      borderRadius: +theme.shape.borderRadius * 2,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      padding: '3px 8px',
      position: 'absolute',
      top: '16px',
      left: '16px',
      lineHeight: '18px'
    },

    '.title': {
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.grey[600],
      width: '100%',
      display: 'block',
      clear: 'both',
      whiteSpace: 'nowrap',
      wordWrap: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    '.price': {
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[900],
      width: '100%',
      display: 'block',
      clear: 'both',
      marginBottom: 8
    },

    '.link': {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      color: lightTheme.palette.primary.main,
      display: 'inline-block',
      clear: 'both',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline !important'
      }
    },
    '.coverage': {
      backgroundColor: CustomColors.lightGreyB,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      width: '100%',
      marginBottom: 16,
      padding: '12px 16px',
      justifyContent: 'space-between',
      b: {
        color: lightTheme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold
      }
    },

    '&.full-width': {
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      display: 'inline-block',
      textAlign: 'center',
      position: 'relative'
    },

    '&.no-border': {
      border: '0 solid transparent'
    },

    '&.border-radius-lg': {
      borderRadius: 16
    }
  },

  '&.mini-carousel': {
    '.carousel-button-group': {
      display: 'none'
    },
    '.react-multi-carousel-list': {
      paddingBottom: 0,
      paddingLeft: 0,
      opacity: 0,
      visibility: 'hidden',
      height: 0,
      '.react-multi-carousel-item': {
        backgroundColor: theme.customVariables.tableStripedBg,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        img: {
          maxHeight: 140,
          maxWidth: '100%',
          objectFit: 'cover',
          width: 'auto',
          margin: 'auto',
          display: 'block'
        }
      },
      '.react-multi-carousel-dot-list': {
        bottom: 0
      }
    },
    '.single-img': {
      height: 140,
      marginBottom: 40,
      img: {
        maxHeight: 140
      }
    },
    '&:hover': {
      '.react-multi-carousel-list': {
        paddingBottom: 40,
        opacity: 1,
        visibility: 'visible',
        height: 'auto'
      },
      '.react-multi-carousel-dot-list': {
        bottom: 15
      },
      '.single-img': {
        display: 'none'
      }
    }
  },

  '&.vd-popup': {
    border: 'none',
    borderRadius: 0,
    padding: 0,
    textAlign: 'left !important',
    '&:hover': {
      boxShadow: 'none',
      cursor: 'unset'
    },
    '.vc-img-wrap': {
      height: 250,
      img: {
        maxHeight: 250
      }
    },
    '.react-multi-carousel-dot--active button': {
      background: lightTheme.palette.primary.main,
      '&:active': {
        background: lightTheme.palette.primary.main
      }
    }
  },

  '&.m-dialog': {
    '.react-multi-carousel-list': {
      paddingBottom: 24,
      paddingLeft: 0,
      paddingRight: 0,
      '.react-multi-carousel-item': {
        '.vc-img-wrap': {
          height: 360,
          margin: '0 32px',
          img: {
            maxHeight: 250,
            maxWidth: '100%',
            objectFit: 'cover'
          }
        }
      },
      '.react-multi-carousel-dot-list': {
        bottom: 0
      }
    },
    '.carousel-button-group': {
      '.carousel-control': {
        top: '50%'
      }
    }
  }
}))
