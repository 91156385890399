import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ProductDetailCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.product-detail-card-wrap': {
    backgroundColor: theme.palette.common.white,
    padding: '40px',
    borderRadius: 16,
    border: '2px solid transparent',
    [theme.breakpoints.down('md')]: {
        padding: '24px',
        '.MuiGrid-container': {
          '.MuiGrid-item': {
            '&:first-child': {
              order: 2,
            },
            '&:last-child': {
              order: 1,
            }
          }
        }
    },
    '.media-element': {
      overflow: 'hidden',
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center'
      },
      'img': {
        borderRadius: theme.shape.borderRadius,
        aspectRatio: '16/9'
      },
      'iframe': {
        borderRadius: theme.shape.borderRadius,
        aspectRatio: '16/9',
        width: '316px',
        [theme.breakpoints.down('md')]: {
          width: '100%'
        },
      }
    },
    '.cj-card-content': {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      '.list-items': {
        marginTop: 16,
      }
    }
  }
}))
