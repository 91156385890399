import { useContext } from 'react'
import { useTheme } from '@mui/material'
import { CjLogoWrap } from './logo.style'
import { LogoProps } from './logo.props'
import { AppContext } from '@app/context-provider'

export const Logo = ({ onClick }: LogoProps) => {
  const theme = useTheme()
  // using useContext to get the state and dispatch from the context
  const appState = useContext(AppContext);

  // Set favicon
  const changeFavicon = (newFaviconUrl: string) => {
    // Get the current favicon link element
    const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement || document.createElement('link');

    // Set the new properties
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = newFaviconUrl;

    // Replace the old favicon with the new one
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  if (appState.state?.theme?.favicon?.location) {
    changeFavicon(appState.state.theme.favicon.location);
  }

  return (
    <CjLogoWrap className='cj-logo-wrap' theme={theme}>
      <a href="javascript:void(0)" onClick={onClick} aria-label='logo'>
        {
          appState.state.theme?.logo?.location && <img src={appState.state.theme.logo?.location} alt='logo' />
        }
      </a>
    </CjLogoWrap>
  )
}
