import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const MakeModelPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.make-model-popup-two-wrap': {
    '.make-modal-popup': {
      '.sticky': {
          position: 'sticky',
          top: 0,
          backgroundColor: '#F5F5F7'
        },
        '.selection-button-wrap': {
            flexWrap: 'wrap'
        },
    }
  },
  '&.make-model-popup-wrap': {
    '.make-modal-popup': {
        '.sticky': {
          position: 'sticky',
          top: 0,
          backgroundColor: '#F5F5F7'
        },
        '.selection-button-wrap': {
            flexWrap: 'wrap',
            '.cj-btn': {
                width: '23.25%',
                [theme.breakpoints.down('md')]: {
                  width: '22.25%'
                }
                
            }
        }
    }
  },
  '&.make-model-popup-five-wrap': {
    '.MuiFormControlLabel-root': {
      '&.u-custom-control-label': {
        margin: '8px 0 0'
      }
    }
  }
}))
