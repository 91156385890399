import React, { Dispatch, ReactNode, createContext, useContext, useReducer } from "react";

export interface IPayment {
   finance_program: any,
   lease_program: any,
   // finance_quotation: Object,
   // lease_quotation: Object,
   // annual_usage: number,
   // contract_term: number,
   // credit_rating: string,
   // down_payment: number,
   // finance_type: string,
   // defaultTerms: number,
   // defaultMiles: number,
   // defaultCreditRating: string,
}

export enum ActionType {
   UPDATE_FINANCE_PROGRAM = 'UPDATE_FINANCE_PROGRAM',
   UPDATE_LEASE_PROGRAM = 'UPDATE_LEASE_PROGRAM',
   UPDATE_FINANCE_QUOTATION = 'UPDATE_FINANCE_QUOTATION',
   UPDATE_LEASE_QUOTATION = 'UPDATE_LEASE_QUOTATION',
   UPDATE_MILES = 'UPDATE_MILES',
   UPDATE_TERMS = 'UPDATE_TERMS',
   UPDATE_CREDIT_RATING = 'UPDATE_CREDIT_RATING',
   UPDATE_DOWN_PAYMENT = 'UPDATE_DOWNPAYMENT',
   UPDATE_DEFAULT_CREDIT_RATING = 'UPDATE_DEFAULT_CREDIT_RATING',
   UPDATE_FINANCE_TYPE = 'UPDATE_FINANCE_TYPE',
   DEFAULT_TERMS_AND_MILES = 'DEFAULT_TERMS_AND_MILES'
}

// Initial state
export const initialState: IPayment = {
   finance_program: {},
   lease_program: {},
   // finance_quotation: {},
   // lease_quotation: {},
   // annual_usage: 0,
   // contract_term: 0,
   // credit_rating: '',
   // down_payment: 0,
   // finance_type: 'Lease',
   // defaultTerms: 0,
   // defaultMiles: 0,
   // defaultCreditRating: 'Excellent'
}

// Action methods
export const updateFinanceProgram = (program: Object) => ({ type: ActionType.UPDATE_FINANCE_PROGRAM, payload: program })
export const updateLeaseProgram = (program: Object) => ({ type: ActionType.UPDATE_LEASE_PROGRAM, payload: program })
export const updateFinanceCalculation = (quotation: Object) => ({ type: ActionType.UPDATE_FINANCE_QUOTATION, payload: quotation })
export const updateLeaseCalculation = (quotation: Object) => ({ type: ActionType.UPDATE_LEASE_QUOTATION, payload: quotation })
export const updateDownPayment = (downPayment: number) => ({ type: ActionType.UPDATE_DOWN_PAYMENT, payload: downPayment })
export const updateMiles = (miles: number) => ({ type: ActionType.UPDATE_MILES, payload: miles })
export const updateTerms = (terms: number) => ({ type: ActionType.UPDATE_TERMS, payload: terms })
export const updateCreditRating = (creditRating: string) => ({ type: ActionType.UPDATE_CREDIT_RATING, payload: creditRating })
export const updateFinanceType = (finance_type: string) => ({ type: ActionType.UPDATE_FINANCE_TYPE, payload: finance_type })
export const updateSetupData = (setup_data: Object) => ({ type: ActionType.DEFAULT_TERMS_AND_MILES, payload: setup_data })


// Reducer
export const paymentReducer = (state: IPayment = initialState, action: { type: ActionType; payload: any }) => {
   switch (action.type) {
      case ActionType.UPDATE_FINANCE_PROGRAM:
         return { ...state, finance_program: action.payload }
      case ActionType.UPDATE_LEASE_PROGRAM:
         return { ...state, lease_program: action.payload }
      case ActionType.UPDATE_FINANCE_QUOTATION:
         return { ...state, finance_quotation: action.payload }
      case ActionType.UPDATE_LEASE_QUOTATION:
         return { ...state, lease_quotation: action.payload }
      case ActionType.UPDATE_DOWN_PAYMENT:
         return { ...state, down_payment: action.payload }
      case ActionType.UPDATE_CREDIT_RATING:
         return { ...state, credit_rating: action.payload }
      case ActionType.UPDATE_MILES:
         return { ...state, annual_usage: action.payload }
      case ActionType.UPDATE_TERMS:
         return { ...state, contract_term: action.payload }
      case ActionType.UPDATE_FINANCE_TYPE:
         return { ...state, finance_type: action.payload }
      case ActionType.DEFAULT_TERMS_AND_MILES:
         return {
            ...state,
            defaultTerms: action.payload.defaultTerms,
            defaultMiles: action.payload.defaultMiles
         }
      default:
         return state
   }
}

// Create context
export const PaymentCreateContext = createContext<{
   state: IPayment;
   dispatch: Dispatch<any>;
}>({
   state: initialState,
   dispatch: () => null
});

// Create provider
export const PaymentContextProvider = ({ children }: { children: ReactNode }) => {
   const [state, dispatch] = useReducer(paymentReducer, initialState);
   return <PaymentCreateContext.Provider value={{ state, dispatch }}>{children}</PaymentCreateContext.Provider>;
};

export const usePaymentContext = () => useContext(PaymentCreateContext)