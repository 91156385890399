import { useEffect, useState } from 'react';
import { Checkbox, Grid, Typography } from '@ntpkunity/controls';
import { MakeModelPopupWrap } from './make-modal-poup.style';
import { Stack } from '@components';
import { useTheme } from '@mui/material';

export const MakeModelPopupFive = ({ options, setselectedOptions, selectedOptions, setProgressValue }) => {
    const theme = useTheme();
    setProgressValue(55.5)
    const [checkboxStates, setCheckboxStates] = useState({});
    useEffect(() => {
        const initialState = {};
        if (Array.isArray(options)) {
            options.forEach(option => {
                initialState[option.vehicleOptionId] = selectedOptions?.some(selected => selected.vehicleOptionId === option.vehicleOptionId);
                if (selectedOptions?.length === 0 && option.isTypical) {
                    setselectedOptions(prev => [...prev, { vehicleOptionId: option.vehicleOptionId }]);
                }
            });
        }
        setCheckboxStates(initialState);
    }, [options, selectedOptions, setselectedOptions]);

    const handleCheckboxChange = (optionId) => {
        const isCurrentlyChecked = checkboxStates[optionId];
        setCheckboxStates(prevState => ({
            ...prevState,
            [optionId]: !isCurrentlyChecked
        }));
        if (isCurrentlyChecked) {
            setselectedOptions(prev => prev.filter(option => option.vehicleOptionId !== optionId));
        } else {
            if (!options.find(option => option.vehicleOptionId === optionId && option.isTypical)) {
                setselectedOptions(prev => [...prev, { vehicleOptionId: optionId }]);
            }
        }
    };

    const groupedOptions = Array.isArray(options)
        ? options?.reduce((acc, option) => {
            const { categoryGroup } = option;
            if (!acc[categoryGroup]) {
                acc[categoryGroup] = [];
            }
            acc[categoryGroup].push(option);
            return acc;
        }, {})
        : {};
    return (
        <MakeModelPopupWrap theme={theme} className='make-model-popup-five-wrap'>
            <Stack bgLight paddingXs={2} paddingMd={3} className='make-modal-popup'>
                <Typography theme={theme} component={'p'} variant='subtitle1'>
                    Which options are currently with your vehicle?
                </Typography>
                <Typography theme={theme} className='text-muted' component={'span'} variant='body2' display={'block'} mt={1}>
                    Be sure to answer thoroughly, since these options will affect your vehicle's value.
                </Typography>
                {Object?.keys(groupedOptions)?.map((group, idx) => (
                    <Grid key={idx} theme={theme} container rowSpacing={0} columnSpacing={3} mt={3}>
                        <Grid item xs={10} theme={theme}>
                            <Typography theme={theme} className='fw-sbold' component={'span'} variant='body2' display={'block'}>
                                {group || "Other"}
                            </Typography>
                        </Grid>
                        {groupedOptions[group]?.map((option) => (
                            <Grid item xs={6} key={option.vehicleOptionId} theme={theme}>
                                <Checkbox
                                    theme={theme}
                                    label={option.optionName}
                                    checkBoxChecked={checkboxStates[option.vehicleOptionId] || false}
                                    checked={checkboxStates[option.vehicleOptionId] || false}
                                    onChange={() => handleCheckboxChange(option.vehicleOptionId)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Stack>
        </MakeModelPopupWrap>
    );
};
