import { Endpoint } from "@constants";
import { http } from "../utilities";

const UMS_BASE_URL = process.env.UMS_BASE_URL;
const CONFIG_BASE_URL = process.env.CONFIG_BASE_URL;

export namespace TenantManagement {
  export const getTenantConfigurations = async ({ slug }: { slug: string }) => {
    const body = {
      url: Endpoint.GET_LENDER_BY_SLUG,
      method_type: "get",
    };
    return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body);
  };

  export const getTenantTheme = async (email: string, slug: string ) => {
    const body = {
      url: `${Endpoint.GET_TENANT_THEME_BY_EMAIL}?user_email=${email}`,
      method_type: "get",
    };
    return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body);
  };

  export const getDefaultLenderConfigurations = async ( company_id, slug) => {
   const body = {
      url: `${Endpoint.DEFAULT_LENDER_CONFIGURATION}`,
      method_type: "get",
      company_id : company_id
    };
    return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body);
  };

  export const getLenderById = async (id: number) => {
      return http(CONFIG_BASE_URL).get(`${Endpoint.LENDER_CONFIG}/${id}`).then((res) => {
            return res.data
        });
  }
}
