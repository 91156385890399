import { Endpoint } from "../constants/endpoint";
import { http } from "../utilities";
import {UMS_BASE_URL} from "./order-management";

export const DMS_BASE_URL =  process.env.DMS_BASE_URL;

export namespace LeadManagement {

  export const updateLead = (data :any , slug: string):any => {
    const body = {
      url: `${Endpoint.EP_DMS_LEAD}`,
      method_type: 'patch',
      payload: {
        ...data
      }
    }
     return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

}
