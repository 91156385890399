import { Link, useTheme } from '@mui/material'
import { Box, Button, Icon, Typography } from '@ntpkunity/controls'
import { ProductAddCardProps } from './product-add-card.props'
import { ProductAddCardWrap } from './product-add-card.style'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Stack } from '@components'
import { useOrderContext } from '@pages'
import { areArraysEqual } from 'helpers/methods'
import { getTabsClass } from '@helpers'

export const ProductAddCard = ({ title, primaryText, secondaryText, buttonText, isSelected, onClick, onCardClick }: ProductAddCardProps) => {
    const theme = useTheme()
    const {
      state: { activeTab },
    } = useOrderContext();
    const [orderTabs, setOrderTabs] = useState([]);

    useEffect(() => {
      const workflow: any = JSON.parse(localStorage.getItem('workflow'))
      if (workflow?.meta_data?.tabs && !areArraysEqual(workflow?.meta_data?.tabs, orderTabs)) {
        setOrderTabs(workflow?.meta_data?.tabs)
      }
    })

    return (
      <ProductAddCardWrap theme={theme} className="product-detail">
        <Stack
          dFlex
          justifyBetween
          className={clsx({ "cj-card": true, selected: isSelected })}
        >
          <Box theme={theme} onClick={onCardClick} className='cp'>
            {title && (
              <Typography
                theme={theme}
                className="fw-medium"
                variant="body2"
                component={"p"}
                display={"block"}
              >
                {title}
              </Typography>
            )}
            <Box
              theme={theme}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "baseline",
                gap: "4px",
              }}
            >
              {primaryText && (
                <Typography
                  theme={theme}
                  className="text-primary"
                  variant="h4"
                  component={"h4"}
                  display={"block"}
                >
                  {primaryText}
                </Typography>
              )}
              {secondaryText && (
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component={"p"}
                  display={"block"}
                >
                  {secondaryText}
                </Typography>
              )}
            </Box>
          </Box>
          {buttonText && (
            <Box theme={theme} className="card-action">
              <Button
                theme={theme}
                secondary
                className={clsx({
                  [`${getTabsClass(orderTabs,activeTab,false)}-add`]: true,
                  'btn-desktop': true
                })}
                text={buttonText}
                onClick={onClick}
              />
              <Button
                theme={theme}
                secondary
                className={clsx({
                  [`${getTabsClass(orderTabs,activeTab,false)}-add`]: true,
                  'btn-mobile': true
                })}
                iconText={<Icon name="AddIcon" />}
                ariaLabel='Add Icon'
                onClick={onClick}
              />
            </Box>
          )}
        </Stack>
      </ProductAddCardWrap>
    );
}