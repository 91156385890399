import { Endpoint } from "@constants";
import { http } from "../utilities";
import { CONFIG_API_GATEWAY } from "./order-management";

export const DMS_BASE_URL = process.env.DMS_BASE_URL;
export const UMS_BASE_URL = process.env.UMS_BASE_URL;

export namespace DealerManagement {
  export const getDealerPreference = async ({
    dealerCode,
    slug,
  }: {
    dealerCode: string;
    slug: string;
  }) => {
    const body = {
      url: `${Endpoint.DMS_DEALER_PREFERENCE_BY_DEALER_CODE}/${dealerCode}`,
      method_type: "get",
    };

    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const getDealerByDealerCode = async (data: any) => {
    const { dealerCode, headers } = data;
    return http(CONFIG_API_GATEWAY)
      .get(`${Endpoint.DEALER}/${dealerCode}`, {
        headers,
      })
      .then((res) => {
        return res.data;
      });
  };

  export const getDealerChatConfiguration = async (
    dealerCode: string
  ): Promise<{
    company_id: string;
    id: number;
    offline_message: string;
    welcome_chat: string;
  }> => {
    return http(CONFIG_API_GATEWAY)
      .get(`${Endpoint.DEALER_CHAT_CONFIG}/${dealerCode}`)
      .then((res) => {
        return res.data;
      });
  };

  export const getDealerFeesByState = async ({
    dealerCode,
    stateCode,
    slug,
  }: {
    dealerCode: string;
    stateCode: string;
    slug: string;
  }) => {
    const queryParams = `state_code=${stateCode}`;
    const body = {
      url: `${Endpoint.GET_DMS_DEALER_FEE_BY_STATE}/${dealerCode}`,
      method_type: "get",
      payload: {
        state_code: stateCode,
      },
    };
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const getOrderInfo = async ({
    order_reference,
    slug,
  }: {
    order_reference: string;
    slug: string;
  }) => {
    const body = {
      url: `${Endpoint.PUBLIC_ORDER_INFO}/${order_reference}`,
      method_type: "get",
    };
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };

  export const getLanguage = async ({
    slug,
  }: {
    slug: string;
  }) => {
    const body = {
      url: `${Endpoint.DEALER_PLACHOLDER}`,
      method_type: "get",
    };
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
        return res.data;
      });
  };
}