import { useContext, useEffect, useState } from 'react'
import { FC } from 'react'
import { useTheme } from '@mui/material'
import { AddressLayout, Grid, PhoneInput } from '@ntpkunity/controls'
import { useGetCountries } from '../../../hooks'
import { QueryKeys } from '@constants'
import { Controller } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { IDealerProfile, IStates } from 'interfaces'
import { AppContext } from '@app/context-provider'

interface IAddressComponentProps {
  isGoogleEnabled: boolean
  form: any
  addressValue?: string
  errorProps?: any
  setIsAddressDirty?: any
  moveIn?: boolean
  control?: any
  isContactRequired?: boolean
  showContactNumber?: boolean
  contactError?: string
  contactName?: string
}

const AddressDetailsComponent: FC<IAddressComponentProps> = ({
  isGoogleEnabled,
  form,
  addressValue,
  setIsAddressDirty,
  errorProps,
  moveIn = true,
  control,
  isContactRequired = false,
  showContactNumber = true,
  contactError,
  contactName
}) => {
  const theme = useTheme();
  const { setValue, watch} = form
  const appState = useContext(AppContext);
  const { data: countriesServiceData } = useGetCountries(appState?.state?.slug)

  const queryClient = useQueryClient();
  const LANGUAGE_PLACEHOLDERS = appState.state.language.placeholders;

  const [countriesData, setCountriesData] = useState([])
  const [dealerCountry, setDealerCountry] = useState<Array<any>>([])

  const statesData: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );
  const dealerCode = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code ?? 0;
  const dealerProfile: IDealerProfile = queryClient.getQueryData(
   [ QueryKeys.DEALER_PROFILE,dealerCode]
  );

  useEffect(() => {
    setCountriesData(countriesServiceData)
  }, [countriesServiceData])

  useEffect(() => {
    setDealerCountry(
      countriesData?.filter(
        (x: any) => x?.id == dealerProfile?.dealer_address?.country_id
      )
    );
  }, [countriesData])

  return (
    <>
      {dealerCountry?.length > 0 && statesData?.length > 0 ? (
        <>
          <AddressLayout
            addressVerificationRequired={isGoogleEnabled}
            hideAddressButton={!isGoogleEnabled}
            theme={theme}
            state={{
              data: statesData,
              selectedItemObject: { text: 'name', value: 'name' },
              controlName: 'state_name'
            }}
            countries={dealerCountry}
            validationProps={errorProps}
            isContactRequired={false}
            value={watch(`${addressValue}`)}
            verifyAddressURL={`${process.env.HUBEX_BASE_URL}/api/verify-address/`}
            token={localStorage.getItem('access_token')}
            dealerCode={JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code}
            setIsAddressDirty={setIsAddressDirty}
            onChange={(e: any) => {
              setValue(`${addressValue}`, e);
              setValue(`${addressValue}.state_name`, e?.state_name);
              setValue(`${addressValue}.state_id`, e?.state_id);
              if(!isGoogleEnabled){
                setValue(`${addressValue}.verified`, true);

              }

            }}
            showCountryDropdown={false}
            disableCounty={isGoogleEnabled}
            moveInRequired={moveIn}
            placeholders={LANGUAGE_PLACEHOLDERS}
          />
          {showContactNumber ? (
            <Grid theme={theme} container item spacing={3}>
              <Grid item xs={12} theme={theme} mt={3}>
                <Controller
                  name={contactName ? contactName : 'contact_number'}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                    theme={theme}
                    type="text"
                    label="Contact Number (Optional)"
                    phoneCountryCode={dealerCountry?.[0]?.country_code ?? 'US'}
                    error={contactError}
                    {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : null}
        </>
      ) : null}
    </>
  )
}

export default AddressDetailsComponent;
