import { FC, useEffect, useState, useContext, useReducer } from 'react'
import { Box, Button, CircleLoader, Grid, Input, Select, Snackbar, Typography } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Stack, Textbox, SelectionButton } from '@components'
import { validation, isEmpty } from '../../../helpers/index'
import { ResidenceType } from '../../../constants/residence-type'
import { IPersonalFinance } from 'interfaces'
import { AppContext } from '@app/context-provider'
import { useAddPersonalFinance, useUpdatePeronalFinanceInfo } from 'hooks'
import { pagesActionType, useJourneyLayoutContext } from 'layouts/journey-layout/journey-layout-context-provider'
import { useFormContext } from "../../../pages/(order-management)/order/form-context-provider";
import { SnackbarUtility } from '@utilities'
import { TaggingClasses } from 'constants/enums'

interface IPersonalFinanceComponentProps {
    personalFinanceData?: any
    customerReferenceId?: any
    form: any
}

const residenceType = [
    { text: 'Mortgage', value: 'Mortgage' },
    { text: 'Renting', value: 'Renting' },
    { text: 'Personal Ownership', value: 'Personal Ownership' },
    { text: 'With Relatives', value: 'With Relatives' }
]

export const PersonalFinanceDetailsComponent: FC<IPersonalFinanceComponentProps> = ({
    personalFinanceData,
    customerReferenceId, form }) => {

    const { dispatch: pageDispatch } = useJourneyLayoutContext()
    const { mutate: createPersonalFinance } = useAddPersonalFinance()
    const { mutate: updatePersonalFinance ,isLoading:updatePersonalFinanceIsLoading} = useUpdatePeronalFinanceInfo()
    const [btnValue, setBtnValue] = useState<boolean>(false)
    const appState = useContext(AppContext);
    const defaultCurrency = appState?.state?.default_currency_symbol
    const PLACEHOLDERS = appState.state.language.placeholders
    const { formStates, resetFormState } = useFormContext()
    const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)


    const theme = useTheme()
    const {
        control,
        formState: { errors, isDirty },
        handleSubmit,
        reset,
        setValue,
        watch
    } = form

    const messages = {
        name: {
            residence: 'residence',
            monthlyPayment: 'monthly_payment',
            bankruptcy: 'bankruptcy',
            assetUsage: 'asset_usage'
        },
        label: {
            residence: PLACEHOLDERS.PERSONAL_FINANCE_RESIDENCE_TEXT,
            monthlyPayment: PLACEHOLDERS.PERSONAL_FINANCE_MONTHLY_PAYMENT_TEXT,
            bankruptcy: PLACEHOLDERS.PERSONAL_FINANCE_BANKRUPTCY_TEXT
        },
        placeholder: {
            typeHere: PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER,
            monthlyPayment: '0.00'
        }
    }

    useEffect(() => {
        if (personalFinanceData) {
            const { bankruptcy } = personalFinanceData
            setBtnValue(bankruptcy);

        }
    }, [personalFinanceData])


    const onSubmit = async (data: IPersonalFinance) => {

        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Personal finance details saving'))

        let { residence, monthly_payment, bankruptcy } = data

        const residenceType =
        residence === ResidenceType.Renting || residence === ResidenceType.Mortgage ? true : false
        if (!residenceType) {
            monthly_payment = 0
        }
        const payload = {
            residence,
            monthly_payment,
            bankruptcy: btnValue,
            reference_id: customerReferenceId
        }
        if (isEmpty(personalFinanceData)) {

            createPersonalFinance(
                {
                    ...payload,
                    reference_id: customerReferenceId
                }, {
                onSuccess: () => {
                    snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())

                    resetFormState('personalFinance')
                    pageDispatch({
                        type: pagesActionType.UPDATE_PERSONAL_FINANCE_DETAILS,
                        payload: {
                            success: true,
                            error: false
                        }
                    })
                },
                onError() {
                    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Error while saving Personal finance details'))
                    setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); });


                }
            }
            )

        }
        {
            updatePersonalFinance(payload, {

                onSuccess: () => {
                    snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())

                    resetFormState('personalFinance')
                    pageDispatch({
                        type: pagesActionType.UPDATE_PERSONAL_FINANCE_DETAILS,
                        payload: {
                            success: true,
                            error: false
                        }
                    })
                },
                onError() {
                    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Error while updaing Personal finance details'))
                    setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); });


                }
            })
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length) {
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Error while updaing Personal finance details'))
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); });


            pageDispatch({
                type: pagesActionType.UPDATE_PERSONAL_FINANCE_DETAILS,
                payload: {
                    success: false,
                    error: true
                }
            })
        }
    }, [errors])

    return (
        <>
            <Stack marginTopXs={3} className='scroll-horizontal'>
                <Typography theme={theme} component={'h4'} variant='h4'>{PLACEHOLDERS.FINANCING_PERSONAL_FINANCE_QUESTION}</Typography>
                <Grid theme={theme} container item columnSpacing={2} rowSpacing={3} mt={0}>
                    <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                        <Textbox>
                            <Controller
                                name={messages.name.residence as any}
                                rules={validation(messages.label.residence, true)}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <Select
                                        theme={theme}
                                        {...field}
                                        label={PLACEHOLDERS.PERSONAL_FINANCE_RESIDENCE_TEXT}
                                        items={residenceType}
                                        selectError={errors?.residence?.message}
                                        sxProps={''}
                                        onChange={(e: any) => {
                                            field.onChange(e.target.value)
                                            if (
                                                e.target.value === ResidenceType.Renting ||
                                                e.target.value === ResidenceType.Mortgage
                                            ) {
                                                setValue(
                                                    'monthly_payment',
                                                    personalFinanceData?.monthly_payment > 0
                                                        ? personalFinanceData?.monthly_payment
                                                        : ''
                                                )
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    {(watch('residence') === ResidenceType.Renting ||
                        watch('residence') === ResidenceType.Mortgage) && (
                            <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                                <Textbox>
                                    <Controller
                                        name={messages.name.monthlyPayment as any}
                                        control={control}
                                        rules={validation(messages.label.monthlyPayment, true)}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                theme={theme}
                                                fullWidth
                                                masking
                                                maskNumeric
                                                maskDecimalScale={2}
                                                placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                                                label={messages?.label?.monthlyPayment}
                                                startAdornment={defaultCurrency}
                                                type="text"
                                                error={errors?.monthly_payment?.message?.toString()}
                                            />
                                        )}
                                    />
                                </Textbox>
                            </Grid>
                        )}
                    <Grid theme={theme} item xs={12}>
                        <Typography theme={theme} component={'h4'} variant='h4'>{PLACEHOLDERS.FINANCING_BANKRUPTCY_HISTORY_QUESTION}</Typography>
                        <Box theme={theme} mt={"12px"}>
                            <SelectionButton
                                buttonItems={[
                                    {
                                        title: PLACEHOLDERS.FINANCING_BANKRUPTCY_HISTORY_YES,
                                        onClick: () => {
                                            setBtnValue(true)
                                            setValue('bankruptcy', true)
                                        },
                                        isSelected: btnValue === true,
                                        bgLight: btnValue === false
                                    },
                                    {
                                        title: PLACEHOLDERS.FINANCING_BANKRUPTCY_HISTORY_NO,
                                        onClick: () => {
                                            setBtnValue(false)
                                            setValue('bankruptcy', false)
                                        },
                                        isSelected: btnValue === false,
                                        bgLight: btnValue === true
                                    }
                                ]}
                            />
                        </Box>
                    </Grid>
                </Grid>

            </Stack >
            {
                formStates?.['personalFinance']?.isDirty ? (
                    <Box theme={theme} mt={3} >
                        <Button
                        className={`${TaggingClasses.FINANCING}-personal-finance-save`} 
                        theme={theme} 
                        primary
                        text={
                            <>
                            {updatePersonalFinanceIsLoading && <CircleLoader theme={theme} size="xs" />}
                            {PLACEHOLDERS.FINANCING_SAVE_AND_CONTINUE}

                            </>
                        }
                         type='submit'
                         disabled={updatePersonalFinanceIsLoading}
                         onClick={handleSubmit(onSubmit)}

                        fullWidth
                        />
                    </Box >
                ) : null}
            <Snackbar
                theme={theme}
                message={<Box theme={theme} display={'flex'}>
                    <Box theme={theme} width="30px">
                        <CircleLoader theme={theme} size='xs' />
                    </Box> {snackbarState.message}
                </Box>}
                open={snackbarState.open}
                onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
            />
        </>
    )
};

