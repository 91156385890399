import { useTheme } from '@mui/material'
import { Typography, Switch, Box, AddressLayout, Grid, PhoneInput } from '@ntpkunity/controls'
import { CustomCalendar, Stack } from '@components'
import { AddDeliveryDetailPopupWrap } from './add-delivery-detail-popup.style'
import React, { useContext, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useGetCountries } from '@hooks'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@constants'
import { IDealerProfile, IStates } from 'interfaces'
import { AppContext } from '@app/context-provider'
import { LoaderPopup } from 'libraries/trade-in-journey-components/loader-with-title/loader-component'

export const DeliveryStepTwo = (props) => {
  const {
    form,
    isGoogleEnabled,
    setIsAddressDirty,
    addressValue,
    moveIn,
    errorProps,
    control,
    contactError,
    isContactRequired, showContactNumber, contactName ,setSubmitText} = props
  const { setValue, watch,clearErrors } = form

  const appState = useContext(AppContext);
  const LANGUAGE_PLACEHOLDERS = appState.state.language.placeholders;
  setSubmitText(LANGUAGE_PLACEHOLDERS.CONFIRM_SCHEDULE_BUTTON_TEXT)


  const theme = useTheme()
  const { data: countriesServiceData } = useGetCountries(appState?.state?.slug)

  const queryClient = useQueryClient();
  const [countriesData, setCountriesData] = useState([])
  const [dealerCountry, setDealerCountry] = useState<Array<any>>([])

  const statesData: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );
  const dealerCode = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code ?? 0;
  const dealerProfile: IDealerProfile = queryClient.getQueryData(
    [QueryKeys.DEALER_PROFILE, dealerCode]
  );

  useEffect(() => {
    setCountriesData(countriesServiceData)
  }, [countriesServiceData])

  useEffect(() => {
    setDealerCountry(
      countriesData?.filter(
        (x: any) => x?.id == dealerProfile?.dealer_address?.country_id
      )
    );
  }, [countriesData])

  const handleContactNumberChange = (phone: string) => {
    setValue('contact_number', phone)
  };
  if (!dealerCountry?.length || !statesData?.length) {
    return <LoaderPopup/>;
  }

  return (
    <>
      <AddDeliveryDetailPopupWrap className='delivery-detail-wrap choose-map' theme={theme}>
        <Stack bgLight paddingXs={3} paddingMd={3}>
          <Typography mb={3} theme={theme} variant='subtitle1' component={'p'}>Where do you want to get it delivered?</Typography>
          <>
            {dealerCountry?.length > 0 && statesData?.length > 0 ? (
              <>
                <AddressLayout
                  addressVerificationRequired={isGoogleEnabled}
                  hideAddressButton={!isGoogleEnabled}
                  theme={theme}
                  state={{
                    data: statesData,
                    selectedItemObject: { text: 'name', value: 'name' },
                    controlName: 'state_name'
                  }}
                  countries={dealerCountry}
                  validationProps={errorProps}
                  isContactRequired={false}
                  value={watch(`${addressValue}`)}
                  verifyAddressURL={`${process.env.HUBEX_BASE_URL}/api/verify-address/`}
                  token={localStorage.getItem('access_token')}
                  dealerCode={JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code}
                  setIsAddressDirty={setIsAddressDirty}
                  onChange={(e: any) => {
                    setValue(`${addressValue}`, e);
                    setValue(`${addressValue}.state_name`, e?.state_name);
                    setValue(`${addressValue}.state_id`, e?.state_id);
                    if (!isGoogleEnabled) {
                      setValue(`${addressValue}.verified`, true);

                    }
                    if(addressValue){
                      clearErrors(addressValue)
                    }

                  }}
                  showCountryDropdown={false}
                  disableCounty={isGoogleEnabled}
                  moveInRequired={moveIn}
                  placeholders={LANGUAGE_PLACEHOLDERS}
                />

              </>
            ) : null}
          </>

        </Stack>
      </AddDeliveryDetailPopupWrap>

    </>
  )
}


