import { useTheme } from '@mui/material'
import { AccordianBox, Stack } from '../../components'
import { ContractingStackProps } from './contracting-stack.props'
import { ContractingStackWrap } from './contracting-stack.style'
import { Accordion, Box, Button, Checkbox, Grid, Icon, Menu, Typography } from '@ntpkunity/controls'
import React, { Fragment, useContext } from 'react'
import clsx from 'clsx'
import { AppContext } from '@app/context-provider'


export const ContractingStack: React.FC<ContractingStackProps> = ({ heading, description, items, accordian, menu, onClick, handleOptionSelection }) => {
    const theme = useTheme()
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders;

    return (
        <ContractingStackWrap className='contracting-stack-wrap' theme={theme}>
            <Stack marginTopMd={3} marginTopXs={3} paddingMd={4} paddingXs={3}>
                <Box theme={theme}>
                    {heading &&
                        <Typography theme={theme} className='heading' component={'h4'} mb={0.5} variant='h4'>{heading}</Typography>
                    }
                    {description &&
                        <Typography theme={theme} className='description text-muted' mb={3} display={'block'} component={'small'} variant='caption'>{description}</Typography>
                    }
                </Box>
                {accordian && items.map((item, index) => (
                    <Accordion
                        theme={theme}
                        key={index}
                        items={[
                            {
                                //   isExpanded: true,
                                title: <><Box theme={theme} className='title-with-icon'>{item.title}</Box></>,
                                key: 'string',
                                actionBtn:
                                    <>
                                        <Box theme={theme} className={clsx({ 'items': true, 'success': item.success, 'error': item.error })}>
                                            {item.success && <Icon name='GreenCheckIcon' />}
                                            {item.error && <Icon name='InfoErrorIcon' />}
                                        </Box>
                                        {item.menu &&
                                            <Menu
                                                theme={theme}
                                                options={[
                                                    {
                                                        optionText: PLACEHOLDERS?.CONTRACTING_SIGN_AND_VIEW_OPTION,
                                                        optionkey: 'view',
                                                        optionValue: item.menu,
                                                    }
                                                ]}
                                                handleOptionClick={handleOptionSelection}
                                                render={(onMenuSelection) => (
                                                    <Button
                                                        defaultBtn
                                                        theme={theme}
                                                        iconText={<Icon name="MoreIcon" />}
                                                        ariaLabel='More Icon'
                                                        onClick={onMenuSelection}
                                                    />
                                                )}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right"
                                                }}
                                            />
                                        }
                                    </>,

                                content: (
                                    <>
                                        {item.subItems.map((subItem, index) => (
                                            <Stack paddingMd={2} paddingXs={2} dFlex justifyBetween key={index}>
                                                {item.title &&
                                                    <Typography theme={theme} className='title fw-sbold' variant='body2' component={'p'} display={'block'}>
                                                        {item.title}
                                                        {subItem.subTitle &&
                                                            <Typography theme={theme} className='sub-title text-muted' variant='body2' component={'span'}>{(subItem.subTitle)}</Typography>
                                                        }
                                                    </Typography>
                                                }
                                                <Box theme={theme} className={clsx({ 'item-status-text': true, 'error': subItem.error, 'success': subItem.success })}>
                                                    {subItem.success &&
                                                        <>
                                                            {subItem.successText &&
                                                                <Typography theme={theme} className='text-muted text-msg' component={'p'} variant='body2'>{subItem.successText}</Typography>
                                                            }
                                                            <Icon name='GreenCheckIcon' />
                                                        </>
                                                    }
                                                    {subItem.error &&
                                                        <>  
                                                            {subItem.errorText &&
                                                                <Typography theme={theme} className='text-muted text-msg' component={'p'} variant='body2'>{subItem.errorText}</Typography>
                                                            }
                                                            <Icon name='InfoErrorIcon' />
                                                        </>
                                                    }
                                                </Box>
                                            </Stack>
                                        ))}
                                    </>
                                ),
                            }
                        ]}
                    />
                ))}

                {menu && items.map((item, index) => (
                    <Stack bgLight dFlex justifyBetween key={index} paddingMd={3} paddingXs={3} marginTopMd={2} marginTopXs={2}>
                        <Box theme={theme} className='main-title'>
                            <Typography theme={theme} className='title-with-icon fw-sbold' variant='body2' component={'p'} display={'block'}>
                                {item.title}
                            </Typography>
                        </Box>
                        <Box theme={theme} className='menu'>
                            {item.success && <Icon name='GreenCheckIcon' />}
                            <Menu
                                theme={theme}
                                options={[
                                    {
                                        optionText: PLACEHOLDERS.CONTRACTING_VIEW_OPTION,
                                        optionkey: 'view',
                                        optionValue: item.menu,
                                    }
                                ]}
                                handleOptionClick={handleOptionSelection}
                                render={(onMenuSelection) => (
                                    <Button
                                        defaultBtn
                                        theme={theme}
                                        iconText={<Icon name="MoreIcon" />}
                                        ariaLabel='More Icon'
                                        onClick={onMenuSelection}
                                    />
                                )}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            />
                        </Box>
                    </Stack>
                ))}
            </Stack>
        </ContractingStackWrap>
    )
}




