import { useTheme } from '@mui/material'
import { Stack } from '../../components'
import { JourneyFooterProps } from './journry-footer.props'
import { JourneyFooterWrap } from './journey-footer.style'
import { Box, Button, Icon, Typography } from '@ntpkunity/controls'
import clsx from 'clsx'

export const JourneyFooter = ({ taggingClass, title, subTitle, linkText, buttonText, onLinkClick, onButtonClick, noFooter }: JourneyFooterProps) => {
    const theme = useTheme()
  return (
        <JourneyFooterWrap className={clsx({ 'journey-footer-wrap': true, 'no-footer': noFooter })} theme={theme}>
            <Stack
                paddingMd={2}
                paddingXs={2}
                bgDark
                hasShadow
            >
                <Box theme={theme} className='left-area' pl={1}>
                <a href="#" className='text-white decoration-none' onClick={onLinkClick}>
                <Box theme={theme} display={'inline-block'} width={'auto'} gap={0.5}>
                        <Typography theme={theme} className='text-white' component={'span'} variant='body2' sx={{ mr: '4px', pt: '2px' }}>{title}</Typography>
                        <Typography theme={theme} className='text-white' component={'span'} variant='h4'>{subTitle}</Typography>
                    <Box theme={theme}>
                    <Typography theme={theme} className='text-white' component={'small'} variant='caption'>
                        {linkText}
                    </Typography>
                    </Box>
                    </Box>
                </a>
                </Box>
                {buttonText && <Box theme={theme} className='action-area'>
                    <Box theme={theme} display={{xs: 'none', sm: 'block'}}>
                        <Button className={clsx({[`${taggingClass}-footer`]: !!taggingClass})} theme={theme} primary text={buttonText} onClick={onButtonClick} />
                    </Box>
                    <Box theme={theme} display={{xs: 'block', sm: 'none'}}>
                        <Button className={clsx({[`${taggingClass}-footer`]: !!taggingClass})} theme={theme} primary iconText={<Icon name="IconRightArrow" />} onClick={onButtonClick} />
                    </Box>
                </Box>}
            </Stack>
        </JourneyFooterWrap>
  )
}
