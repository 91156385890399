import { useTheme } from '@mui/material'
import { Box, Button, Grid, Image, Typography } from '@ntpkunity/controls'
import { Stack } from '@components'
import { useState } from 'react'
import { FinalPopupWrap } from './final-popup.style'
import { convertToAmountWithDecimals } from '../../../helpers/methods'
import { useAppContext } from '@app/context-provider'
import { AlertDialog } from '../alert-popup/alert-popup'
import { TaggingClasses, TradeInJourneySteps } from '../../../constants/enums'
import clsx from 'clsx'

export const FinalPopup = (props) => {
	const theme = useTheme()
	const { setNextPopup, getValues, handleSubmit, handleRemove, onProceedWithTradeIn, tradeInType, providerLogoUrl,setProgressValue,setShowProgressBar} = props
	setShowProgressBar(false)
	const values = getValues()
	const { state: appState } = useAppContext()
	const PLACEHOLDERS = appState.language.placeholders

	const { model, year, make, trim, asset_image } = values
	const [isAlertOpen, setIsAlertOpen] = useState(false)
	const [alertContent, setAlertContent] = useState({
		title: '',
		subtitle: '',
		btnText: '',
	})
	const handleClose = () => {
		handleSubmit(onProceedWithTradeIn)()
	}

	const handleAlertOpen = () => {
		setAlertContent({
			title: PLACEHOLDERS.TRADE_IN_REMOVAL_WARNING,
			subtitle: PLACEHOLDERS.TRADE_IN_REMOVAL_CONFIRMATION,
			btnText: PLACEHOLDERS.TRADE_IN_REMOVAL_ACTION,
		})
		setIsAlertOpen(true)
	}

	const handleAlertClose = () => {
		setIsAlertOpen(false)
	}

	const confirmRemove = () => {
		handleRemove()
		handleAlertClose()
	}

	return (
		<>
			<FinalPopupWrap theme={theme} className='final-popup-wrap'>
				<Stack paddingXs={0} paddingMd={0}>
					<Grid theme={theme} container rowSpacing={0} columnSpacing={{ xs: 2 }}>
						{providerLogoUrl ? (
							<Grid item xs={12} theme={theme}>
								<Box theme={theme} mb={3} className='product-logo'>
									<Image theme={theme} src={providerLogoUrl.url} alt={providerLogoUrl.key} />
								</Box>
							</Grid>
						) : null}
						<Grid item xs={12} theme={theme} sx={{ textAlign: 'center' }}>
							<Box theme={theme} mb={3} className='product-img'>
								<Image theme={theme} src={asset_image?.length > 0 ? asset_image[0] : null} aspectRatio='16:9' alt='product-img' />
							</Box>
							<Typography theme={theme} component={'h3'} variant='h3' marginBottom={3}>
								{PLACEHOLDERS.TRADE_IN_FINAL_POPUP_ESTIMATED_VALUE_LABEL}{' '}
								{`${convertToAmountWithDecimals(
									values?.trade_in_amount ? parseFloat(values?.trade_in_amount) : 0,
									appState?.default_currency_code,
									2,
									2
								)}`}
							</Typography>
							<Typography theme={theme} component={'p'} variant='caption' marginBottom={3}>
								{PLACEHOLDERS.TRADE_IN_FINAL_POPUP_FIRM_OFFER_LABEL}{' '}
								<span className='fw-medium'>
									{model && model.split(',').length >= 2
										? model.trim().replace(',', ' ').replace(',', ' ')
										: `${year} ${make} ${model} ${trim ? trim : ''}`}
								</span>{' '}
								{PLACEHOLDERS.TRADE_IN_FINAL_POPUP_CONFIRMATION_MESSAGE}
							</Typography>
						</Grid>
					</Grid>
					<Grid theme={theme} container rowSpacing={2} columnSpacing={2} sx={{ mb: 3 }}>
						<Grid item xs={12} md={6} theme={theme}>
							<Button
								className={clsx({[`${TaggingClasses.TRADE_IN}-proceed`]:true})}
								theme={theme}
								primary
								text={PLACEHOLDERS.TRADE_IN_FINAL_POPUP_PROCEED_BUTTON}
								sx={{ whiteSpace: 'nowrap' }}
								onClick={handleClose}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={6} theme={theme}>
							<Button
								className={clsx({[`${TaggingClasses.TRADE_IN}-edit`]:true})}
								theme={theme}
								onClick={() => setNextPopup(tradeInType, true)}
								secondary
								text={PLACEHOLDERS.TRADE_IN_FINAL_POPUP_EDIT_DETAILS_BUTTON}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} theme={theme} sx={{ textAlign: 'center' }}>
							<Typography theme={theme} component={'p'} variant='body2' className='text-muted'>
								{PLACEHOLDERS.TRADE_IN_FINAL_POPUP_DECLINE_LABEL}
								<a href='#' onClick={handleAlertOpen} className='text-primary fw-medium decoration-none'>
									{' '}
									{PLACEHOLDERS.TRADE_IN_FINAL_POPUP_REMOVE_TEXT}
								</a>
							</Typography>
						</Grid>
					</Grid>
				</Stack>
				{tradeInType !== TradeInJourneySteps.ProvideManualEstimatePopup ? (
					<Typography theme={theme} component='p' variant='body2' className='text-muted' textAlign='center' mb={4}>
						{PLACEHOLDERS.TRADE_IN_PARTNER_DISCLAIMER}
					</Typography>
				) : null}
			</FinalPopupWrap>

			{isAlertOpen ? (
				<AlertDialog
					title={alertContent.title}
					subtitle={alertContent.subtitle}
					btnText={alertContent.btnText}
					isOpen={isAlertOpen}
					onClick={confirmRemove}
					onClose={handleAlertClose}
				/>
			) : null}
		</>
	)
}
