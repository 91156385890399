import { PageAlert } from "@ntpkunity/controls";
import { ErrorInfo, Component } from "react";
import { Button, Icon } from "@ntpkunity/controls";
interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}
interface ErrorBoundary {
  props: any;
}
class ErrorBoundary extends Component<ErrorBoundary, ErrorBoundaryState> {
  constructor(props: ErrorBoundary) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error): ErrorBoundaryState {
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo });
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const { error, errorInfo } = this.state;
      const { theme, navigate } = this.props;

      return (
        <PageAlert
          theme={theme}
          icon="DialogAlertIcon"
          title={"Unexpected Error!"}
          variant="full-page"
          message={
            <div>
              {error?.message}{" "}
              {process.env.NODE_ENV === "development" && (
                <details style={{ whiteSpace: "pre-wrap" }}>
                  <summary>Stack trace</summary>
                  {errorInfo?.componentStack}
                </details>
              )}
            </div>
          }
          actionArea={
            <>
              <Button
                theme={theme}
                fullWidth
                secondary
                startIcon={<Icon name="IconLeftArrow" />}
                text={"Go Back"}
                onClick={() => navigate(-1)} 
              />
              <Button
                theme={theme}
                fullWidth
                danger
                startIcon={<Icon name="ResetIcon" />}
                text={"Refresh"}
                onClick={() => navigate(0)} 
              />
            </>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
