import { CjButton, FileUploader, Popup, SelectionButton, Stack, Textbox } from "@components";
import { useTheme } from "@mui/material";
import { Box, Button, Dialog, FileUploadPreview, FileUpload, Icon, Input, Typography } from "@ntpkunity/controls";
import React, { FC, useState, useEffect, useContext, Fragment } from "react";
import { PageWrap } from "./submit-order.style";
import { Controller, useForm } from 'react-hook-form';
import { AppContext } from '@app/context-provider';
import { getBase64 } from "helpers/methods";
import { useOrderContext } from "@pages";
import { PaymentBreakDownPopup } from "libraries/payment-breakdown-popup/payment-breakdown-popup.component";
import { updateContractTerm, updateDownPayment } from "pages/(order-management)/order/order-context-provider";
import { useCreateDocument, useGetCreditResponse, useGetNonFinancialStips, useGetOrderInformation, useGetSetupData, useGetStipulationsByLenderId } from "hooks/order-management";
import DisableLoader from '../../src/shared/assets/loader-disabled.gif'

export enum StipulationType {
  FINANCIAL = 'financial',
  NON_FINANCIAL = 'non financial'
}

export interface IStipulationResponse {
  stipulation_code: string
  stipulation_type: string
  parameter: string
  description: string
  document_type_id: string
  assignee: string
  lender_id: number
  fulfilled: boolean
  value_to: number
  value_from: number
  document_url?: string
  document_type?: string
}

export const StipPopupComponent: FC<{
  order_info,
  onCloseDialog: () => void,
  defaultCurrency,
  filters,
  setFilters,
  handleFilterUpdate,
  reSubmitOrder: any,
  isReSubmitEnabled: boolean,
  documents: any,
  setDocuments: any
}> = ({ order_info, onCloseDialog, defaultCurrency, filters, setFilters, handleFilterUpdate, reSubmitOrder, isReSubmitEnabled, documents, setDocuments }) => {

  const theme = useTheme();
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;
  const { state: { order, isFlowExecuted }, dispatch: orderDispatch, loading } = useOrderContext()
  const queryParams =`company_id=${appState?.state?.tenant}&setup_data_indicators=contract-terms`
  const [showPopup, setShowPopup] = useState(true);
  const [selectedTerm, setSelectedTerm] = useState<number | null>(filters.terms);
  const [downPaymentValue, setDownPaymentValue] = useState<number | null>(filters.downPayment);
  const [nonStips, setNonStips] = useState([]);
  const [filteredContractTerms, setFilteredContractTerms] = useState([]);
  const [stipulationsByLender, setStipulationsByLender] = useState([])
  const [filteredStipulations, setFilteredStipulations] = useState<IStipulationResponse[]>([]);
  const [isDownPaymentValid, setIsDownPaymentValid] = useState(false);
  const [openPaymentBreakdownPopup, setPaymentBreakdownPopup] = useState(false);
  const [stipCode, setStipCode] = useState('')

  const [creditDecision] = useState(() => {
  const lenderId = order?.lender_id;
  const submissions = order?.order_submissions || [];

  const filteredSubmission = submissions.find(submission => submission.lender_id === lenderId);

  return filteredSubmission ? filteredSubmission.decision : null;
  });
  const { mutate: getNonStips } = useGetNonFinancialStips();
  const { mutate: getStipsByLenderId } = useGetStipulationsByLenderId()
  const { mutate: createDocument } = useCreateDocument();
  const { data: setupData } = useGetSetupData(queryParams, appState?.state?.slug)
  const { control, clearErrors } = useForm<any>({});
  const [groupedDocumentUrls, setGroupedDocumentUrls] = useState<
    Array<{
      identifier: string
      external_reference_id: any
      orderReference: any
      document_type: string
      document_urls: Array<{
        location: string
        name: string
        size: number
      }>
    }>
  >(filters?.documents || [])

  const uploadDocumentHandler = async (
    e: any,
    identifier: string,
    order_reference_number: string,
    document_type: string
  ) => {
    const selectedFile = e.type === 'drop' ? e.dataTransfer.files[0] : e.target.files?.[0]
    const base64 = await getBase64(selectedFile)

    const data = {
      customer_reference_id: order_info?.customer_info?.reference_id,
      external_reference_id: order_reference_number,
      document_type: document_type,
      document_name: selectedFile.name,
      document_size: selectedFile.size,
      created_by: order?.customer_id,
      document: base64,
      category: 'STIP',
      stipulation_code: stipCode,
      content_type: 'Application',
      updated_by: order?.customer_id
    };
    const filteredDocuments = documents.filter((doc: any) => doc.document_size !== null)
    setDocuments([...filteredDocuments, data])

    setGroupedDocumentUrls((prevGroupedDocumentUrls) => {
      const updatedGroupedDocumentUrls = [...prevGroupedDocumentUrls]
      const filteredGroupedDocumentUrls = updatedGroupedDocumentUrls.map((group) => ({
        ...group,
        document_urls: group.document_urls.filter((url) => url.location !== null)
      }))
      const existingGroupIndex = filteredGroupedDocumentUrls.findIndex(
        (group) =>
          group.external_reference_id === order_reference_number &&
          group.identifier === identifier &&
          group.orderReference === order_info.id
      )
      if (existingGroupIndex !== -1) {
        filteredGroupedDocumentUrls[existingGroupIndex].document_urls.push({
          location: 'LOCATION',
          name: selectedFile.name,
          size: selectedFile.size
        })
      } else {
        filteredGroupedDocumentUrls.push({
          document_type: selectedFile.type,
          document_urls: [
            {
              location: 'LOCATION',
              name: selectedFile.name,
              size: selectedFile.size
            }
          ],
          identifier,
          external_reference_id: order_reference_number,
          orderReference: order_info.id
        })
      }
      return filteredGroupedDocumentUrls
    })
  }

  useEffect(() => {
    handleFilterUpdate('terms', selectedTerm);
  }, [selectedTerm]);


  useEffect(() => {
    if (nonStips?.length > 0) {
      const newArray: any = nonStips.map((item: any) => ({
        ...item,
        content_type: 'Application'
      }))
      setDocuments(newArray)
    }
  }, [nonStips])

  useEffect(() => {
    if (filteredStipulations?.length > 0) {
      const filteredNonStips = (nonStips as any)?.filter(
        (stips: any) => stips.document_status?.toLowerCase() !== 'draft'
      )
      const tempArr = [...filteredStipulations]
      for (const stips of tempArr) {
        if (
          stips.stipulation_type?.toLowerCase() === StipulationType.NON_FINANCIAL?.toLowerCase()
        ) {
          filteredNonStips?.find((st: any) => {
            if (st.stipulation_code === stips.stipulation_code && st.document_url !== null) {
              stips.fulfilled = true
            } else {
              stips.fulfilled = false
            }
          })
        }
      }
      setFilteredStipulations(tempArr)
    }
  }, [nonStips])

  useEffect(() => {
    const tempStips: IStipulationResponse[] = []
    stipulationsByLender?.filter((stip: any) =>
      creditDecision?.stipulations?.forEach((el: any) => {
        if (stip.stipulation_code === el?.stipulation_code) {
          stip.fulfilled = false
            ; (stip.value_to = el.value_to), (stip.value_from = el.value_from)
          tempStips.push(stip)
        }
      })
    )
    setFilteredStipulations(tempStips)
  }, [stipulationsByLender, creditDecision])

  useEffect(() => {
    getStipsByLenderId(appState.state.tenant, {
      onSuccess: (res: any) => {
        setStipulationsByLender(res)
      }
    })
  }, [order_info.lender_id])

  useEffect(() => {
    if (order_info?.id && documents.length === 0 && filteredStipulations?.length > 0) {
      getNonStips(order_info?.id,
        {
          onSuccess(nonStipData: any) {
            setNonStips(nonStipData)
          },
          onError() {
            setNonStips([] as any)
            handleCreateDocument([])
          }
        }
      )
    }
  }, [order_info, documents, filteredStipulations])

  const handleCreateDocument = (nonStipData) => {
    if (nonStipData?.length === 0 && filteredStipulations?.length > 0 && order_info) {
      const tempArr = [] as any
      filteredStipulations
        ?.filter((stip) => stip.stipulation_type?.toLowerCase() === StipulationType.NON_FINANCIAL)
        ?.forEach((stip) => {
          const data = {
            customer_reference_id: order_info?.customer_info?.reference_id,
            external_reference_id: order_info?.id,
            document_upload_indicator: false,
            stipulation_code: stip.stipulation_code,
            document_type: stip.description,
            created_by: 2847
          }
          tempArr.push(data)
        })
      tempArr?.forEach((obj: any) => {
        createDocument(obj)
      })
    }
  }

  const handleCloseDialog = () => {
    setShowPopup(false);
    onCloseDialog();
  };

  const onCrossFile = (_identifier: string, _order_id: any, index: any, stipulation_index: any) => {
    const newArray = [...documents]
    newArray.splice(index, 1)
    setDocuments(newArray)
    setGroupedDocumentUrls(prevGroupedDocumentUrls => {
      const newGroupedDocumentUrls = [...prevGroupedDocumentUrls];
      newGroupedDocumentUrls[stipulation_index].document_urls.splice(index, 1);
      return newGroupedDocumentUrls;
    });
  }

  useEffect(() => {
    const groupedUrls: {
      [key: string]: {
        document_type: string
        document_urls: Array<{
          location: string
          name: string
          size: number
        }>
        identifier: string
        external_reference_id: any
        orderReference: any
      }
    } = {}
    nonStips?.forEach((stipulation) => {
      setStipCode(stipulation.stipulation_code)
      const {
        document_type,
        document_url,
        identifier,
        external_reference_id,
        document_name,
        document_size
      } = stipulation
      const existingGroup = groupedUrls[document_type]
      if (existingGroup) {
        existingGroup.document_urls.push({
          location: document_url,
          name: document_name,
          size: document_size
        })
      } else {
        groupedUrls[document_type] = {
          document_type,
          document_urls: [
            {
              location: document_url,
              name: document_name,
              size: document_size
            }
          ],
          identifier,
          external_reference_id,
          orderReference: order_info.id
        }
      }
    })
    const groupedArray = Object.values(groupedUrls)
    if (groupedArray.length > 0) {
      setGroupedDocumentUrls(groupedArray)
    }
  }, [nonStips])

  useEffect(() => {
    setFilters({ ...filters, documents: groupedDocumentUrls })


  }, [groupedDocumentUrls])

  const handleDownPaymentBlur = (value) => {
    const downPayment = parseFloat(value?.replace(/,/g, ''));
    orderDispatch(updateDownPayment(downPayment))
    setFilters({ ...filters, downPayment: downPayment });
  };

  useEffect(() => {
    const isFinancedAmountValid = order?.net_finance_amount >= stipulationFinance?.value_from && order?.net_finance_amount <= stipulationFinance?.value_to;
    setIsDownPaymentValid(isFinancedAmountValid)
  }, [order?.net_finance_amount])
  const [termsFrom, setTermsFrom] = useState('');
  const [termsTo, setTermsTo] = useState('');
  const stipulationTerm = creditDecision?.stipulations?.find(stip => stip.stipulation_code === "ST003");
  const stipulationFinance = creditDecision?.stipulations?.find(stip => stip.stipulation_code === "ST001");

  const nonFinancialStipulations = filteredStipulations?.filter(
    (stip) => stip.stipulation_type?.toLowerCase() === StipulationType.NON_FINANCIAL
  )

  useEffect(() => {
    if (setupData && setupData?.['contract-terms'] && stipulationTerm) {
      const filteredTerms = setupData?.['contract-terms']?.filter(term => term?.term >= stipulationTerm?.value_from && term?.term <= stipulationTerm?.value_to);
      if(filteredTerms) {
        setTermsFrom(stipulationTerm?.value_from)
        setTermsTo(stipulationTerm?.value_to)
        setFilteredContractTerms(filteredTerms);
      }
    }
  }, [setupData, stipulationTerm]);

  useEffect(() => {
    if (isFlowExecuted) {
      handleCloseDialog()
    }
  }, [isFlowExecuted])

  return (
    <Popup>
      <Dialog
        disablePortal
        theme={theme}
        size="sm"
        title={PLACEHOLDERS.SUBMIT_ORDER_EDIT_ORDER}
        open={showPopup}
        onCloseDialog={handleCloseDialog}
        customFooter={
          <>
            <Box theme={theme} className="footer-full-width-btns">
              <Button
                theme={theme}
                secondary
                text={PLACEHOLDERS.SUBMIT_ORDER_VIEW_ORDER_SUMMARY}
                onClick={() => setPaymentBreakdownPopup(true)} 
                
                />
              <Button
                theme={theme}
                primary
                disabled={isReSubmitEnabled || loading}
                text={PLACEHOLDERS.SUBMIT_ORDER_RE_SUBMIT_ORDER}
                onClick={reSubmitOrder}
                startIcon={loading && <img src={DisableLoader} alt="Loader" />}
              />
            </Box>
          </>
        }
      >
        <PageWrap theme={theme} className="edit-dialog">
          {!!filteredContractTerms?.length && (
            <Stack bgLight paddingXs={3} paddingMd={3}>
              <Box theme={theme} className="title-with-icon">
                <Icon
                  className={`icon ${!!selectedTerm ? 'icon-green' : 'icon-error'}`}
                  name={!!selectedTerm ? "GreenCheckIcon" : "InfoErrorIcon"}
                />
                <Typography theme={theme} component={'small'} variant='caption' className="text-muted">
                  {`${PLACEHOLDERS.SUBMIT_ORDER_TERM_BETWEEN} ${termsFrom} ${PLACEHOLDERS.AND_TEXT_LOWERCASE} ${termsTo} ${PLACEHOLDERS.MONTHS_LOWERCASE_TEXT}`}
                </Typography>
              </Box>
              <CjButton>
                <SelectionButton
                  buttonItems={filteredContractTerms?.map((term, index) => ({
                    title: `${term.term} ${PLACEHOLDERS.MONTHS_TEXT}`,
                    isSelected: term?.term === (selectedTerm),
                    onClick: () => {
                      setSelectedTerm(term?.term);
                      setFilters({ ...filters, terms: term.term });
                      orderDispatch(updateContractTerm(term?.term))
                    }
                  }))}
                />
              </CjButton>
            </Stack>
          )}
          {!!stipulationFinance && (
            <Stack bgLight paddingXs={3} paddingMd={3} marginTopMd={2} marginTopXs={2}>
              <Box theme={theme} className="title-with-icon">
                <Icon className={`icon ${isDownPaymentValid ? 'icon-green' : 'icon-error'}`}
                  name={isDownPaymentValid ? "GreenCheckIcon" : "InfoErrorIcon"} />
                <Typography theme={theme} component={'small'} variant='caption' className="text-muted">
                  {`${PLACEHOLDERS.SUBMIT_ORDER_FINANCED_AMOUNT_BETWEEN}
                  ${defaultCurrency}${stipulationFinance?.value_from?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                    ${PLACEHOLDERS.TERM_AND}
                   ${defaultCurrency}${stipulationFinance?.value_to?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                    `}
                </Typography>
              </Box>
              <Textbox>
                <Controller
                  name="down_payment"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      placeholder={'000.00'}
                      type="decimal"
                      startAdornment={<span className="symbol">{defaultCurrency}</span>}
                      label={PLACEHOLDERS.PAYMENT_DOWN_PAYMENT_FIELD_LABEL}
                      {...field}
                      value={field.value || downPaymentValue}
                      masking
                      maskDecimalScale={2}
                      maskNumeric
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors();
                      }}
                      onBlur={(e) => handleDownPaymentBlur(e?.target?.value)}
                    />
                  )}
                />
              </Textbox>
            </Stack>
          )}
          <Stack bgLight paddingXs={3} paddingMd={3} marginTopMd={2} marginTopXs={2}>
            {nonFinancialStipulations?.map((stipulation, index) => {
              const isDocumentUploaded = groupedDocumentUrls.find(
                (group) => group.document_urls.length > 0
                  && group.document_urls.some(doc => doc.location !== null)
                  && group.document_type === stipulation.description
              );
              return (
                <React.Fragment key={index}>
                  <Box theme={theme} className="title-with-icon">
                    <Icon
                      className={`icon ${!!isDocumentUploaded ? 'icon-green' : 'icon-error'}`}
                      name={!!isDocumentUploaded ? 'GreenCheckIcon' : 'InfoErrorIcon'}
                    />
                    <Typography theme={theme} component={'small'} variant='caption' className="text-muted">
                      {isDocumentUploaded ? `${PLACEHOLDERS.THE} ${stipulation.description} ${PLACEHOLDERS.DOCUMENT_UPLOADED_SUCCESSFULL}` : `${PLACEHOLDERS.THE} ${stipulation.description} ${PLACEHOLDERS.IS_NOT_UPLOAD}. ${PLACEHOLDERS.PLEASE_UPLOAD}`}
                    </Typography>
                  </Box>
                </React.Fragment>
              );
            })}
            {groupedDocumentUrls?.map((stipulation: any, index: any) => (
              <Fragment key={index}>
                <FileUploader>
                  <FileUpload
                    id={stipulation.identifier}
                    theme={theme}
                    width="300"
                    inlinePreview={false}
                    allowMultiple={true}
                    hoverLabel={<>{`${PLACEHOLDERS.FILE_DRAG_DROP_HOVER_LABEL_1} `}<span className='text-primary'>{PLACEHOLDERS.FILE_DRAG_DROP_HOVER_LABEL_2}</span></>}
                    height="243px"
                    backgroundColor="white"
                    onDrop={(e) => uploadDocumentHandler(e, stipulation.identifier, stipulation.external_reference_id, stipulation.document_type)}
                    onChange={(e) => uploadDocumentHandler(e, stipulation.identifier, stipulation.external_reference_id, stipulation.document_type)} />
                </FileUploader>
                <Typography
                  className="file-caption"
                  theme={theme}
                  component="p"
                  variant="body2"
                />
                {stipulation.document_urls.find((item: any) => item.location !== null) &&
                  stipulation.document_urls.length > 0 && (
                    <FileUploadPreview
                      files={stipulation?.document_urls.map((doc, docIndex) => ({
                        key: `${docIndex}`,
                        file: { location: doc.location, name: doc.name, size: doc.size },
                        onRemoveFile: () => onCrossFile(stipulation.identifier, stipulation.external_reference_id, docIndex, index)
                      }))}
                      theme={theme}
                    />
                  )}
              </Fragment>
            ))}
          </Stack>
          {openPaymentBreakdownPopup && (
            <PaymentBreakDownPopup
              defaultCurrency={defaultCurrency}
              onCloseDialog={() => setPaymentBreakdownPopup(false)}
              order_info={order}
            />
          )}
        </PageWrap>
      </Dialog>
    </Popup>
  );
};