import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const JourneyFooterWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.journey-footer-wrap': {
    marginTop: 40,
    position: 'sticky',
    bottom: 8,
    zIndex: 9,
    marginLeft: -16,
    marginRight: -16,
    '.stack-wrap': {
        display: 'flex',
        alignItems: 'center',
        '.left-area': {
            flexGrow: 1,

            a: {
              display: 'inline-block'
            }
        },
        '.action-area': {
            flexShrink: 0
        },
        '.btn': {
            '&.btn-primary': {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.grey[900],
                borderColor: theme.palette.common.white,
                '&:hover': {
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.grey[900],
                    borderColor: theme.palette.common.white, 
                },
                '&.with-icon': {
                  'svg': {
                    'path': {
                      stroke: theme.palette.grey[900]
                    }
                  },
                  '&:hover': {
                    'svg': {
                      'path': {
                        stroke: theme.palette.grey[900]
                      }
                    },
                  }
                }
            }
        }
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: -24,
      marginRight: -24,
    },
    '&.no-footer': {
      display: 'none'
    },
    '.footer': {
      display: 'block'
    }
  }
}))
