import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CreateNewOrderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.add-new-card-wrap': {
    display: 'flex',
    width: '100%',
    minHeight: '395px',
    maxHeight: '100%',
    padding: 24,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    borderRadius: 16,
    border: '1px dashed',
    borderColor: theme.palette.grey[100],
    cursor: 'pointer',
    svg: {
      width: '30.3px',
      height: '33.2px',
      path: {
        stroke: theme.palette.grey[600]
      }
    }
  }
}))
