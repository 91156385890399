import { useTheme } from '@mui/material'
import { Box, Button, Icon, Menu, Typography } from '@ntpkunity/controls'
import { PreQualificationCardProps } from './pre-qualification-card.props'
import { PreQualificationCardWrap } from './pre-qualification-card.style'
import clsx from 'clsx'
import { Status } from "constants/enums"
import QRCode from 'react-qr-code'

export const PreQualificationCard = ({ status, refNo, items, btnText, onClick }: PreQualificationCardProps) => {
    const theme = useTheme()

    return (
        <PreQualificationCardWrap theme={theme} className='product-order-card'>
            <Box theme={theme} className='card-head'>
                {status && <Typography theme={theme} variant='caption' component={'small'} display={'block'} className={clsx({ 'fw-sbold status': true, 'in-progress': (status == Status.Qualified), 'cancelled': (status == Status.Expired||status == Status.REJECTED) })}> {status}</Typography>}
                <Box theme={theme} className='qr-img'>
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={`Ref No: ${refNo}`}
                        viewBox={`0 0 256 256`}
                    />
                </Box>
                {refNo &&
                    <Typography theme={theme} variant='caption' component={'small'} className='text-muted fw-sbold ref'>
                        Ref No: {refNo}
                    </Typography>
                }
            </Box>
            <Box theme={theme} className='card-content'>
                {items &&
                    <ul>
                        {items.map((item, index) => (
                            <li key={index}>
                                <Typography theme={theme} variant='caption' component={'small'} className='fw-medium'>{item.title}</Typography>
                                <Typography theme={theme} variant='caption' component={'small'} className='text-muted'>{item.value}</Typography>
                            </li>
                        ))}
                    </ul>
                }
            </Box>
            {onClick &&
                <Box theme={theme} className='card-action'>
                    <Menu
                        theme={theme}
                        options={[
                            {
                                optionText: 'Print',
                                optionkey: 'print',
                                optionValue: 'print',
                            },
                            {
                                optionText: 'Email',
                                optionkey: 'email',
                                optionValue: 'email',
                            },
                            {
                                optionText: 'Delete',
                                optionkey: 'delete',
                                optionValue: 'delete',
                            }
                        ]}
                        handleOptionClick={() => { }}
                        render={(onMenuSelection) => (
                            <Button
                                secondary
                                theme={theme}
                                iconText={<Icon name="MoreIcon" />}
                                ariaLabel='More Icon'
                                onClick={onMenuSelection}
                            />
                        )}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    />
                    <Button theme={theme} primary fullWidth onClick={onClick} text={btnText} />
                </Box>
            }
        </PreQualificationCardWrap>

    )
}