import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const QuestionStackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.question-stack-wrap': {
    backgroundColor: theme.palette.common.white,
    padding: '24px',
    borderRadius: 16,
    border: '2px solid transparent',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    cursor: 'pointer',
    '.stack-row': {
      display: 'flex',
      justifyContent: 'space-between',
      '.action-area': {
        '.MuiButtonBase-root': {
          '&.btn.with-icon.icon-only': {
            padding: 0
          }
        },
        '.custom-checkbox-wrap': {
          '.u-custom-control-label': {
            margin: 0,
            '.custom-checkbox': {
              margin: 0
            },
          },
        },
        '.MuiFormGroup-root': {
          '.u-switch-label': {
            flexDirection: 'row-reverse',
            gap: '8px'
          }
        },
        '.MuiTypography-root': {
          whiteSpace: 'nowrap'
        }
      },
      '.testing': {
        flexWrap: 'wrap',
        gap: 8,
        '.title': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: 'calc(100% - 154px)',
          [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 40px)',
          }
        },
      },
      [theme.breakpoints.down('md')]: {
        '&.stack-column': {
          flexDirection: 'row',
          '.action-area': {
            marginTop: 0,
          }
        },
        '&.d-flex': {
          flexDirection: 'row',
          '.action-area': {
            marginTop: '0px',
          }
        },
        '&.d-column': {
          flexDirection: 'column',
          '.testing': {
            flexDirection: 'row',
            marginBottom: 8,
          }
        },
        '.action-area': {
          marginTop: '0px',
          '.u-switch-label': {
            justifyContent: 'space-between',
            '.MuiTypography-root': {
              margin: 0
            },
          }
        },
        '.testing': {
          flexDirection: 'column',
          gap: 8,
        }
      },
      [theme.breakpoints.down('lg')]: {
          '&.d-column': {
            flexDirection: 'column',
          },
          '.action-area': {
            marginTop: '0px',
            '.u-switch-label': {
            justifyContent: 'space-between',
            '.MuiTypography-root': {
              margin: 0
            },
          }
        },
      }
    },


  }
}))
