import {useTheme} from '@mui/material';
import {Box, Button, Menu, Typography} from '@ntpkunity/controls';
import React, {useEffect, useState} from 'react';
import {ChooseDateTimeWrap} from './choose-date-time.style';
import {CustomCalendar, SelectionButton, Stack} from '@components';
import {Controller} from 'react-hook-form';
import {TimeZone, timeSlots} from 'constants/enums';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {getDefaultTimeZone} from "../../helpers/methods";

dayjs.extend(utc);
dayjs.extend(timezone);

export const ChooseDateTime = (props) => {
    const {scheduleOrderForm, setSelectedTimeZone, selectedTimeZone} = props;
    const {control} = scheduleOrderForm;
    const theme = useTheme();
    const [disabledTimeSlots, setDisabledTimeSlots] = useState([]);

    const timeZoneOptions = Object.keys(TimeZone).map((key) => ({
        optionText: key.replace(/_/g, ' '), optionKey: TimeZone[key], optionValue: TimeZone[key],
    }));

    useEffect(() => {
        if (!selectedTimeZone || !selectedTimeZone.value || !selectedTimeZone.name) {
            const defaultTimeZone = getDefaultTimeZone();
            setSelectedTimeZone(defaultTimeZone);
        }
    }, [selectedTimeZone])

    const getCurrentTimeInSelectedTimeZone = () => {
        if (!selectedTimeZone?.value) {
            return;
        }
        return dayjs().tz(selectedTimeZone.value);
    };
    const calculateDisabledTimeSlots = () => {
        if (!selectedTimeZone?.value) {
            return;
        }
        const selectedDate = scheduleOrderForm?.getValues("selectedDate");
        const currentTime = getCurrentTimeInSelectedTimeZone();
        const selectedDateTime = selectedDate ? dayjs(selectedDate).tz(selectedTimeZone.value).startOf('day') : dayjs().tz(selectedTimeZone.value).startOf('day');

        const disabledSlots = timeSlots.map((time) => {
            const [timeString, modifier] = time.split(' ');
            const [hours, minutes] = timeString.split(':').map(Number);
            const adjustedHours = modifier === 'PM' && hours < 12 ? hours + 12 : (modifier === 'AM' && hours === 12 ? 0 : hours);
            const slotTime = selectedDateTime.set('hour', adjustedHours).set('minute', minutes);
            return slotTime.isBefore(currentTime);
        });

        setDisabledTimeSlots(disabledSlots);
    };

    useEffect(() => {
        calculateDisabledTimeSlots();
    }, [selectedTimeZone, scheduleOrderForm.getValues("selectedDate")]);

    return (<ChooseDateTimeWrap theme={theme} className='choose-date-time-wrap'>
            <Stack bgLight paddingMd={3} paddingXs={3}>
                <Typography theme={theme} variant='subtitle1' component={'p'} mb={1}>Choose Date</Typography>
                <Box theme={theme} mt={3}>
                    <CustomCalendar scheduleOrderForm={scheduleOrderForm}/>
                </Box>
            </Stack>
            <Box theme={theme} mt={2}>
                <Stack bgLight paddingMd={3} paddingXs={3}>
                    <Typography theme={theme} variant='subtitle1' component={'p'}>Choose Time</Typography>
                    <Box theme={theme} display={'flex'} flexWrap={'wrap'} alignItems={'baseline'} gap={0.3} mb={1}>
                        <Typography theme={theme} variant='caption' component={'small'} className='text-muted'>Your
                            current time zone is</Typography>
                        <Typography theme={theme} variant='caption' component={'small'} className='fw-medium'>
                            {selectedTimeZone ? (`(GMT${selectedTimeZone.value}) ${selectedTimeZone.name}`) : ('Not Set')}
                        </Typography>
                        <Box theme={theme} className='custom-menu'>
                            <Menu
                                theme={theme}
                                options={timeZoneOptions.map(option => ({
                                    optionValue: option.optionValue,
                                    optionText: `(GMT${option.optionValue}) ${option.optionText}`
                                }))}
                                handleOptionClick={(e, key, value) => {
                                    const selectedOption = timeZoneOptions.find(option => option.optionValue === value);
                                    if (selectedOption) {
                                        const newTimeZone = {
                                            value: selectedOption.optionValue, name: selectedOption.optionText
                                        };
                                        setSelectedTimeZone(newTimeZone);
                                    }
                                }}
                                render={(onMenuSelection) => (<Button
                                        defaultBtn
                                        theme={theme}
                                        text={'Change Time Zone'}
                                        ariaLabel='More Icon'
                                        onClick={onMenuSelection}
                                    />)}
                                anchorOrigin={{
                                    vertical: "bottom", horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top", horizontal: "right"
                                }}
                            />
                        </Box>
                    </Box>
                    <Box theme={theme} mt={3} className="choose-time">
                        <Controller
                            name="selectedTime"
                            control={control}
                            render={({field}) => (<SelectionButton
                                    buttonItems={timeSlots.map((time, index) => ({
                                        title: time, onClick: () => {
                                            if (!disabledTimeSlots[index]) {
                                                field.onChange(time);
                                            }
                                        }, isSelected: field.value === time, disabled: disabledTimeSlots[index]
                                    }))}
                                />)}
                        />
                    </Box>
                </Stack>
            </Box>
        </ChooseDateTimeWrap>);
};
