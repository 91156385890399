import { Months } from "constants/analytics";

export const formatDateStringPreQualification = (
    dateString: string = '',
    includeTime: boolean = false
  ) => {
    let date = new Date(dateString)
    if (isNaN(date.getTime())) {
      date = new Date()
    }
    const day = String(date?.getDate())?.padStart(2, '0')
    const month = Months[date.getMonth()];
    const year = date?.getFullYear()
    if (!includeTime) {
      return `${month}/${day}/${year}`
    }
    const hours = date?.getHours()
    const minutes = String(date?.getMinutes())?.padStart(2, '0')
    const isAM = hours < 12
    const formattedHours = String(hours % 12 || 12)?.padStart(2, '0')
    const period = isAM ? ' AM' : ' PM'
    return `${month}/${day}/${year} ${formattedHours}:${minutes}${period}`
  }
export const validation_response = (data: any) => {
    const parsedNestedData = JSON.parse(data.data);
    const { status, ...details } = parsedNestedData;

    let title: string;
    let subTitle: string;
    let state: boolean
    switch (status) {
        case "reject":
            state = false,
            title = 'Sorry! We cannot proceed with your pre-qualification';
            subTitle = 'Thank you for your interest.At this time, we’re unable to qualify you for financing.However, we encourage you to explore other options or reach out to our team for further assistance.We’re here to help you find a solution that meets your needs.You can also visit us in person for more support.';
            break;
        case "accept":
            const formattedDate = formatDateStringPreQualification(details.expiration,false)
            return {
                state: true,
                refNo: data.id,
                title: 'Congratulations! You are qualified',
                subTitle: `Your credit score is <span class='fw-medium text-dark'>${details.score}</span> and you are eligible for the financing up to <span class='fw-medium text-dark'>${details.finance.toLocaleString()}</span>.This qualification will expire on <span class='fw-medium text-dark'> ${formattedDate}</span>.You can access this result in your profile.`
            }
    }

    return {
        state: state,
        refNo: data.id,
        title: title,
        subTitle: subTitle,
        items: []
    };
};
export const view_response = (data: any) => {
    let title: string;
    let subTitle: string;
    let state: boolean
    switch (data.status) {
        case "reject":
            state = false,
            title = 'Sorry! We cannot proceed with your pre-qualification';
            subTitle = 'Thank you for your interest.At this time, we’re unable to qualify you for financing.However, we encourage you to explore other options or reach out to our team for further assistance.We’re here to help you find a solution that meets your needs.You can also visit us in person for more support.';
            break;
        case "accept":
            const formattedDate = formatDateStringPreQualification(data.expiration,false)
            state = true,
            title = 'Congratulations! You are qualified',
            subTitle = `Your credit score is <span class='fw-medium text-dark'>${data.score}</span> and you are eligible for the financing up to <span class='fw-medium text-dark'>${data.finance}</span>.This qualification will expire on <span class='fw-medium text-dark'> ${formattedDate}</span>.You can access this result in your profile.`
            break;
    }
    return {
        state: state,
        refNo: data.id,
        title: title,
        subTitle: subTitle,
        items: []
    };
}