import React, {useEffect, useState} from "react";
import {TradeInJourneyPopup} from "@libraries";
import {popupConfigurations} from "./trad-in-pop.configuration"
import {useForm} from "react-hook-form";
import {updateOrderTradeIn, useOrderContext} from "../../(order-management)/order/order-context-provider";
import {IOrderTradeIn} from "../../../interfaces/order-trade-in";
import {TradeInJourneySteps} from "../../../constants/enums";
import {createQuestionMap, mapTradeInType, reverseMapTradeInType, transformFormData} from "../../../helpers/methods";
import { useAppContext } from "@app/context-provider";
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags,PageType } from 'constants/enums';
import { user_session } from '@constants'
import { useParams } from 'react-router-dom'
import { useGetQuestions } from "hooks/trade-in-management";
import {useFormContext} from "../../(order-management)/order/form-context-provider";
import {useCustomDirtyForm} from "../../../hooks/order-management";

export const TradeInPopup = ({isPopupOpen, closePopup, initialPopupType, setInitialPopupType, resetForm, handleRemove}) =>{
    const { state: {order} , dispatch: orderDispatch } = useOrderContext()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders
    const { mutate: getQuestion, data: questionData, isLoading: gettingQuestion, isSuccess: questionSuccess } = useGetQuestions(appState?.tenant, appState?.slug);


    const [currentPopupType, setCurrentPopupType] = useState(mapTradeInType(order?.order_tradein?.trade_in_type) || initialPopupType)
    const { tenant } = useParams()
    const user_profile = JSON.parse(localStorage.getItem("user_profile"))
    const { mutate: createTags } = useTags();
    const [previousPopupType, setPreviousPopupType] = useState({previousState: {previousState: null, currentState: null}, currentState: initialPopupType})
    const [Component, setComponent] = useState(null)
    const [title, setTitle] = useState(null)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [hasBackArrow, setHasBackArrow] = useState(false)
    const [tradeInType, setTradeInType] = useState(mapTradeInType(order?.order_tradein?.trade_in_type) || initialPopupType)
    const [makeModelYearTrim, setMakeModelYearTrim] = useState({
        "make_id": null,
        "make_name": "",
        "model_id": null,
        "model_name": "",
        'model_year_id':null,
        'year_id': null,
        "platform_vehicle_type": "Car"
    })
    const [selectedOptions,setselectedOptions] =useState([])
    const [progressValue,setProgressValue]=useState(0)
    const [currentStep, setCurrentStep] = useState(1);
    const[showProgressBar,setShowProgressBar]=useState(true)

    const [tradeIn, setTradeIn] = useState<IOrderTradeIn>(order?.order_tradein);
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        clearErrors,
        reset,
        setError,
        trigger, unregister, watch,
        formState: { errors, isValid } } =
        useForm({ defaultValues: tradeIn, mode: "onChange", });

    const { formStates } = useFormContext();

    useCustomDirtyForm('TradeIn', watch, isValid);

    useEffect(() => {

          if(tradeIn){
              reset(formStates?.['TradeIn']?.data || tradeIn);
          }

    }, [tradeIn]);

    useEffect(() => {
        setComponent(popupConfigurations[currentPopupType].component)
        setTitle(popupConfigurations[currentPopupType].title ? PLACEHOLDERS.TRADE_IN_ESTIMATION_PROMPT : '')
        setHasBackArrow(popupConfigurations[currentPopupType].hasBackButton)
        setIsButtonDisabled(popupConfigurations[currentPopupType].hasContinueButton)
    }, [currentPopupType]);

    useEffect(() => {
        setTradeIn(order?.order_tradein)

    }, [order]);
    useEffect(()=>{
        const event: Event = {
            session: user_session,
            slug:tenant,
            event_name:Tags.POP_TRADE_ESTIMATE,
            user_id: user_profile?.user_id,
            page_type:PageType.TradeIn
            }
            createTags(event)
    },[])

    useEffect(() => {

        setPreviousPopupType( {previousState: {previousState: null, currentState: null}, currentState: initialPopupType})
        setCurrentPopupType(initialPopupType)

    },[initialPopupType])
    useEffect(() => {
        getQuestion({
            event_name: "trade_in_event_3",
            company_id: appState.tenant
        })
    }, []);


    const setNextPopup = (next, edit) => {
        if ([TradeInJourneySteps.ProvideManualEstimatePopup, TradeInJourneySteps.VinOnePopup, TradeInJourneySteps.MakeModelPopupOne].includes(next)) {
            setTradeInType(next)
        }
        if(edit){
            setPreviousPopupType({
                previousState: initialPopupType == TradeInJourneySteps.AddTradeInPopup ? {previousState: {previousState: null, currentState: null}, currentState: TradeInJourneySteps.AddTradeInPopup}: {previousState: null, currentState: null},
                currentState:  next
            });
        }
        else{
            setPreviousPopupType(prevState => ({
                previousState: {...prevState},
                currentState:  next || popupConfigurations[currentPopupType].nextComponent
            }));
        }
        setCurrentPopupType(next || popupConfigurations[currentPopupType].nextComponent);

    }
    function getTotalSteps(tradeInType) {
        switch (tradeInType) {
            case TradeInJourneySteps.ProvideManualEstimatePopup:
                return 3;
            case TradeInJourneySteps.VinOnePopup:
                return 7;
            case TradeInJourneySteps.MakeModelPopupOne:
                return 8;
            default:
                return 0
        }
    }
    const goToBackPopup = () => {
        if (currentStep > 1) {
            setCurrentStep(prevStep => prevStep - 1);
            const value = ((currentStep - 1) / getTotalSteps(tradeInType)) * 100;
            setProgressValue(value);
        }
        setCurrentPopupType(previousPopupType.previousState.currentState)
        setPreviousPopupType({...previousPopupType.previousState})

    }


    useEffect(() => {
        if(resetForm){
            reset()
            setPreviousPopupType({previousState: {previousState: null, currentState: null}, currentState: TradeInJourneySteps.AddTradeInPopup})
            setCurrentPopupType( TradeInJourneySteps.AddTradeInPopup)
            setselectedOptions([])
        }
    }, [resetForm]);

    const onSubmitTradeIn = (data)  => {
        const questionMap =  createQuestionMap(questionData);
        const questionAnswerList = transformFormData(data, questionMap);
        const event: Event = {
            session: user_session,
            slug:tenant,
            event_name:Tags.MILEAGE,
            user_id: user_profile?.user_id,
            page_type:PageType.TradeIn,
            data:data
            }
            createTags(event)
        if (data?.model && data.model.split(',').length >= 2) {
            const [year, make, model] = data?.model.split(',').map(value => value.trim());
            data['year'] = parseInt(year)
            data['make'] = make
            data['model'] = model
        }

        if(previousPopupType.currentState !== TradeInJourneySteps.FinalPopup){
            setNextPopup(false, false)
        }
        else{

            setPreviousPopupType({previousState: {previousState: null, currentState: null}, currentState: initialPopupType})
            setCurrentPopupType(initialPopupType)
            closePopup()
        }

        data['question_answers']=questionAnswerList

    }

    const onProceedWithTradeIn = (data) => {
        if (!data['trade_in_type'] || data['trade_in_type']!== initialPopupType) {
            data['trade_in_type'] = reverseMapTradeInType(tradeInType);
            setInitialPopupType(tradeInType);

        }
        if (data?.model && data.model.split(',').length >= 2) {
            const [year, make, model] = data?.model.split(',').map(value => value.trim());
            data['year'] = parseInt(year)
            data['make'] = make
            data['model'] = model
        }

        data['payoff_amount'] = parseInt(data['payoff_amount'] )
        data['mileage'] = parseInt(data['mileage'])

        orderDispatch(updateOrderTradeIn(data))
        if(previousPopupType.currentState !== TradeInJourneySteps.FinalPopup){
            setNextPopup(false, false)
        }
        else{

            setPreviousPopupType({previousState: {previousState: null, currentState: null}, currentState: tradeInType})
            setCurrentPopupType(tradeInType)
            closePopup()
        }
        const questionMap =  createQuestionMap(questionData);
        const questionAnswerList = transformFormData(data, questionMap);
        data['question_answers']=questionAnswerList

    }

    const messages = {
        label: {
            yearMakeModel: PLACEHOLDERS.TRADE_IN_POPUP_YEAR_MAKE_MODEL_LABEL,
            EstimatedValue: PLACEHOLDERS.TRADE_IN_POPUP_ESTIMATED_VALUE_LABEL,
            Miles: PLACEHOLDERS.TRADE_IN_POPUP_MILES_DRIVEN_PROMPT_LABEL,
            Condition: PLACEHOLDERS.TRADE_IN_POPUP_MILES_DRIVEN_PROMPT_LABEL,
            PayOffAmount: PLACEHOLDERS.TRADE_IN_POPUP_ESTIMATED_PAYOFF_AMOUNT_LABEL,
            LenderName: PLACEHOLDERS.TRADE_IN_POPUP_LENDER_NAME_LABEL,
            ContractNumber: PLACEHOLDERS.TRADE_IN_MILES_POPUP_CONTRACT_NUMBER,
            LoanLeaseBalance: PLACEHOLDERS.TRADE_IN_POPUP_LOAN_LEASE_BALANCE_LABEL,
            PaymentType: PLACEHOLDERS.TRADE_IN_POPUP_PAYMENT_TYPE_LABEL,
            VehicleType: PLACEHOLDERS.TRADE_IN_POPUP_PAYMENT_TYPE_LABEL,
            Vin: PLACEHOLDERS.TRADE_IN_POPUP_VIN_LABEL,
            Year: PLACEHOLDERS.TRADE_IN_POPUP_YEAR_LABEL,
            Make: PLACEHOLDERS.TRADE_IN_POPUP_MAKE_LABEL,
            Model: PLACEHOLDERS.TRADE_IN_POPUP_MODEL_LABEL,


        },
        name: {
            yearMakeModel: 'model',
            EstimatedValue: 'trade_in_amount',
            Miles: 'mileage',
            Condition: 'condition',
            PayOffAmount: 'payoff_amount',
            LenderName: 'lender_name',
            LoanLeaseBalance: 'loan_lease_balance',
            PaymentType: 'payment_type',
            CustomerTitles: 'is_customer_titled',
            Vin:'vin',
            Trim:'trim',
            Year: 'year',
            Make: 'make',
            Model: 'model',
            AssetImage: 'asset_image',
            ContractNumber:'lender_contract_number'
        },
        validation: {
            yearMakeModel: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_YEAR_MAKE_MODEL_REQUIRED
            },
            vin: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_VIN_REQUIRED,
                minLength: PLACEHOLDERS.TRADE_IN_POPUP_VIN_LENGTH_ERROR,
                maxLength: PLACEHOLDERS.TRADE_IN_POPUP_VIN_LENGTH_ERROR,
                invalid: PLACEHOLDERS.TRADE_IN_POPUP_INVALID_VIN,
                notFound: PLACEHOLDERS.TRADE_IN_POPUP_NOT_FOUND
            }
            ,
            EstimatedValue: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_ESTIMATED_VALUE_REQUIRED
            },
            Miles: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_MILES_REQUIRED
            }
            ,
            Condition: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_CONDITION_REQUIRED
            }
            ,
            PayOffAmount: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_PAYOFF_AMOUNT_REQUIRED
            }
            ,
            LenderName: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_LENDER_NAME_REQUIRED
            }
            ,
            LoanLeaseBalance: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_LOAN_LEASE_BALANCE_REQUIRED
            }
            ,
            PaymentType: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_PAYMENT_TYPE_REQUIRED
            }
            ,
            VehicleType: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_PAYMENT_TYPE_REQUIRED
            }
            ,
            Trim: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_TRIM_REQUIRED,
                invalid: PLACEHOLDERS.TRADE_IN_POPUP_TRIM_ERROR
            },
            Year: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_YEAR_REQUIRED
            },
            Make: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_MAKE_REQUIRED
            },
            Model: {
                required: PLACEHOLDERS.TRADE_IN_POPUP_MODEL_REQUIRED
            },
            AssetImage: {
                required: PLACEHOLDERS.TRADE_IN_IMAGE_REQUIRED_ERROR,
                maxFiles: PLACEHOLDERS.TRADE_IN_IMAGE_UPLOAD_MAX_LIMIT_ERROR,
                minFiles: PLACEHOLDERS.TRADE_IN_IMAGE_UPLOAD_MIN_LIMIT_ERROR
            },
            ContractNumber: {
                required: PLACEHOLDERS.TRADE_IN_CONTRACT_NUMBER_IS_REQUIRED
            }

        },
        button: {

        }
    }


    const formProps = {
        control,
        handleSubmit,
        errors,
        messages,
        onSubmitTradeIn,
        setValue,
        getValues,
        clearErrors,
        reset,
        closePopup,
        setError,
        isValid,
        trigger,
        makeModelYearTrim,
        setMakeModelYearTrim,
        unregister,
        handleRemove,
        onProceedWithTradeIn,
        watch,
        tradeInType,
        selectedOptions,
        setselectedOptions,
        getTotalSteps,
        currentStep, setCurrentStep,progressValue,
        setProgressValue,showProgressBar,setShowProgressBar
        }
 const handleClosePopup = () => {
     setPreviousPopupType( {
         previousState: {previousState: null, currentState: null},
         currentState: initialPopupType
     });
     closePopup()
 }


    return(
        <TradeInJourneyPopup
            isPopupOpen={isPopupOpen}
            closePopup={() => handleClosePopup()}
            title={title}
            setNextPopup={setNextPopup}
            isButtonDisabled={isButtonDisabled}
            hasBackArrow={hasBackArrow}
            Component={Component}
            goToBackPopup={goToBackPopup}
            previousPopupType={previousPopupType}
            initialPopupType={initialPopupType}
            {...formProps}
        />

    )
}