import { useTheme } from '@mui/material';
import { Box, Skeleton, Typography, Radio } from '@ntpkunity/controls';
import { Stack } from '@components';
import { ConditionPopupWrap } from './condition-popup.style';
import { Controller } from 'react-hook-form';
import { TradeInJourneySteps } from 'constants/enums';

export const ConditionQuestionPopup = (props) => {
    const { questions = [], control,setProgressValue,tradeInType} = props;
    tradeInType==TradeInJourneySteps.ProvideManualEstimatePopup?setProgressValue(66.66):setProgressValue(77.77)
    const theme = useTheme();

    const defaultAnswers = questions?.reduce((acc, questionItem) => {
        const defaultAnswer = questionItem?.answers.find(answer => answer?.is_correct)?.answer || "";
        acc[questionItem?.question_identifier] = defaultAnswer;
        return acc;
    }, {})

    return (
        <ConditionPopupWrap theme={theme} className='condition-question-popup-wrap'>
            <Stack bgLight paddingXs={3} paddingMd={4} className='condition-question-stack'>
                <Typography theme={theme} component={'p'} variant='subtitle1'>
                    What is your vehicle's condition?
                </Typography>
                {questions?.length > 0 ? (
                    questions?.map((questionItem) => (
                        <Box theme={theme} key={questionItem.question_identifier} className={`popup-row ${questionItem.answers.length > 2 ? 'rating' : ''}`}>
                            <Typography theme={theme} component={'span'} variant='body2' className='text-muted'>
                                {questionItem.question}
                            </Typography>
                            <Controller
                                name={questionItem.question_identifier}
                                control={control}
                                rules={{ required: true }}
                                defaultValue={defaultAnswers[questionItem.question_identifier]}
                                render={({ field }) => (
                                    <Box theme={theme} className='radio-buttons'>
                                        <Radio
                                            theme={theme}
                                            options={questionItem.answers.map(answerItem => ({
                                                label: answerItem.answer,
                                                value: answerItem.answer,
                                            }))}
                                            value={field.value}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                        />
                                    </Box>
                                )}
                            />
                        </Box>
                    ))
                ) : (
                    <Skeleton theme={theme} width={'100%'} height={'20px'} style={{ marginBottom: '24px' }} />
                )}
            </Stack>
        </ConditionPopupWrap>
    );
};
